<template>
  <section class="hero">
    <figure class="image">
      <img src="../assets/img/luontoplus-banaani_1800@3x.png" />
    </figure>
    <div class="hero-body banana-bg">
      <div class="container">
        <div class="columns is-flex-direction-column">
          <div class="column">
            <h1 class="has-text-centered font-arla-strong-regular font-43 color-black">
              TEHDÄÄN HYVÄÄ
            </h1>
            <p class="has-text-centered arlainterface-regular font-16">
              Arla on mukana tukemassa MLL:n työtä lasten kaveritaitojen kehittämisessä.
            </p>
          </div>          
          <div class="column has-text-centered">
            <button class="button arla-button yellow-btn" @click="goToDoGood()">Lue lisää</button>
          </div>          
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
	name: "BananaComponent",
	components: {},
	data() {
		return {}
	},
  methods: {
    goToDoGood() {
      this.$router.push({ path: `/dogood` })
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
.banana-bg {
  background-color: $pale-yellow;
}
</style>
