import camelCase from 'lodash/camelCase'
/*
 * createAsyncMutation('GET_INFO_ASYNC') will lead to:
 *
 * GET_INFO_ASYNC = {
 *      BASE: GET_INFO_ASYNC_BASE,
 *      SUCCESS: GET_INFO_ASYNC_SUCCESS,
 *      PENDING: GET_INFO_ASYNC_PENDING,
 *      FAILURE: GET_INFO_ASYNC_FAILURE,
 *      loadingKey: getInfoAsyncPending,
 *      errorCode: getInfoAsyncErrorCode,
 *      stateKey: getInfoAsyncData
 * }
 *
 */
const createAsyncMutation = (type) => ({
  BASE: `${type}`,
  SUCCESS: `${type}_SUCCESS`,
  FAILURE: `${type}_FAILURE`,
  PENDING: `${type}_PENDING`,
  loadingKey: camelCase(`${type}_PENDING`),
  statusCode: `${camelCase(type)}StatusCode`,
  stateKey: camelCase(`${type}_DATA`),
  errorKey: camelCase(`${type}_ERRORS`)
})

export const POST_BIRD = createAsyncMutation('POST_BIRD')
export const PATCH_BIRD = createAsyncMutation('PATCH_BIRD')
export const GET_BIRD = createAsyncMutation('GET_BIRD')
export const POST_CONTACT = createAsyncMutation('POST_CONTACT')
export const POST_VIDEO = createAsyncMutation('POST_ANIMATION')
export const PATCH_VIDEO = createAsyncMutation('PATCH_ANIMATION')
export const GET_AUTHENTICATE = createAsyncMutation('GET_AUTHENTICATE')
export const GET_FIRST_ITEM_GALLERY = createAsyncMutation('GET_FIRST_ITEM_GALLERY')
export const GET_FIRST_VIDEOS_GALLERY = createAsyncMutation('GET_FIRST_VIDEOS_GALLERY')
export const GET_MORE_ITEMS_GALLERY = createAsyncMutation('GET_MORE_ITEMS_GALLERY')
export const GET_MESSAGES = createAsyncMutation('GET_MESSAGES')
