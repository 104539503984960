import { createApp } from 'vue'
import { router } from './router'
import App from './App.vue'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSocialSharing from 'vue-social-sharing'
import VueGtag from "vue-gtag-next"

const app = createApp(App);

app.use(router);
app.use(store)
app.use(VueAxios, axios)
app.use(VueSocialSharing);

app.use(VueGtag, {
    property: {
      id: process.env.VUE_APP_GA_ID
    }
  });

app.mount('#app');