<template>
  <div id="kamut-content">
    <h1
      class="has-text-centered color-dg"
    >
    HEIPPA! <br>
    AAMUKISSA ON ELÄINLÄÄKÄRISSÄ EIKÄ PÄÄSE TÄNÄÄN PELAAMAAN KANSSASI... <br>
    MITÄS JOS SILLÄ AIKAA KÄVISIT PIIRTÄMÄSSÄ UUSIA LUONTO+ LINTUKAVEREITA? <br>
    PAINA ALLA OLEVAA NAPPIA!
    </h1>

    <a href="https://luontopluskampanja.pgm.fi/" target="_blank">
      <button class="button arla-button">
        Piirrä lintukaveri
      </button>
    </a>

    <figure class="image">
      <img src="../assets/img/kamut.png" />
    </figure>

    <p class="kamut-footer color-dg">
      Kaveritaidot ovat supertärkeitä. <a href="https://www.mll.fi/kaveritaitoja-ja-vanhemmuutta/" target="_blank">Lue lisää MLL:n sivuilta.</a>
    </p>
  </div>
</template>

<script>

export default {
  name: "KamutPage",
  data() {
    return {
      spinning: false,
      birds: [],
      videos: [],
    }
  },
  mounted() {
    document.body.classList.add('kamut-body');
  },
  beforeDestroy() {
    document.body.classList.remove('kamut-body');
  }
}
</script>

<style lang="scss">

@font-face {
	font-family: "ArlaSmile";
	src: url('~@/assets/fonts/arlasmileextendedregular21.otf') format('OpenType');
}

.hero-wrapper {
  flex: 1 1 auto;
}

#kamut-content {
  text-align: center;
  .button {
    width: 240px;
    margin-top: 30px;
    margin-bottom: 30px;
    font-family: 'ArlaSmile';
    font-size: 32px;
    padding-bottom: 0px;
    padding-top: 0px;
  }
  .image img {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
  .kamut-footer {
    font-family: 'ArlaSmile';
    margin-top: 30px;
    font-size: 24px;
    a {
      color: #166134;
      text-decoration: underline;
    }
  }
  h1 {
    font-family: 'ArlaSmile';
    margin-top: 30px;
    font-size: 32px;
    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
}

html, .kamut-body {
  max-width: none !important;
}

.kamut-body #app {
  background-image: url( '../assets/img/kamutbg.jpg' );
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>
