<template>
  <section class="hero">
    <SpinnerModal :spinning="spinning"></SpinnerModal>
    <div class="hero-body pt-1">
      <div class="container">
        <div class="columns is-mobile is-multiline">
          <!-- Start editor tools -->
          <div class="column is-12 is-flex is-justify-content-space-between">
            <div
              class="tool current-color"
              :style="{ backgroundColor: color }"
            ></div>

            <div class="tool undo" @click="undo">
              <i class="fas fa-undo-alt fa-lg"></i>
            </div>
            <div class="tool redo" @click="redo">
              <i class="fas fa-redo-alt fa-lg"></i>
            </div>
            <div class="tool clear" @click="clear">
              <i class="fas fa-trash-alt fa-lg"></i>
            </div>
            <div
              class="tool free-drawing"
              @click="setTool('freeDrawing')"
              :class="{ 'active-tool': currentActiveMethod === 'freeDrawing' }"
            >
              <i class="fas fa-pencil-alt fa-lg"></i>
            </div>
            <input class="slider" step="5" min="5" max="40"
                   type="range" :disabled="currentActiveMethod != 'freeDrawing'"
                   v-model="strokeSize"
            >
            <div
              class="tool custom-circle"
              @click="setTool('circle')"
              :class="{ 'active-tool': currentActiveMethod === 'circle' }"
            >
              <i class="far fa-circle fa-lg"></i>
            </div>
            <div
              class="tool custom-rect"
              @click="setTool('rect')"
              :class="{ 'active-tool': currentActiveMethod === 'rect' }"
            >
              <i class="far fa-square fa-lg"></i>
            </div>

            <div
              class="tool drag"
              @click="setTool('selectMode')"
              :class="{ 'active-tool': currentActiveMethod === 'selectMode' }"
            >
              <i class="fas fa-arrows-alt fa-lg"></i>
            </div>

          </div>
          <div class="column">
            <div class="buttons-wrapper is-flex is-justify-content-space-between">
              <button class="template-selector-button font-os-eb" @click="imageUrl = 'bird_template-01.png';template = 1" :class="{ 'active': imageUrl == 'bird_template-01.png' }">Malli 1</button>
              <button class="template-selector-button font-os-eb" @click="imageUrl = 'bird_template-02.png';template = 2" :class="{ 'active': imageUrl == 'bird_template-02.png' }">Malli 2</button>
              <button class="template-selector-button font-os-eb" @click="imageUrl = 'bird_template-03.png';template = 3" :class="{ 'active': imageUrl == 'bird_template-03.png' }">Malli 3</button>
              <button class="template-selector-button font-os-eb" @click="imageUrl = 'bird_template-04.png';template = 4" :class="{ 'active': imageUrl == 'bird_template-04.png' }">Malli 4</button>
            </div>
          </div>
          <!-- End editor tools -->
          <!-- Start draw editor -->
          <div class="column is-11">
            <div class="is-relative" :class="isMobile ? 'mobile-size' : 'desktop-size'">
              <Editor class="editor-container" :canvasWidth="editorWidth" :canvasHeight="editorHeight" ref="editor"/>
              <div id="bird-template" :class="isMobile ? 'mobile-size' : 'desktop-size'" :width="editorWidth" :height="editorHeight">
                <img class="bird-image" :src="require('../assets/img/'+imageUrl)"/>
              </div>                
            </div>
 
          </div>
          <!-- End draw editor -->
          <!-- Start color selection -->
          <div class="column is-1 pl-0 pt-0">
            <div class="color-container pink" @click="changeColor('#e867a2')"></div>
            <div class="color-container red" @click="changeColor('#e7413f')"></div>
            <div class="color-container orange" @click="changeColor('#f7a91a')"></div>
            <div class="color-container yellow" @click="changeColor('#fcee89')"></div>
            <div class="color-container cream" @click="changeColor('#fbf7da')"></div>
            <div class="color-container purple" @click="changeColor('#7372b3')"></div>
            <div class="color-container blue" @click="changeColor('#27497b')"></div>
            <div class="color-container green" @click="changeColor('#91c03c')"></div>
            <div class="color-container darkgreen" @click="changeColor('#1e5f2b')"></div>
            <div class="color-container black" @click="changeColor('#3c3c3b')"></div>
          </div>
          <!-- End color selection -->
          <div class="column is-11 has-text-centered">
            <button class="button arla-button" @click="submit">Valmis!</button>
          </div>
          <div id="previewPicture">
            <canvas id="previewCanvas" width="508" height="567"></canvas>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Editor from "vue-image-markup"
import "@fortawesome/fontawesome-free/css/all.css"
import "@fortawesome/fontawesome-free/js/all.js"
import SpinnerModal from '../components/SpinningModal'
import {mapMutations, mapGetters, mapActions} from "vuex"

export default {
  name: "DrawingEditor",
  components: {
    Editor,
    SpinnerModal,
  },
  data() {
    return {
      currentActiveMethod: null,
      params: {},
      color: "black",
      imageUrl: "bird_template-01.png",
      croppedImage: false,
      savedPng: null,
      editorWidth: 508,
      editorHeight: 567,
      isMobile: false,
      spinning: false,
      bird: {},
      strokeSize: 10,
      template: 1
    }
  },
  mounted() {
    this.changeColor("#3c3c3b");
    this.setTool("freeDrawing");
    this.resize()
    //window.addEventListener('resize', this.resize, false)
    if (this.getToken() == '') {
      this.spinning = true
      this.getAuthenticate().then(() => {
        this.spinning = false
      })
    }

    // Initialize drawing if stored in session
    this.history = this.getDrawingHistory()
    if (this.history != null) {
      this.$refs.editor.canvas.loadFromJSON(this.history)
    }
  },
  watch: {
    strokeSize: function(val) {
      let customizeFreeDrawing = { stroke: this.color, strokeWidth: parseInt(val) }
      this.$refs.editor.set('freeDrawing', customizeFreeDrawing)
    }
  },
  methods: {
    ...mapMutations({
       setDrawingHistory: 'setDrawingHistory',
       setTemplate: 'setTemplate'
    }),
    ...mapActions({
      postBird: 'postBird',
      getAuthenticate: 'getAuthenticate'
    }),
    ...mapGetters({
      getToken: 'getToken',
      getBird: 'getBird',
      getDrawingHistory: 'getDrawingHistory',
      getTemplate: 'getTemplate'
    }),
    resize(clear=true) {
      if (clear) {
        this.$refs.editor.clear()
      }
      if (window.innerWidth < 600) {
        this.isMobile = true;
        this.editorHeight = 320
        this.editorWidth = 280
        this.$refs.editor.height = 320
        this.$refs.editor.width = 280
        this.$refs.editor.canvas.setDimensions({width: 280, height: 320});
      } else {
        this.isMobile = false;
        this.editorHeight = 567
        this.editorWidth = 508
        this.$refs.editor.height = 567
        this.$refs.editor.width = 508
        this.$refs.editor.canvas.setDimensions({width: 508, height: 567});
      }
    },
    changeColor(colorHex) {
      this.color = colorHex
      this.$refs.editor.changeColor(colorHex)
      if (this.currentActiveMethod == "freeDrawing") {
        let customizeFreeDrawing = { stroke: this.color, strokeWidth: parseInt(this.strokeSize) }
        this.$refs.editor.set('freeDrawing', customizeFreeDrawing)
      }
    },
    setTool(type, params) {
      this.currentActiveMethod = type
      this.$refs.editor.set(type, params)
      if (this.currentActiveMethod == "freeDrawing") {
        let customizeFreeDrawing = { stroke: this.color, strokeWidth: parseInt(this.strokeSize) }
        this.$refs.editor.set('freeDrawing', customizeFreeDrawing)
      }
    },
    clear() {
      this.currentActiveMethod = this.clear
      this.$refs.editor.clear()
    },
    undo() {
      this.currentActiveMethod = this.undo
      this.$refs.editor.undo()
      this.resize(false)
      this.setTool("freeDrawing")
    },
    redo() {
      this.currentActiveMethod = this.redo
      this.$refs.editor.redo()
      this.resize(false)
      this.setTool("freeDrawing")
    },
    submit() {
      // Unselect all objects
      this.$refs.editor.canvas.discardActiveObject().renderAll();
      // Get the drawing colors (without the bird).
      let self = this
      let canvas = document.getElementById("c")
      let destinationCanvas = document.getElementById("previewCanvas")
      let ctx = canvas.getContext("2d")
      // Copy the drawing colors to real size canvas
      let destCtx = destinationCanvas.getContext('2d')
      // Clear result canvas
      destCtx.clearRect(0, 0, 508, 567) // clear destination canvas for every new render
      destCtx.drawImage(canvas, 0, 0, 508, 567)
      ctx = destCtx
      // Attach to real size canvas bird (without wing lines) template on top of it
      let background = new Image(508, 567)
      let no_wings_image = this.imageUrl.replace("bird", "bird_no_wings") // example: bird_template-01.png => bird_no_wings_template-01.png
      background.src = process.env.VUE_APP_BG_IMG_PREFIX + no_wings_image
      background.onload = function () {
        ctx.drawImage(background, 0, 0)

        // replacing white pixels with transparent pixels
        var imgd = ctx.getImageData(0, 0,  508, 567),
        pix = imgd.data;

        for (var i = 0, n = pix.length; i <n; i += 4) {
            var r = pix[i],
                g = pix[i+1],
                b = pix[i+2];

            if(g==255 && r==255 && b==255){
                pix[i + 3] = 0;
            }
        }
        ctx.putImageData(imgd, 0, 0);

        // Get the base64 url from the final image
        self.savedPng = destinationCanvas.toDataURL("image/png")
        // Store the drawing history in case user wants to return and edit it
        self.setDrawingHistory(self.$refs.editor.canvas.toJSON())
        self.spinning = true
        self.postBird({source_image: self.savedPng}).then((res) => {
          self.setTemplate(self.template)
          self.bird = res.data
          self.spinning = false
          self.$router.push({ path: `/${self.bird.uuid}/message` })
        })
      };      
    }
  }
}

</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
.custom-editor {
  .canvas-container {
    margin-right: auto;
    margin-left: auto;
  }
}

.mobile-size {
  width: 280px;
  height: 320px;
}

.desktop-size {
  width: 508px;
  height: 567px;
}

.submitValidationText {
  color: red;
}

.editor-container {
  border: 1px #707070 solid;
}

#bird-template {
  top: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  z-index: 10000;
}
.bird-image {
  width: 100%;
  height: 100%;
}

#previewPicture {
  height: 508px;
  width: 567px;
  display: none;
}

.editor {
  margin-left: 30px;
  justify-content: space-between;
  display: flex;
}
.previewPicture {
  display: flex;
}
.colors {
  display: flex;
  flex-direction: column;
  margin: 40px 25px 0 25px;
  align-items: center;
  justify-content: center;
}
.color-container {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin: 8px 0;
}
.editor-container {
  flex-direction: column;
}
.editor-container,
.tool {
  display: flex;
}
.tool {
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding: 2px 5px;
}
.current-color {
  border-radius: 5px;
  width: 28px;
  height: 28px;
  margin-top: 2%;
}
.active-tool {
  cursor: pointer;
  color: #4287f5;
}
.red {
  background-color: #e7413f;
}
.yellow {
  background-color: #fcee89;
}
.purple {
  background-color: #7372b3;
}
.green {
  background-color: #91c03c;
}
.darkgreen {
  background-color: #1e5f2b;
}
.pink {
  background-color: #e867a2;
}
.blue {
  background-color: #27497b;
}
.black {
  background-color: #3c3c3b;
}
.orange {
  background-color: #f7a91a;
}
.cream {
  background-color: #fbf7da;
}
.upload-image {
  max-width: 15px;
}
.template-selector-button {
  cursor: pointer;
  color: #FFFFFF;
  font-size: 15px;
  background-color: #9CB96D;
  border: none;
  width: 80px;
  height: 40px;
  &.active {
    background-color: #166134;
  }
}
canvas {
  border: 1px solid rgba(0, 0, 0, 0.13);
}
.tool-icon {
  height: 20px;
  width: 20px;
  object-fit: contain;
}
.fa-lg:hover {
  cursor: pointer;
  color: #4287f5;
}
</style>
