<template>
  <div class="wrapper-video-player" :class="{'big-wrapper-video-player': bigSize}">
    <video ref="videoPlayer" class="video-js"></video>
  </div>
</template>

<script>
import videojs from 'video.js';

export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    bigSize: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      player: null
    }
  },
  methods: {
    mute(mode){
      this.player.muted(mode)
    }
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.options, () => {
      this.player.log('onPlayerReady', this);
      this.player.on('ended', () => {
        this.player.currentTime(0);
        this.player.pause();
    });        
    });
  
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },

}
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
.wrapper-video-player {
  width: 300px;
  margin: auto;

}
.big-wrapper-video-player {
  width: 26vw;
}
</style>