<template>
  <section class="hero">
    <figure class="image is-relative">
      <img src="../assets/img/background_mobile.png" />
      <figure class="image heart-img">
        <img src="../assets/img/heart@3x.png" />
      </figure>
      <figure class="image arla-logo">
        <img src="../assets/img/Arla_luonto+_logo-01@3x.png" />
      </figure>
      <figure class="image mll-ver">
        <img src="../assets/img/mll_ver@3x.png" />
      </figure>
    </figure>
  </section>
  <nav class="navbar arla-navbar">
    <div class="container navbar-container">
      <div class="navbar-brand" @click="isMenuOpen = !isMenuOpen">
        <span
          class="navbar-burger burger"
          data-target="navbarMenu"
          :class="{ 'is-active': isMenuOpen }"
        >
          <span></span>
          <span></span>
          <span></span>
        </span>
      </div>
      <div
        id="navbarMenu"
        class="navbar-menu cstm-menu"
        :class="{ 'is-active': isMenuOpen }"
      >
        <div class="navbar-end cstm-navbar-end">
          <a class="navbar-item is-size-5 color-dg font-20" @click="goToHomepage()">
            Etusivu
          </a>
          <!-- <a class="navbar-item is-size-5 color-dg font-20" @click="goToDraw()"> Piirrä </a> -->
          <a class="navbar-item is-size-5 color-dg font-20" @click="goToGallery()">
            Lintugalleria
          </a>
          <a class="navbar-item is-size-5 color-dg font-20" @click="goDoGood()">
            Tehdään hyvää
          </a>
          <a class="navbar-item is-size-5 color-dg font-20" @click="$refs.rulesModal.showModal()">
            Kampanjan säännöt
          </a>
        </div>
      </div>
      
    </div>
  </nav>
  <RulesModal ref="rulesModal"/>
</template>

<script>
import RulesModal from './RulesModal'
import { mapMutations } from 'vuex';

export default {
  name: "ArlaNavbar",
  components: {
    RulesModal,
  },
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    ...mapMutations({
      partialReset: 'partialReset'
    }),
    goToHomepage() {
      this.partialReset()
      this.$router.push({ path: `/` })
    },
    goToGallery() {
      this.$router.push({ path: `/gallery` })
    },
    goToDraw() {
      this.partialReset()
      this.$router.push({ path: `/drawing` })
    },
    goDoGood() {      
      this.$router.push({ path: `/dogood` })
    }
  },
  created() {
    document.addEventListener("DOMContentLoaded", () => {
      // Get all "navbar-burger" elements
      const $navbarBurgers = Array.prototype.slice.call(
        document.querySelectorAll(".navbar-burger"),
        0
      );

      // Check if there are any navbar burgers
      if ($navbarBurgers.length > 0) {
        // Add a click event on each of them
        $navbarBurgers.forEach((el) => {
          el.addEventListener("click", () => {
            // Get the target from the "data-target" attribute
            const target = el.dataset.target;
            const $target = document.getElementById(target);

            // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
            el.classList.toggle("is-active");
            $target.classList.toggle("is-active");
          });
        });
      }
    });
  },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
.heart-img {
  width: 44px;
  height: 45px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -22px;
  margin-top: -22px;
}

.arla-logo {
  width: 131px;
  height: 110px;
  position: absolute;
  top:50%;
  margin-top: -55px;
  left: 25%;
  margin-left: -65px;
}

.mll-ver {
  width: 152px;
  height: 129px;
  position: absolute;
  top:50%;
  margin-top: -65px;
  right: 25%;
  margin-right: -80px;
}

.arla-navbar {
  flex: 0 1 auto;
  min-height: fit-content !important;
  position: absolute;
  right: 0;
  background-color: transparent;
  width: 100%;
  
  .navbar-container {
    flex-direction: column;
  }

  .cstm-navbar-end {
    margin:auto;
  }

  @media screen and (max-width: 768px) {
    .cstm-menu {
      background-color: $light-green;
      border: 1px solid $dark-green;
      margin-right: 0;
      margin-left: auto;
    }
  }
}
</style>
