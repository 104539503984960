<template>
  <router-view :key="$route.fullPath"/>
</template>

<script>
import { waitForStorageToBeReady } from "./utils";

export default {
  name: 'App',
  async mounted() {
    await waitForStorageToBeReady()
  },
  components: {}
}
</script>

<style lang="scss">
  @import "./assets/sass/mybulma";
</style>
