<template>
  <teleport to="#modals">
    <div class="modal is-fixed" :class="{ 'is-active': active }">
      <div class="modal-background" @click="this.closeModal()"></div>
      <div class="modal-content has-background-white p-4 container">
        <img
          src="../assets/img/close.svg"
          class="is-pulled-right close-button"
          @click="this.closeModal()"
        />
        <div class="is-clearfix"></div>

        <div class="columns">
          <div class="column is-12">
            <h1 class="has-text-centered font-arla-strong-regular">KAMPANJAN SÄÄNNÖT</h1>
            <br />
            <p class="has-text-centered arlainterface-regular">
              Miten kampanjaan osallistutaan?
            </p>
            <p class="has-text-centered arlainterface-regular">
              Kampanja alkaa 3.4.2024 ja päättyy 31.10.2024. Kampanja on
              kaksivaiheinen, kuten alla on kuvattu.
            </p>
            <br />
            <p class="has-text-centered arlainterface-regular">
              Kampanjaan voi osallistua 3.4.-31.10.2024 välisenä aikana
              tekemällä osoitteessa arla.fi/luontoplus oman lintukuvan,
              kertomalla pyydetyt tiedot kuvasta ja antamalla yhteystietosi.
              Kampanja on osa Arlan ja Mannerheimin Lastensuojeluliiton (MLL)
              välistä hyväntekeväisyyskampanjaa, missä tuetaan lasten ja nuorten
              kaveritaitotyötä. Voit lukea lisää:
              <a
                href="www.mll.fi/kaveritaitoja-ja-vanhemmuutta/"
                target="_blank"
                >www.mll.fi/kaveritaitoja-ja-vanhemmuutta/</a
              >
              Järjestäjä valitsee kampanjasivun kautta ladatuista piirroskuvista
              tarvittavan määrän kuvia, joita vaiheessa 1. julkaistaan
              kampanjasivun galleriassa, joista myöhemmin vaiheessa 2. valitaan
              tarvittavan määrän painettavaksi osallistujan suostumuksella Arla
              Luonto+ 175g - sarjan kansiin kampanja-ajaksi vuonna 2024
              (tuotteita voi olla myynnissä vielä kampanja-ajan jälkeenkin).
              Järjestäjä on yhteydessä kaikkiin sähköpostilla, joiden kuva
              valitaan galleriaan vaiheessa 1. ja uudestaan mahdollisesti myös
              myöhäisemmissä vaiheissa pakkauksien kansiin painettavaksi Kaikki
              kuvansa ladanneet ja pyydetyt tiedot antaneet ovat automaattisesti
              mukana kampanjassa. Kaikki kampanjaan osallistuneet ja saavat
              hyväksytyt kuvansa halutessaan digitaalisessa muodossa
              kampanjasivulta.
            </p>
            <br />
            <p class="has-text-centered arlainterface-regular">
              Henkilötietojen käsittely
            </p>
            <p class="has-text-centered arlainterface-regular">
              Järjestäjä käsittelee osallistujien henkilötietoja kampanjan
              toteuttamiseksi ja palkintojen luovuttamiseksi. Henkilötietoja
              käsitellään myös suoramarkkinointitarkoituksiin osallistujan
              luvalla. Lisätietoja henkilötietojen käsittelystä löytyy
              Järjestäjän tietosuojaselosteesta.
            </p>
            <br />
            <p class="has-text-centered arlainterface-regular">
              Järjestäjän vastuu
            </p>
            <p class="has-text-centered arlainterface-regular">
              Osallistuja vapauttaa Järjestäjän sekä kampanjan järjestämisessä
              mukana olleet mahdolliset muut yhteistyö-kumppanit vahingosta,
              joka aiheutuu tai jonka väitetään aiheutuneen osallistumisesta
              tähän kampanjaan. Järjestäjän vastuu osanottajia kohtaan ei ylitä
              näissä säännöissä mainittujen palkintojen arvoa tai määrää.
              Järjestäjä ei vastaa tietoteknisistä syistä johtuvista ongelmista
              tai esteistä kampanjaan osallistumiseen tai palkinnon
              vastaanottamiseen.
            </p>
            <br />
            <p class="has-text-centered arlainterface-regular">
              Oikeudet kampanjan puitteissa toimitettuun materiaaliin
            </p>
            <br />
            <p class="has-text-centered arlainterface-regular">
              Osallistumalla kampanjaan osallistuja vakuuttaa, että hän on
              luonut kampanjaan toimittamansa materiaalin, esim. kuvan
              (”Kampanjamateriaali”) itse.
            </p>
            <br />
            <p class="has-text-centered arlainterface-regular">
              Osallistuja vakuuttaa, että Kampanjamateriaali ei loukkaa lakia,
              hyvää tapaa, kolmansien oikeuksia, tekijänoikeudet ja muut
              immateriaalioikeudet mukaan lukien, kunniaa, yksityisyyttä taikka
              liikesalaisuutta tai ole muulla tavalla näiden sääntöjen
              vastainen.
            </p>
            <br />
            <p class="has-text-centered arlainterface-regular">
              Osallistujan tulee muun muassa huolehtia siitä, ettei
              Kampanjamateriaalissa ole näkyvissä kolmansien osapuolten
              tavaramerkkejä (esim. kuvassa näkyvä kolmannen osapuolen logo) tai
              tekijänoikeuden suojaamaa materiaalia.
            </p>
            <br />
            <p class="has-text-centered arlainterface-regular">
              Osallistumalla kampanjaan toimittamalla Kampanjamateriaalin
              kampanjasivun kautta osallistuja antaa Järjestäjälle ja sen
              konserniyhtiöille ilman eri korvausta rajoitetun käyttöoikeuden
              Kampanjamateriaaliin. Tämän käyttöoikeuden nojalla Järjestäjällä
              ja sen konserniyhtiöillä on oikeus käyttää ja julkaista
              Kampanjamateriaalia kampanjaan liittyvän markkinoinnin ja
              viestinnän yhteydessä sosiaalisen median kanavissa ja
              verkkosivuillaan. Kaikkeen muuhun Kampanjamateriaalin käyttöön
              kysymme aina erikseen luvan osallistujalta.
            </p>
            <br />
            <p class="has-text-centered arlainterface-regular">Muut ehdot</p>
            <p class="has-text-centered arlainterface-regular">
              Jos kampanjaan osallistujaa on syytä epäillä vilpistä tai
              osallistumisesta muuten kuin säännöissä mainituin tavoin, on
              Järjestäjällä oikeus hylätä osallistuminen. Järjestäjä ilmoittaa
              kampanjasivuilla ja mahdollisuuksien mukaan kampanjan
              markkinointikanavissa, mikäli joudumme tekemään muutoksia
              kampanjaan, sen palkintoihin, ajankohtaan, saatavuuteen tai muihin
              seikkoihin, jotka vaikuttavat kampanjan toteutukseen. Järjestäjä
              pidättää itsellään oikeuden tehdä kampanjaan ja sääntöihin
              sellaisia muutoksia, jotka eivät vaikuta olennaisesti
              osallistujaan.
            </p>
            <br />
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: "RulesModal",
  components: {},
  data() {
    return {
      active: false,
    };
  },
  methods: {
    closeModal() {
      this.active = false;
    },
    showModal() {
      this.active = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
.modal {
  .modal-content {
    background-color: $pale-yellow !important;
  }
  .close-button {
    cursor: pointer;
  }
}
h1 {
  font-size: 45pt;
  color: black;
}
p {
  font-size: 18pt;
}


@media only screen and (max-width: 768px) {
  h1 {
  font-size: 35pt;
  color: black;
}
p {
  font-size: 15pt;
}
}
</style>
