<template>
  <ArlaNavbar/>

  <section class="hero">
    <div class="hero-body">
      <div class="container">

        <div class="columns is-multiline">
          <div class="column is-12">
            <p class="has-text-centered arlainterface-regular font-18">Kiitos paljon! Kiva kun saatiin uusi kaveri! <br><b>Seuraavaksi voit laittaa lintusi seikkailemaan videoon - se on tosi helppoa.</b></p>
          </div>
          <div class="column is-12">
            <p class="has-text-centered arlainterface-regular font-18">Valitse 4 eri vaihtoehdosta ympäristö, johon haluat laittaa lintusi seikkailemaan.</p>
          </div>
        </div>
        <div class="columns is-multiline">
          <div class="column is-6">
            <p class="has-text-centered font-18 mb-3"><b>Luonto</b></p>
            <figure class="image bg-image" :class="{ 'bg-selected': bgSelected == 1 }" @click="selectBg(1)">
              <img src="../assets/img/video_background-01.png" />
            </figure>
          </div>
          <div class="column is-6">
            <p class="has-text-centered font-18 mb-3"><b>Yötaivas</b></p>
            <figure class="image bg-image" :class="{ 'bg-selected': bgSelected == 2 }" @click="selectBg(2)">
              <img src="../assets/img/video_background-02.png" />
            </figure>
          </div>
          <div class="column is-6">
            <p class="has-text-centered font-18 mb-3"><b>Koulu</b></p>
            <figure class="image bg-image" :class="{ 'bg-selected': bgSelected == 3 }" @click="selectBg(3)">
              <img src="../assets/img/video_background-03.png" />
            </figure>
          </div>
          <div class="column is-6">
            <p class="has-text-centered font-18 mb-3"><b>Ranta</b></p>
            <figure class="image bg-image" :class="{ 'bg-selected': bgSelected == 4 }" @click="selectBg(4)">
              <img src="../assets/img/video_background-04.png" />
            </figure>
          </div>                              
        </div>
        <div class="column has-text-centered">
            <button class="button arla-button dark-red-btn" @click="goToAnimationSelector">
              Eteenpäin!
            </button>
        </div>
        <div class="column">
          <figure class="image">
              <img src="../assets/img/Group25@3x.png" />
            </figure>          
        </div>     
      </div>
    </div>
  </section>



  <ArlaFooter/>
</template>

<script>
import ArlaNavbar from "../components/ArlaNavbar";
import ArlaFooter from "../components/ArlaFooter";
import {mapMutations } from "vuex"

export default {
  name: "VideoBgSelector",
  components: {
    ArlaNavbar,
    ArlaFooter,
  },
  data() {
    return {
      bgSelected: null,
    }
  },
  methods: {
    ...mapMutations({
      setVideoData: 'setVideoData'
    }),    
    selectBg(bgId) {
      this.bgSelected = bgId;
    },
    goToAnimationSelector() {
      if(this.bgSelected) {
        this.setVideoData({background:this.bgSelected, uuid:this.$route.params.videoUuid})
        this.$router.push({ path: `/${this.$route.params.birdUuid}/${this.$route.params.videoUuid}/video/animation` })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
.bg-image {
  cursor: pointer;
  transition: transform .3s
}
.bg-image:hover {
  transform: scale(1.05);
}
.bg-selected {
  border: 3px solid $dark-red;
}
</style>
