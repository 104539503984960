<template>
  <ArlaNavbar/>

  <section class="hero">
    <div class="hero-body">
      <div class="container">

        <div class="columns">
          <div class="column">
            <p class="has-text-centered arlainterface-regular font-16">Sitten vaan piirtämään!<br>Lopuksi paina Valmis! -nappia niin pääset eteenpäin.</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <DrawingEditor/>

  <ArlaFooter/>
</template>

<script>
import ArlaNavbar from "../components/ArlaNavbar";
import ArlaFooter from "../components/ArlaFooter";
import DrawingEditor from "../components/DrawingEditor"

export default {
  name: "LandingPage",
  components: {
    ArlaNavbar,
    ArlaFooter,
    DrawingEditor,
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";

.gallery-wrapper {
  background-color: $light-green;
}
.banana-bg {
  background-color: $pale-yellow;
}

</style>
