<template>
  <teleport to="#modals">
    <div class="modal is-fixed" :class="{ 'is-active': active }">
      <div class="modal-background" @click="this.closeModal()"></div>
      <div class="modal-content has-background-white p-4 container">
        <img
          src="../assets/img/close.svg"
          class="is-pulled-right close-button"
          @click="this.closeModal()"
        />
        <div class="is-clearfix"></div>

        <div class="columns">
          <div class="column is-12 has-text-centered">
            <div>
              <video-player v-if="activeVideo" :options="videoOptions" bigSize="true"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import VideoPlayer from "../components/VideoPlayer";

export default {
  name: "VideoModal",
  components: {
    VideoPlayer
  },
  data() {
    return {
      active: false,
      activeVideo: false,
      videoOptions: {
        fluid: true,
        autoplay: false,
        controls: true,
        sources: []
      }          
    };
  },
  methods: {
    closeModal() {
      this.activeVideo = false;
      this.videoOptions.sources = []
      this.active = false;
    },
    showModal(videoUrl) {
      this.active = true;
      this.videoOptions.sources.push(
        {
          src: videoUrl,
          type: 'video/mp4'
        }
      ),
      this.activeVideo = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
.modal {
  .modal-content {
    background-color: $pale-yellow !important;
    width: 90%;
  }
  .close-button {
    cursor: pointer;
  }
}
h1 {
  font-size: 45pt;
  color: black;
}
p {
  font-size: 18pt;
}
</style>
