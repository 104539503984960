<template>
  <section class="hero gallery-page">
    <div class="hero-body gallery-wrapper">
      <div class="container">
        <div class="columns">
          <div class="column is-12">
            <h1
              class="has-text-centered font-arla-strong-regular gallery-title color-dg"
            >
              LINTUGALLERIA
            </h1>
          </div>
        </div>
        <div
          class="columns is mobile is-multiline"
          v-show="galleryView == true"
        >
          <div class="column is-12">
            <div class="control">
              <input
                class="input is-large text-arla-input"
                type="text"
                placeholder="Etsi"
                v-model="querystring"
              />
            </div>
          </div>

        </div>

        <!-- Start Gallery view -->
        <div
          class="columns is-mobile is-multiline"
          ref="scrollComponent"
          v-show="galleryView == true"
        >
          <template v-for="(bird, index) in birds" :key="bird.uuid">
            <div class="column is-6" v-show="category != 'videos'">
              <figure class="image is-clickable is-relative">
                <img :src="bird.preview_url" @click="goSingleView(index)" />
                <div v-if="bird.video_url" class="video-wrapper">
                  <i class="fa-solid fa-video" style="color: #ffffff"></i>
                </div>
              </figure>
            </div>
            <div
              class="column is-6"
              v-if="category == 'videos' && bird.video_thumbnails"
            >
              <figure
                v-if="bird.video_thumbnails"
                class="image is-clickable is-relative"
                @click="goSingleView(index)"
              >
                <img :src="bird.video_thumbnails" />
                <div class="play-icon-wrapper">
                  <i class="fa-solid fa-play"></i>
                </div>
              </figure>
            </div>
          </template>
          <div class="column has-text-centered" v-if="loading">
            <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
          </div>
        </div>
        <!-- End Gallery view -->

        <!-- Start Single View -->
        <div
          class="columns is-mobile is-multiline"
          v-show="galleryView == false"
        >
          <!-- Start Single View buttons -->
          <div class="column is-12 mb-5">
            <span @click="goGalleryView()"
              ><i class="fa-solid fa-list cstm-icon is-clickable"></i
            ></span>
          </div>
          <div class="column is-6 mb-4">
            <span @click="goPrevious()">
              <i class="fa-solid fa-chevron-left cstm-icon is-clickable"></i>
            </span>
          </div>
          <div class="column is-6 has-text-right mb-4">
            <span @click="goNext()">
              <i class="fa-solid fa-chevron-right cstm-icon is-clickable"></i>
            </span>
          </div>
          <!-- End Single View buttons -->
          <!-- Start single view LID mode-->
          <template v-if="singleViewMode == 'lid'">
            <div class="column is-12">
              <figure class="image lid-image" v-if="birds[singleViewIdx]">
                <img :src="birds[singleViewIdx].preview_url" />
              </figure>
            </div>
            <div class="column is-12 is-flex is-justify-content-space-evenly">
              <ShareNetwork network="facebook" :url="share.url" :title="share.title">
                <figure class="image is-48x48">
                  <img
                    src="../assets/img/icon-facebook.svg"
                    style="height: 48px"
                  />
                </figure>
              </ShareNetwork>
              <ShareNetwork network="whatsapp" :url="share.whatsappUrl" :title="share.title">
                <figure class="image is-48x48">
                  <img src="../assets/img/icon-whatsapp.svg" />
                </figure>
              </ShareNetwork>
              <figure class="image is-48x48 is-clickable" @click="downloadPic">
                <img src="../assets/img/icon-instagram.svg" />
              </figure>
            </div>
            <div
              class="column has-text-centered"
              v-if="birds[singleViewIdx] && birds[singleViewIdx].video_url"
            >
              <button
                class="button arla-button dark-red-btn"
                @click="changeSingleViewMode('video')"
              >
                <span>Katso video!</span>
                <span class="icon is-small">
                  <i class="fa-solid fa-video" style="color: #ffffff"></i>
                </span>
              </button>
            </div>
          </template>
          <!-- End single view LID mode-->
          <!-- Start single view VIDEO mode -->
          <template v-else>
            <div class="column is-12 has-text-centered">
              <video-player
                v-if="activeVideo"
                ref="videoPlayerRef"
                :options="videoOptions"
              />
            </div>
            <div class="column has-text-centered">
              <button
                class="button arla-button dark-red-btn"
                @click="changeSingleViewMode('lid')"
              >
                Katso piirros!
              </button>
            </div>            
          </template>
          <!-- End single view VIDEO mode -->
        </div>
        <!-- End Single View -->
      </div>
    </div>
  </section>

  <ArlaFooter />
</template>

<script>
import ArlaNavbar from "../components/ArlaNavbar";
import ArlaFooter from "../components/ArlaFooter";
import VideoPlayer from "../components/VideoPlayer";
import debounce from "lodash/debounce";
import axios from "axios";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "StaticGalleryPage",
  components: {
    ArlaNavbar,
    ArlaFooter,
    VideoPlayer,
  },
  data() {
    return {
      querystring: "",
      category: "video", // 'all' == default 'shops' == 'on_stores' 'videos' == 'birds with videos'
      birds: [],
      loading: false,
      galleryView: true, // true = gallery view, false = single bird view
      singleViewIdx: null,
      singleViewMode: "lid", // lid or video
      activeVideo: false,
      resetViewMode: false,
      videoOptions: {
        fluid: true,
        autoplay: false,
        controls: true,
        sources: [],
      },
      share: {
        url: "",
        whatsappUrl: "",
        title: "Arla",
      },
    };
  },
  methods: {
    ...mapActions({
      loadMoreItemsGallery: "loadMoreItemsGallery",
    }),
    ...mapGetters({
      getGalleryBirds: "getGalleryBirds",
      getToken: "getToken",
    }),
    ...mapMutations({
      resetGalleryData: "resetGalleryData",
    }),
    goSingleView(idx) {
      this.galleryView = false;
      this.singleViewIdx = idx;
      if (this.category == "videos") {
        this.singleViewMode = "video";
        this.loadVideoData();
      }
    },
    changeSingleViewMode(mode) {
      if (mode == "video") {
        this.resetViewMode = true
        // Push source and activate video component
        this.videoOptions.sources.push({
          src: this.birds[this.singleViewIdx].video_url,
          type: "video/mp4",
        });
        this.activeVideo = true;
      } else {
        this.updateSharingData()
        this.resetVideoComponent();
      }
      this.singleViewMode = mode;
    },
    resetVideoComponent() {
      // Reset sources and deactivate video component
      this.videoOptions.sources = [];
      this.activeVideo = false;
    },
    loadVideoData() {
      this.resetVideoComponent();
      this.videoOptions.sources.push({
        src: this.birds[this.singleViewIdx].video_url,
        type: "video/mp4",
      });
      // Without setTimeout, video doesn't reset its sources and does not
      setTimeout(() => {
        this.activeVideo = true;
      }, 500);
    },
    goGalleryView() {
      this.galleryView = true;
      this.singleViewIdx = null;
      this.singleViewMode = "lid";
      this.resetVideoComponent();
    },
    goPrevious() {
      if (this.singleViewIdx == 0) return;
      if (this.singleViewMode == "video" && this.resetViewMode) {
        //  Needed when being in video mode entered from lid mode,
        //  probably next bird does not have video, so we force to go lid mode always
        this.resetViewMode = false
        this.changeSingleViewMode("lid");
      }
      this.singleViewIdx -= 1;
      if (this.singleViewMode == "video") {
        this.loadVideoData();
      }
    },
    goNext() {
      if (this.singleViewIdx + 1 == this.birds.length) return;
      if (this.singleViewMode == "video" && this.resetViewMode) {
        //  Needed when being in video mode entered from lid mode,
        //  probably prev bird does not have video, so we force to go lid mode always        
        this.resetViewMode = false
        this.changeSingleViewMode("lid");
      }      
      this.singleViewIdx += 1;
      if (this.singleViewMode == "video") {
        this.loadVideoData();
      }
    },
    updateSharingData(birdUuid) {
      this.share.url = `${document.location.origin}/#!/share/${birdUuid}`;
      this.share.whatsappUrl = `${document.location.origin}?whatsappshare=/share/${birdUuid}`;
    },
    changeCategory(category) {
      this.resetGalleryData();
      this.loading = false;
      this.category = category;
      this.fetchMore();
    },
    fetchMore: debounce(function () {
      let self = this;
      if (this.$store.state.nextPageBirds) {
        this.loading = true;
        this.loadMoreItemsGallery({
          category: this.category,
          querystring: this.querystring,
        }).then(() => {
          self.birds = self.getGalleryBirds();
          self.loading = false;
        });
      }
    }, 1000),
    handleScroll(e) {
      let scrollEl = this.$refs.scrollComponent;
      if (scrollEl.getBoundingClientRect().bottom < window.innerHeight) {
        this.fetchMore();
      }
    },
    downloadPic() {
      let self = this;
      // Auth header in GET s3 requests returns 400, we remove it temporaly
      axios.defaults.headers.common["Authorization"] = "";
      let filename = `${this.birds[this.singleViewIdx].uuid}.png`;
      axios({
        url: `${process.env.VUE_APP_S3_BASE_URL_SECURE}/public/preview/${this.birds[this.singleViewIdx].uuid}.png`,
        method: "GET",
        responseType: "blob", // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "image/png" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          axios.defaults.headers.common[
            "Authorization"
          ] = `Token ${self.getToken()}`;
        });
    },    
  },
  watch: {
    querystring: function (val) {
      if (val.length >= 3 || val.length == 0) {
        this.resetGalleryData();
        this.fetchMore();
      }
    },
    singleViewIdx: function (val) {
      if (val != null) {
        this.updateSharingData(this.birds[val].uuid)
      }
    }
  },
  mounted() {
    this.changeCategory("videos")
    //this.resetGalleryData();
    window.addEventListener("scroll", this.handleScroll);
    //this.fetchMore();
  },
  beforeUnmount() {
    this.resetGalleryData();
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
.gallery-page {
  flex: 1 1 auto;
  .video-wrapper {
    position: absolute;
    width: 47px;
    height: 25px;
    border-radius: 20px;
    background-color: $dark-red;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 8%;
    left: 12%;
  }
  .play-icon-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: scale(5);
  }
  .gallery-wrapper {
    background-color: $light-green;
    .category-button {
      min-width: 130px;
    }
    .category-btn-unselected {
      background-color: $middle-green;
    }
  }
  .gallery-title {
    font-size: 43px;
  }
  .lid-image {
    max-width: 100%;
    width: 400px;
    margin: auto;
  }
  @media screen and (max-width: 768px) {
    .gallery-title {
      font-size: 30px;
    }
  }
  .cstm-icon {
    transform: scale(2.5);
  }
}
</style>
