<template>
  <ArlaNavbar/>

	<section class="hero">
		<div class="hero-body">
			<div class="container">
				<div class="columns is-flex-direction-column">
					<div class="column">
						<p class="has-text-centered arlainterface-regular">
							Hautominen käynnistetty! <br> <b>Lähetämme sähköpostiisi ilmoituksen, kun lintukaverisi ja videosi on julkaistu Galleriassa.</b><br> Sillä välin voit vaikka piirtää uusia lintuja tai katsoa, millaisia kavereita muut ovat tehneet.<br> Iloista päivää!
						</p>
					</div>

					<div class="column has-text-centered">
            <button class="button arla-button" @click="goToDrawNewOne">
							<span>Piirrä uusi</span>
							<span class="icon is-small">
								<i class="fa-solid fa-pencil" style="color: #ffffff"></i>
							</span>							
						</button>					
          </div>

					<div class="column has-text-centered">
            <button class="button arla-button dark-red-btn" @click="goToVideoGallery">Katso videoita</button>
          </div>					

          <div class="column">
            <figure class="image">
              <img src="../assets/img/Group25@3x.png" />
            </figure>
          </div>

				</div>
			</div>
		</div>
	</section>

	<BananaComponent/>

	<ArlaFooter/>
</template>

<script>
import ArlaNavbar from "../components/ArlaNavbar";
import ArlaFooter from "../components/ArlaFooter";
import BananaComponent from '../components/BananaComponent';
import { mapMutations } from 'vuex';

export default {
	name: "ThanksPage",
  components: {
    ArlaNavbar,
    ArlaFooter,
		BananaComponent,
  },
	data() {
		return {}
	},
	methods: {
		...mapMutations({
			partialReset: 'partialReset'
		}),
		goToDrawNewOne() {
			this.partialReset()
			this.$router.push({ path: `/drawing` })
		},
		goToVideoGallery() {
			this.$router.push({ path: `/gallery` });
		}

	}
}
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";

</style>
