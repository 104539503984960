<template>
  <ArlaNavbar />

  <section class="hero sharing-page">
    <div class="hero-body preview-wrapper">
      <div class="container">
        <div class="columns is-flex-direction-column">
          <div class="column">
            <p class="has-text-centered arlainterface-regular">
              Kiva kun saatiin uusi kaveri! Lintusi on nyt pesiytynyt
              Galleriaan. Voit tallentaa kuvan itsellesi tai jakaa sen vaikka
              Whatsapissa omalle kaverillesi.
            </p>
          </div>

          <div class="column is-12" v-show="viewMode =='lid'">
            <figure class="image">
              <img :src="bird.url" />
            </figure>
          </div>

          <div class="column is-12 has-text-centered" v-if="viewMode == 'video'">
            <video-player v-if="activeVideo" ref="videoPlayerRef" :options="videoOptions"/>            
          </div>

          <div class="column is-flex is-justify-content-space-evenly">
            <ShareNetwork
              network="facebook"
              :url="share.url"
              :title="share.title"
            >
              <figure class="image is-48x48">
                <img src="../assets/img/icon-facebook.svg" style="height: 48px"/>
              </figure>
            </ShareNetwork>
            <ShareNetwork
              network="whatsapp"
              :url="share.whatsappUrl"
              :title="share.title"
            >
              <figure class="image is-48x48">
                <img src="../assets/img/icon-whatsapp.svg" />
              </figure>
            </ShareNetwork>
          </div>
     
          <div class="column has-text-centered">
            <button class="button arla-button dark-red-btn" @click="changeView('video')" v-show="viewMode == 'lid'" v-if="this.bird.animation_url">
              <span>Katso video!</span>
              <span class="icon is-small">
                <i class="fa-solid fa-video" style="color: #ffffff"></i>
              </span>
            </button>
            <button class="button arla-button" @click="changeView('lid')" v-show="viewMode == 'video'">
              Katso piirros!
            </button>                   
          </div>

          <div class="column has-text-centered">
            <button class="button arla-button green-btn" @click="downloadPic" v-show="viewMode=='lid'">
              Tallenna kuva
            </button>
            <button class="button arla-button green-btn" @click="downloadVideo" v-show="viewMode=='video'">
              Tallenna video
            </button>            
          </div>

          <div class="column has-text-centered">
            <button class="button arla-button green-btn" @click="goLanding">
              Takaisin
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <ArlaFooter />
</template>

<script>
import ArlaNavbar from "../components/ArlaNavbar";
import ArlaFooter from "../components/ArlaFooter";
import VideoPlayer from "../components/VideoPlayer";
import axios from "axios";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "SharingPage",
  components: {
    ArlaNavbar,
    ArlaFooter,
    VideoPlayer,
  },
  data() {
    return {
      bird: {},
      share: {
        url: "",
        whatsappUrl: "",
        title: "Arla",
      },
      viewMode: "lid",  // lid, video
      activeVideo: false,
      videoOptions: {
        fluid: true,
        autoplay: false,
        controls: true,
        sources: [],
      },      
    };
  },
  methods: {
    ...mapMutations({
      partialReset: "partialReset",
    }),
    ...mapActions({
      getAuthenticate: "getAuthenticate",
      getBirdSharingData: "getBirdSharingData",
    }),
    ...mapGetters({
      getSharingDataFromStore: "getSharingDataFromStore",
      getToken: "getToken",
    }),
    goLanding() {
      this.partialReset();
      this.$router.push({ path: `/` });
    },
    changeView(mode) {
      if (mode == 'lid' ) {
        this.activeVideo = false
      } else {
        this.activeVideo = true
      }
      this.viewMode = mode
    },
    downloadPic() {
      let self = this;
      // Auth header in GET s3 requests returns 400, we remove it temporaly
      axios.defaults.headers.common["Authorization"] = "";
      let filename = `${self.$route.params.birdUuid}.png`;
      axios({
        url: `${process.env.VUE_APP_S3_BASE_URL_SECURE}/public/preview/${this.$route.params.birdUuid}.png`,
        method: "GET",
        responseType: "blob", // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "image/png" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          axios.defaults.headers.common[
            "Authorization"
          ] = `Token ${self.getToken()}`;
        });
    },
    getCurrentTime() {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');

      const currentTimeString = `${hours}:${minutes}:${seconds}`;
      return currentTimeString;
    },    
    downloadVideo(){
      let fileUrl = this.bird.animation_url
      let fileName = `Video-${this.getCurrentTime()}.mp4`
      fetch(fileUrl).then(response => response.blob()).then(blob => {
          // Create a temporary link element
          var link = document.createElement('a');

          // Create a Blob from the fetched content
          var blobUrl = window.URL.createObjectURL(blob);

          // Set the download attribute, file name, and href with the Blob URL
          link.download = fileName;
          link.href = blobUrl;

          // Append the link to the document body
          document.body.appendChild(link);

          // Trigger a click event to start the download
          link.click();

          // Remove the link and revoke the Blob URL
          document.body.removeChild(link);
          window.URL.revokeObjectURL(blobUrl);
        }).catch(error => console.error('Error downloading the file:', error))
    },    
  },
  created() {
    let self = this;
    this.getAuthenticate().then(() => {
      self.getBirdSharingData(self.$route.params.birdUuid).then(() => {
        self.bird = self.getSharingDataFromStore(); // can be removed? since we only need uuid, if we need name and so on for title and description then yes
        self.share.title = "Arla";
        self.share.url = `${document.location.origin}/#!/share/${self.$route.params.birdUuid}`;
        self.share.whatsappUrl = `${document.location.origin}?whatsappshare=/share/${self.$route.params.birdUuid}`;
        self.videoOptions.sources.push(self.bird.animation_url)
      });
    });
  },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";

.sharing-page {
  flex: 1 1 auto;
  .preview-wrapper {
    background-color: $light-green;
  }
}
</style>
