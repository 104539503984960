<template>
  <ArlaNavbar/>

	<section class="hero">
		<SpinnerModal :spinning="spinning"></SpinnerModal>
		<div class="hero-body py-3">
			<div class="container">
				<div class="columns">
					<div class="column">
						<p class="has-text-centered arlainterface-regular">
							Melkein valmista!
						</p>
						<p class="has-text-centered arlainterface-regular">
							Annatko vielä vähän tietoja itsestäsi :)
						</p>
						<p class="has-text-centered arlainterface-regular">
							Sähköpostiosoite tarvitaan, jotta voimme ilmoittaa sinulle kun lintusi on julkaistu Galleriassa.
						</p>


					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="hero contact-page">
		<div class="hero-body banana-bg">
			<div class="container">
				<div class="columns is-mobile is-multiline">
					<div class="column is-12">
						<p class="has-text-centered mb-1 font-os-r">
							<span class="font-os-sb">Piirtäjän tiedot</span> (nämä tulevat näkyviin)
						</p>
						<div class="control">
							<input class="input is-large text-arla-input" type="text" placeholder="Etunimi*" v-model="contact.name" :maxlength="MAX_NAME_LENGTH">
						</div>
					</div>


					<div class="column is-12">
						<div class="control">
							<input class="input is-large text-arla-input" type="number" placeholder="Ikä*" @keypress="isNumber($event)" v-model="contact.age">
						</div>
					</div>


					<div class="column is-12">
						<div class="control">
							<input class="input is-large text-arla-input" type="text" placeholder="Paikkakunta*" v-model="contact.town" :maxlength="MAX_TOWN_LENGTH">
						</div>
					</div>

					<div class="column is-12 mt-5">
						<p class="has-text-centered font-os-sb">
							Äidin, isän tai muun aikuisen yhteystiedot
						</p>
						<p class="has-text-centered font-os-r">
							(nämä eivät näy muille)
						</p>
					</div>								

					<div class="column is-12">
						<div class="control">
							<input class="input is-large text-arla-input" type="text" placeholder="Sähköpostiosoitteesi*" v-model="contact.email">
						</div>
						<p v-if="emailMsgError" class="help is-danger">This email is invalid</p>
					</div>

					<div class="column is-12">
						<div class="control">
							<input class="input is-large text-arla-input" type="text" placeholder="Sähköpostiosoite uudelleen*" v-model="emailCheck">
						</div>
					</div>					
					
					<div class="column is-12">
						<label class="checkbox is-block font-os-r">
							<input type="checkbox" class="check-input" v-model="rules">
							Aikuinen on lukenut kampanjan <a @click="$refs.rulesModal.showModal()">säännöt</a> *
						</label>

						<label class="checkbox is-block mt-1 font-os-r">
							<input type="checkbox" class="check-input" v-model="contact.marketing">
							Aikuinen haluaa vastaanottaa markkinointiviestejä Arlalta
						</label>				
					</div>

					<div class="column has-text-centered is-12">
							<button class="button arla-button" :disabled="!canSubmit" @click="submit">Eteenpäin!</button>
					</div>

					<!-- <div class="column has-text-centered is-12">
							<button class="button arla-button yellow-btn" @click="goBack">Taaksepäin</button>
					</div> -->

				</div>
			</div>
		</div>
	</section>
	<RulesModal ref="rulesModal"/>
	<ArlaFooter/>
</template>

<script>
import ArlaNavbar from "../components/ArlaNavbar";
import ArlaFooter from "../components/ArlaFooter";
import RulesModal from '../components/RulesModal';
import SpinnerModal from '../components/SpinningModal'
import { mapGetters, mapMutations } from 'vuex';


export default {
	name: "ContactPage",
	components: {
		ArlaNavbar,
		ArlaFooter,
		RulesModal,
		SpinnerModal,
	},
	data() {
		return {
			rules: false,
			contact: {
				name: '',
				email: '',
				age: null,
				town: '',
				marketing: false
			},
			emailCheck: '',
			emailMsgError: false,
			spinning: false,
			bird: {},
			MAX_NAME_LENGTH: 14,
			MAX_TOWN_LENGTH: 18,
		}
	},
	mounted() {
		this.bird = this.getBird()
		this.contact = this.getContact()
	},
	methods: {
    ...mapGetters({
      getToken: 'getToken',
			getBird: 'getBird',
			getContact: 'getContact'
    }),
		...mapMutations({
			setContact: 'setContact'
		}),
    validateEmail() {
      return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.contact.email)
    },
    goBack() {
      this.$router.push({ path: `/${this.bird.uuid}/message` })
    },
		removeEmojis() {
			this.contact.name = this.contact.name.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,'')
			this.contact.town = this.contact.town.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,'')
		},
		submit() {
			if(this.canSubmit) {
				this.removeEmojis()
				this.setContact(this.contact)
				this.$router.push({ path: `/${this.bird.uuid}/preview` })
			}
		},
		isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    }	
	},
	computed: {
		canSubmit() {
			if(this.contact.name && 
					this.contact.email &&
				  this.contact.email.toLowerCase() == this.emailCheck.toLowerCase() &&
					this.contact.age && 
					this.contact.town && 
					this.rules &&
					this.validateEmail() &&
					this.contact.name.length <= this.MAX_NAME_LENGTH &&
					this.contact.town.length <= this.MAX_TOWN_LENGTH
				) {
				return true
			}
			return false
		}
	}
}
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";

.contact-page {
	flex: 1 1 auto;
}

.checkbox input {
	vertical-align: sub;
	height: 18px;
	width: 18px;
}
</style>
