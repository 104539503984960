<template>
  <ArlaNavbar/>

	<section class="hero">
		<SpinnerModal :spinning="spinning"></SpinnerModal>
		<div class="hero-body">
			<div class="container">
				<div class="columns is-multiline">
					<div class="column is-12">
						<p class="has-text-centered arlainterface-regular">
							Tältä näyttää sinun suunnittelemasi Arla Luonto+ jogurttikipon kansi. Jos kaikki on kunnossa, paina Valmis! Jos jotain pitää vielä muuttaa, paina Taaksepäin.
						</p>
					</div>


					<div class="column is-12 has-text-centered">
            <button class="button arla-button" @click="submit">Jatka</button>
          </div>

          <div class="column is-12">
            <figure class="image has-text-centered">
							<NewSvgContainer/>
            </figure>
          </div>



					<div class="column is-12 has-text-centered">
            <button class="button arla-button yellow-btn" @click="goBack">Taaksepäin</button>
          </div>      
				</div>
			</div>
		</div>
	</section>

	<BananaComponent/>

	<ArlaFooter/>
</template>

<script>
import ArlaNavbar from "../components/ArlaNavbar";
import ArlaFooter from "../components/ArlaFooter";
import BananaComponent from '../components/BananaComponent'
import NewSvgContainer from '../components/NewSvgContainer'
import SpinnerModal from '../components/SpinningModal'
import { mapActions, mapGetters } from 'vuex';

export default {
	name: "PreviewPage",
  components: {
    ArlaNavbar,
    ArlaFooter,
		BananaComponent,
		SpinnerModal,
		NewSvgContainer
  },
	data() {
		return {
			spinning: false,
			contact: {},
			bird: {},
		}
	},
	mounted() {
		this.contact = this.getContact();
		this.bird = this.getBird();
	},
	methods: {
		...mapActions({
      postContact: 'postContact',
			patchBird: 'patchBird',
			postVideo: 'postVideo',
    }),
		...mapGetters({
      getContact: 'getContact',
			getBird: 'getBird',
			getTemplate: 'getTemplate'
    }),
		submit() {
			let self = this;
			this.spinning = true;
			this.postContact(this.contact).then((contactData) => {				
				// PATCH bird to upload svg
      let svg = document.getElementById('arla-svg')
      let defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs')
      let style = document.createElementNS('http://www.w3.org/2000/svg', 'style')
      let node = document.createTextNode(`@import url('${process.env.VUE_APP_S3_BASE_URL_SECURE}/public/fonts.css');`)
      style.appendChild(node)
      defs.appendChild(style)
      svg.appendChild(defs)
      let blob = new Blob([svg.outerHTML], {type: "image/svg+xml;charset=utf-8"})
      let formData = new FormData()
      formData.append('image_preview_svg', new File([blob], 'label.svg'))
			
			// Create animation video
			self.postVideo({bird:self.bird.uuid,template: self.getTemplate()}).then((res) => {
				let animationUuid = res.data.uuid
				self.patchBird(formData).then(birdData => {
					self.spinning = false;
					self.$router.push({ path: `/${this.bird.uuid}/${animationUuid}/video/bg` })
				})				
			})			

			})	
		},
		goBack() {
			this.$router.push({ path: `/${this.bird.uuid}/contact` })
		}
	}
}
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";

</style>
