<template>
  <ArlaNavbar/>

  <section class="hero do-good-page">
    <figure class="image">
      <img src="../assets/img/luontoplus-banaani_1800@3x.png" />
    </figure>
    <div class="hero-body banana-bg">
      <div class="container">
        <div class="columns">
          <div class="column">
            <h1 class="has-text-centered font-arla-strong-regular font-43 color-black">
              TEHDÄÄN HYVÄÄ
            </h1>
            <p class="has-text-centered mt-1 arlainterface-regular font-16">
              ARLA ON MUKANA TUKEMASSA MLL:N TYÖTÄ LASTEN KAVERITAITOJEN KEHITTÄMISESSÄ
            </p>
            <br>
            <p class="has-text-centered mt-1 arlainterface-regular font-16">
              Hyvillä kaverisuhteilla on suuri merkitys lapsen kasvulle ja kehitykselle. Kaverit merkitsevät lapselle
              iloa, leikkiä ja yhteistä tekemistä. Mitä vanhemmaksi lapsi kasvaa, sitä merkityksellisemmäksi
              kaveritaidot tulevat.
            </p>
            <br>
            <p class="has-text-centered mt-1 arlainterface-regular font-16">
              Lapset tarvitsevat vanhempia kaverisuhteiden opetteluun. Vanhemman ja lapsen välinen hyvä vuorovaikutus
              luo pohjan lapsen sosiaalisille taidoille. Kaverisuhteisiin on helpompi ryhtyä, kun lapsella on
              kokemuksia siitä, että häntä ymmärretään ja arvostetaan omassa perheessä. Omat vanhemmat ovat lapselle
              tärkein turvasatama silloin, kun kaverisuhteissa on tapahtunut kurjia asioita.
            </p>
            <br>
            <p class="has-text-centered mt-1 arlainterface-regular font-16">LUE LISÄÄ:</p>
            <p class="has-text-centered mt-1 arlainterface-regular font-16"><a href="https://www.mll.fi/kaveritaitoja-ja-vanhemmuutta/" target="_blank">https://www.mll.fi/kaveritaitoja-ja-vanhemmuutta/</a></p>
          </div>
        </div>
      </div>
    </div>
  </section>

	<ArlaFooter/>
</template>

<script>
import ArlaNavbar from "../components/ArlaNavbar";
import ArlaFooter from "../components/ArlaFooter";

export default {
	name: "DoGoodPage",
  components: {
    ArlaNavbar,
    ArlaFooter,
  },
	data() {
		return {}
	}
}
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";

.do-good-page {
	flex: 1 1 auto;
	.banana-bg {
		background-color: $pale-yellow;
	}
}

</style>