import { createStore } from "vuex"
import VuexPersistence from "vuex-persist"
import doAsync from "./async-util"
import * as types from "./mutation-types"
import localforage from "localforage"
import axios from "axios"

const local_store = localforage.createInstance({
  name: "arla",
});

const vuexPersist = new VuexPersistence({
  storage: local_store,
  reducer: (state) => {
    return JSON.parse(JSON.stringify(state));
  },
  asyncStorage: true,
});

const getDefaultState = () => {
  return {
    token: '',
    contact: {},
    bird: {},
    videoData: {},
    sharing: {},
    drawing_history: null,
    birds: [],
    nextPageBirds: true,
    pageBirds: 1,
    messages: [],
    template: null,
    videos: []
  }
}


let state_dict = getDefaultState()

let store_config = {
  state() {
    return state_dict
  },
  mutations: {
    setAuth(state, token) {
      state.token = token
    },
    setContact(state, contact) {
      state.contact = contact
    },
    setBird(state, bird) {
      state.bird = bird
    },
    setVideoData(state, videoData) {
      state.videoData = videoData
    },
    setMessages(state, data) {
      state.messages = data
    },
    setBirdSharingData(state, data) {
      state.sharing = data
    },
    setTemplate(state, template) {
      state.template = template
    },
    setGalleryVideos(state, data) {
      state.videos = state.videos.concat(data)
    },
    partialReset(state) {
      let token = state.token
      Object.assign(state, getDefaultState())
      state.token  = token
    },    
    resetStore(state) {
      state = state_dict
    },
    setDrawingHistory(state, history) {
      state.drawing_history = history
    },
    setGalleryBirds(state, birds) {
      state.birds = state.birds.concat(birds)
    },
    resetGalleryData(state) {
      let defaultState = getDefaultState()
      state.nextPageBirds = defaultState.nextPageBirds
      state.birds = defaultState.birds
      state.pageBirds = defaultState.pageBirds
    }
  },
  getters: {
    getToken(state) {
      return state.token
    },
    getContact(state) {
      return state.contact
    },
    getMessages(state) {
      return state.messages
    },
    getDrawingHistory(state) {
      return state.drawing_history
    },
    getBird(state) {
      return state.bird
    },
    getGalleryBirds(state) {
      return state.birds
    },
    getGalleryVideos(state) {
      return state.videos
    },
    getTemplate(state) {
      return state.template
    },
    getSharingDataFromStore(state) {
      return state.sharing
    },
    getVideoData(state) {
      return state.videoData
    },
  },
  actions: {
    getAuthenticate(store) {
      return doAsync(store, {
        url: `login/`,
        mutationTypes: types.GET_AUTHENTICATE,
        method: 'post',
      }).then((res) => {
        if(res.status === 200) {
          store.commit('setAuth', res.data.token)
        }
        return res
      })
    },
    getBirdMessages(store) {
      return doAsync(store, {
        url: `message/`,
        mutationTypes: types.GET_MESSAGES,
        method: 'get',
      }).then((res) => {
        if(res.status === 200) {
          store.commit('setMessages', res.data.results)
        }
        return res
      })
    },    
    postContact(store, payload) {
      return doAsync(store, {
        url: 'contact/',
        mutationTypes: types.POST_CONTACT,
        method: 'post',
        data: payload,
      }).then((res) => {
        if(res.status === 201) {
          store.commit('setContact', res.data)
        }
        return res
      })
    },
    postBird(store, payload) {
      return doAsync(store, {
        url: 'bird/',
        mutationTypes: types.POST_BIRD,
        method: 'post',
        data: payload,
      }).then((res) => {
        if(res.status === 201) {
          store.commit('setBird', res.data)
        }
        return res
      })
    },  
    patchBird(store, payload) {
      return doAsync(store, {
        url: `bird/${store.state.bird.uuid}/`,
        mutationTypes: types.PATCH_BIRD,
        method: 'patch',
        data: payload,
      }).then((res) => {
        if(res.status === 200) {
          store.commit('setBird', res.data)
        }
        return res
      })
    },
    postVideo(store, payload) {
      return doAsync(store, {
        url: 'animation/',
        mutationTypes: types.POST_VIDEO,
        method: 'post',
        data: payload,
      }).then((res) => {
        return res
      })
    },      
    patchVideo(store, payload) {
      return doAsync(store, {
        url: `animation/${store.state.videoData.uuid}/`,
        mutationTypes: types.PATCH_VIDEO,
        method: 'patch',
        data: payload,
      }).then((res) => {
        if(res.status === 200) {
          store.commit('setVideoData', res.data)
        }
        return res
      })
    },
    getBirdSharingData(store, uuid) {
      return doAsync(store, {
        url: `bird/${uuid}/`,
        mutationTypes: types.GET_BIRD,
        method: 'get'
      }).then(res => {
        store.commit('setBirdSharingData', res.data.data)
      })
    },
    loadFirstItemsGallery(store) {
      let tmpToken = store.state.token
      store.state.token = ''
      return doAsync(store, {
        url: `bird/?page=1`,
        mutationTypes: types.GET_FIRST_ITEM_GALLERY,
        method: 'get'
      }).then(res => {
        store.commit('setGalleryBirds', res.data.results)
        store.state.token = tmpToken
      })
    },
    loadFirstVideosGallery() {
      return doAsync(store, {
        url: `landing/videos/`,
        mutationTypes: types.GET_FIRST_VIDEOS_GALLERY,
        method: 'get'
      }).then(res => {
        store.commit('setGalleryVideos', res.data.results)
      })
    },
    loadMoreItemsGallery(store, data) {
      let tmpToken = store.state.token
      store.state.token = ''
      return doAsync(store, {
        url: `bird/?page=${store.state.pageBirds}&category=${data['category']}&querystring=${data['querystring']}`,
        mutationTypes: types.GET_MORE_ITEMS_GALLERY,
        method: 'get'
      }).then(res => {
        store.commit('setGalleryBirds', res.data.results)
        store.state.token = tmpToken
        if (res.data.next) {
          store.state.pageBirds++
        } else {
          store.state.nextPageBirds = false
        }
      })
    }
  },
  plugins: [vuexPersist.plugin],
}

/*
 * For each async mutation, dynamically add the three mutations:
 * SUCCESS - write the response to the store using the `stateKey` property
 * PENDING - set the `loadingKey` property in the store to true
 * FAILURE - Set `loadingKey` to false, and `errorCode` - for example 401, 404...
 *
 * stateKey, errorCode and loadingKey are prepended with the name of the action,
 * for example an action getData would lead to:
 *
 * mutations:
 *   GET_DATA_SUCCESS,
 *   GET_DATA_PENDING,
 *   GET_DATA_FAILURE
 *
 * state:
 *   getDataLoadingKey,
 *   getDataStateKey,
 *   getDataErrorCode
 *
 * For all mutations following defaults are applied:
 *   getDataLoadingKey = false
 *   getDataStateKey = null
 *   getDataStatusCode = null
 *   getDataErrorCode = null
 */
Object.keys(types).forEach((type) => {
  // add the initial state
  state_dict[types[type].loadingKey] = false
  state_dict[types[type].stateKey] = null
  state_dict[types[type].statusCode] = null
  state_dict[types[type].FAILURE] = null

  // add the mutation
  store_config['mutations'][types[type].BASE] = (state, payload) => {
    switch (payload.type) {
      case types[type].PENDING:
        state[types[type].loadingKey] = payload.value
        return state[types[type].loadingKey]

      case types[type].SUCCESS:
        state[types[type].statusCode] = payload.statusCode
        state[types[type].FAILURE] = null
        state[types[type].stateKey] = payload.data
        return state[types[type].stateKey]

      case types[type].FAILURE:
        state[types[type].statusCode] = payload.statusCode
        state[types[type].FAILURE] = payload.error
        return state[types[type].FAILURE]
    }
  }
})

const store = createStore(store_config)

export default store


