<template>
  <ArlaNavbar/>

	<section class="hero message-selector-page">
		<div class="hero-body">
			<div class="container">
				<div class="columns is-multiline">

          <div class="column is-12">
            <div class="has-text-centered">
              <figure class="image bird-img m-auto">
                <img :src="this.bird.source_image_url" />
              </figure>              
            </div>
            <p class="has-text-centered mt-4">
              Kappas, siinähän on ihan uusi kaveri! Haluaako sinun lintu sanoa jotain?
            </p>
          </div>

          <div class="column is-12 is-flex is-justify-content-center">
            <div class="bubble-wrapper">
              <p class="has-text-centered font-30 font-amatic p-2">{{this.getText()}}</p>
            </div>
          </div>

          <div class="column is-12">
            <div class="control">
              <label v-for="message in messages" :key="message.id" class="radio arla-radio is-block ml-0 mb-3">

                <input type="radio" name="message" v-model="messageId" :value="message.id">
                {{message.text}}
              </label>                           
            </div>
          </div>

          <div class="column is-12 has-text-centered">
            <button class="button arla-button" :disabled="!canSubmit" @click="submit">Eteenpäin!</button>
          </div>
          <div class="column is-12 has-text-centered">
            <button class="button arla-button yellow-btn" @click="goBack">Taaksepäin</button>
          </div>

				</div>
			</div>
		</div>
	</section>
	<ArlaFooter/>
</template>

<script>
import ArlaNavbar from "../components/ArlaNavbar";
import ArlaFooter from "../components/ArlaFooter";
import { mapActions, mapGetters, mapMutations } from 'vuex';


export default {
	name: "ContactPage",
	components: {
		ArlaNavbar,
		ArlaFooter,
	},
	data() {
		return {
			messageId: null,
      bird: {},
      messages: []
		}
	},
	mounted() {
		this.bird = this.getBird()
    let self = this
    this.getBirdMessages().then(() => {
      self.messages = self.getMessages()
      self.messageId = self.messages[0].id
    })
	},
	methods: {
    ...mapActions({
      getBirdMessages: 'getBirdMessages',
      patchBird: 'patchBird'
    }),
    ...mapGetters({
			getBird: 'getBird',      
      getMessages: 'getMessages'
    }),
		...mapMutations({
			setMessageId: 'setMessageId'
		}),
    getText() {
      if(this.messageId) return this.messages.find(el => el.id == this.messageId).text
      return ''
    }    ,
    goBack() {
      this.$router.push({ path: `/drawing` })
    },
		submit() {
      this.patchBird({
          message: this.messageId
        }).then(() => {
        this.$router.push({ path: `/${this.bird.uuid}/contact` })
      })
    }
	},
  computed: {
		canSubmit() {
			if(this.messageId) {
				return true
			}
			return false
		}
	}
}
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";

.message-selector-page {
  flex: 1 1 auto;
  .bubble-wrapper {
    width: 290px;
    height: 145px;
    background-image: url( '../assets/img/arla-bubble.svg' );
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      margin-right: 25px;
    }
  }

  .bird-img {
    max-width: 210px;
    max-height: 235px;
  }

}


</style>