<template>
  <svg
    id="arla-svg"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="100%"
    max-width="602.09"
    height="100%"
    max-height="602.09"
    viewBox="0 0 602.09 602.09"
  >
    <g>
      <g>
        <defs>
          <circle id="SVGID_1_" cx="300.2" cy="301" r="300.2" />
        </defs>
        <clipPath
          id="SVGID_00000124864287520555003420000015894881902113674669_"
        >
          <use xlink:href="#SVGID_1_" style="overflow: visible" />
        </clipPath>
        <g
          style="
            clip-path: url(#SVGID_00000124864287520555003420000015894881902113674669_);
          "
        >
          <image
            style="overflow: visible; enable-background: new"
            width="284"
            height="284"
            xlink:href="../assets/img/arla-luontoplus-bg.png"
            transform="matrix(2.12 0 0 2.12 0.19 0)"
          ></image>
        </g>
      </g>
    </g>
    <path
      style="fill: #ffffff"
      d="M481.5,128.3c-4.6-3.7-9.9-6.7-15.4-8.8c-17.8-6.3-29.7,3.6-29.7,3.6s2.5-10.5-7.2-16.1
	c-13.6-7.9-20.6,2.7-20.6,2.7s-3.6-44.8-51.1-48.4c-26.8-2-35,20.6-35,20.6s-7.2-11-20.6-11.7c-9.8-0.6-19,4.7-23.3,13.5
	c0,0-12.6-26.8-43.5-24.9c-3.4,0.2-6.8,0.8-10.1,1.6c-6.7,1.6-13,4.2-18.8,7.9c-22.7,14.1-29.6,38.3-29.6,38.3s-4.7-9.1-13.1-7.7
	c-12.4,2-14.1,12.1-14.1,12.1s-0.4-3.8-6.3-4c-1.2,0-2.5,0.1-3.7,0.4c-32.9,26.8-58.6,61.4-74.6,100.7h0.7
	c-11.8,29.7-17.9,61.4-17.9,93.3c0,139.4,113,252.4,252.4,252.4c139,0,251.7-112.3,252.3-251.1C552,237.5,526.7,175.1,481.5,128.3z"
    />
    <g>
      <path
        style="fill: #1d1d1b"
        d="M170.5,440c-0.4,0.5-1,0.8-1.6,0.8h-0.6v3.9c0,0.2-0.1,0.3-0.2,0.3l0,0h-0.9c-0.2,0-0.3-0.1-0.3-0.3
		v-8.8c0-0.1,0.1-0.3,0.3-0.3h1.7c0.6-0.1,1.2,0.3,1.5,0.8c0.3,0.6,0.5,1.2,0.4,1.9C170.9,438.8,170.8,439.5,170.5,440z M169,436.6
		c-0.5,0-0.6,0-0.6,0.5v2.1c0,0.3,0.1,0.4,0.5,0.4c0.6,0,0.9-0.4,0.9-1.6C169.8,437.1,169.5,436.6,169,436.6z"
      />
      <path
        style="fill: #1d1d1b"
        d="M175.6,444.9h-0.9c-0.1,0-0.2-0.1-0.2-0.3v-0.1c-0.3,0.4-0.7,0.6-1.2,0.5c-0.7,0-1.4-0.5-1.4-1.9
		v-0.3c0-1.3,0.6-2.1,1.7-2.1c0.3,0,0.5,0,0.8,0.2v-1.4c-0.1-0.4-0.3-0.7-0.5-0.7c-0.3,0-0.6,0.1-0.8,0.4c-0.1,0.1-0.2,0.2-0.3,0.2
		s-0.2-0.1-0.2-0.1c-0.1-0.2-0.2-0.3-0.3-0.4s-0.1-0.2-0.1-0.2c0-0.1,0.1-0.2,0.2-0.3c0.3-0.3,0.8-0.5,1.2-0.6h0.4
		c0.5,0,1,0.3,1.3,0.7c0.3,0.4,0.4,0.9,0.3,1.4v4.8C175.9,444.8,175.8,444.9,175.6,444.9z M174.6,442c0-0.3-0.2-0.4-0.6-0.4
		s-0.7,0.3-0.7,1.2s0.2,1.2,0.6,1.2c0.4,0,0.6-0.3,0.7-0.6V442z"
      />
      <path
        style="fill: #1d1d1b"
        d="M180.5,438.4c-0.1,0.2-0.3,0.4-0.4,0.6c0,0.1-0.1,0.1-0.1,0.1l0,0c-0.1,0-0.1,0-0.2-0.1
		c-0.1-0.1-0.3-0.2-0.5-0.2c-0.4,0-0.7,0.4-0.7,0.9v4.9c0,0.2-0.1,0.3-0.2,0.3l0,0h-0.8c-0.1,0-0.3-0.1-0.3-0.3v-6.5
		c0-0.1,0.1-0.3,0.3-0.3h0.8c0.2,0,0.3,0.1,0.3,0.3l0,0v0.4c0.1-0.2,0.3-0.4,0.4-0.5c0.2-0.2,0.5-0.3,0.7-0.3s0.4,0.1,0.6,0.2
		c0.1,0.1,0.1,0.1,0.1,0.2C180.6,438.3,180.6,438.4,180.5,438.4z"
      />
      <path
        style="fill: #1d1d1b"
        d="M185.1,444.9h-0.9c-0.1,0-0.2-0.1-0.2-0.3v-0.1c-0.3,0.4-0.7,0.6-1.2,0.5c-0.7,0-1.4-0.5-1.4-1.9
		v-0.3c0-1.3,0.6-2.1,1.7-2.1c0.3,0,0.5,0,0.8,0.2v-1.4c0-0.3-0.2-0.6-0.5-0.7c-0.3,0-0.6,0.1-0.8,0.4c-0.1,0.1-0.2,0.2-0.3,0.2
		s-0.2-0.1-0.2-0.1c-0.1-0.2-0.2-0.3-0.3-0.4s-0.1-0.2-0.1-0.2c0-0.1,0.1-0.2,0.2-0.3c0.3-0.3,0.8-0.5,1.2-0.6h0.4
		c0.5,0,1,0.3,1.3,0.7c0.3,0.4,0.4,0.9,0.3,1.4v4.8C185.4,444.8,185.2,444.9,185.1,444.9z M184.1,442c0-0.3-0.2-0.4-0.6-0.4
		s-0.7,0.3-0.7,1.2s0.2,1.2,0.6,1.2c0.4,0,0.6-0.3,0.6-0.6L184.1,442L184.1,442z"
      />
      <path
        style="fill: #1d1d1b"
        d="M189.3,444.5c-0.3,0.3-0.7,0.5-1.1,0.5c-0.3,0-0.6,0-0.9-0.1c-0.1,0-0.3-0.1-0.4-0.2
		c-0.1,0-0.1-0.1-0.1-0.2s0-0.1,0-0.2c0.1-0.2,0.2-0.4,0.3-0.7c0-0.1,0.1-0.1,0.1-0.1h0.1c0.2,0.2,0.5,0.4,0.8,0.4s0.5-0.1,0.5-0.8
		c0-0.2,0-0.3,0-0.5c-0.1-0.4-0.3-0.7-0.6-1c-0.5-0.5-0.9-1.1-1.1-1.8c0-0.2-0.1-0.4-0.1-0.6c0-0.5,0.2-0.9,0.5-1.2
		c0.3-0.3,0.7-0.5,1.2-0.5c0.3,0,0.5,0,0.7,0.1s0.4,0.2,0.5,0.3l0.1,0.1v0.1c-0.1,0.1-0.3,0.5-0.3,0.6s-0.1,0.1-0.1,0.1
		c-0.1,0-0.1,0-0.1-0.1c-0.2-0.2-0.4-0.3-0.6-0.3c-0.3,0-0.5,0.1-0.5,0.7c0,0.2,0,0.3,0.1,0.5c0.1,0.4,0.3,0.7,0.6,1
		c0.5,0.5,0.9,1.1,1.1,1.8c0,0.2,0.1,0.4,0.1,0.5C189.8,443.7,189.6,444.2,189.3,444.5z"
      />
      <path
        style="fill: #1d1d1b"
        d="M194.1,444.7c-0.3,0.2-0.6,0.3-1,0.3H193c-0.5,0-0.9-0.3-1.2-0.7c-0.2-0.4-0.4-0.9-0.3-1.4V439h-0.3
		c-0.1,0-0.3-0.1-0.3-0.3l0,0v-0.5c0-0.1,0.1-0.3,0.3-0.3h0.3v-1.7c0-0.1,0.1-0.3,0.3-0.3h0.8c0.2,0,0.3,0.1,0.3,0.3v1.6h0.7
		c0.2,0,0.3,0.1,0.3,0.3v0.5c0,0.2-0.1,0.3-0.3,0.3h-0.7v3.3c0,0.4,0,0.8,0,1.3c0,0.2,0.2,0.4,0.4,0.4l0,0l0,0
		c0.2,0,0.4-0.1,0.5-0.2s0.1-0.2,0.2-0.2s0.1,0.1,0.1,0.2l0.1,0.5c0,0.1,0.1,0.2,0.1,0.2C194.3,444.6,194.2,444.7,194.1,444.7z"
      />
      <path
        style="fill: #1d1d1b"
        d="M198.9,444.9H198c-0.1,0-0.2-0.1-0.2-0.3v-0.1c-0.3,0.4-0.7,0.6-1.2,0.5c-0.6,0-1.4-0.5-1.4-1.9
		v-0.3c0-1.3,0.6-2.1,1.7-2.1c0.3,0,0.5,0,0.8,0.2c0-0.5,0-1,0-1.4c0-0.3-0.2-0.6-0.5-0.7c-0.3,0-0.6,0.1-0.8,0.4
		c-0.1,0.1-0.2,0.2-0.3,0.2s-0.2-0.1-0.2-0.1c-0.1-0.2-0.2-0.3-0.3-0.4s-0.1-0.2-0.1-0.2c0-0.1,0.1-0.2,0.2-0.3
		c0.3-0.3,0.8-0.5,1.2-0.6h0.4c0.5,0,1,0.3,1.3,0.7c0.3,0.4,0.4,0.9,0.3,1.4v4.8C199.2,444.8,199.1,444.9,198.9,444.9L198.9,444.9z
		 M197.9,442c0-0.3-0.2-0.4-0.6-0.4s-0.7,0.3-0.7,1.2s0.2,1.2,0.6,1.2c0.4,0,0.6-0.3,0.7-0.6V442z"
      />
      <path
        style="fill: #1d1d1b"
        d="M204.5,441.6l-0.7,0.2c0,0.6,0,1.1,0.1,1.7c0.1,0.4,0.2,0.5,0.5,0.5s0.6-0.2,0.9-0.4
		c0.1-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.1,0.1c0.1,0.2,0.2,0.3,0.2,0.5c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1-0.2,0.2
		c-0.4,0.3-0.9,0.4-1.3,0.4h-0.1c-0.5,0.1-1.1-0.2-1.4-0.6c-0.3-0.4-0.4-0.8-0.4-1.3v-3.6c0-0.4,0.1-0.9,0.4-1.2
		c0.3-0.4,0.9-0.7,1.4-0.6h0.2c0.5,0,1.1,0.2,1.3,0.7c0.2,0.4,0.3,0.8,0.3,1.2v0.1C206,440.6,205.4,441.4,204.5,441.6z M204.3,438.7
		c-0.2,0-0.4,0.1-0.5,0.5c-0.1,0.5-0.1,1-0.1,1.5l0.2-0.1c0.5-0.1,0.9-0.6,0.9-1.1v-0.1C204.8,439.1,204.7,438.7,204.3,438.7
		L204.3,438.7z"
      />
      <path
        style="fill: #1d1d1b"
        d="M211.1,444.9h-0.8c-0.2,0-0.3-0.1-0.3-0.3v-3.2c0.1-0.8,0-1.6-0.2-2.3c-0.1-0.2-0.2-0.3-0.4-0.3
		c-0.3,0-0.6,0.2-0.7,0.4v5.5c0,0.2-0.1,0.3-0.2,0.3l0,0h-0.8c-0.1,0-0.3-0.1-0.3-0.3v-6.5c0-0.1,0.1-0.3,0.3-0.3h0.7
		c0.2,0,0.3,0.1,0.3,0.3l0,0v0.2c0.1-0.2,0.3-0.3,0.5-0.4s0.5-0.2,0.7-0.2c0.4,0,0.8,0.2,1,0.5c0.2,0.4,0.3,0.8,0.3,1.2v5.2
		C211.2,444.7,211.2,444.9,211.1,444.9z"
      />
      <path
        style="fill: #1d1d1b"
        d="M216.4,444.9h-0.8c-0.2,0-0.3-0.1-0.3-0.3v-3.2c0.1-0.8,0-1.6-0.2-2.3c-0.1-0.2-0.2-0.3-0.4-0.3
		c-0.3,0-0.6,0.2-0.7,0.4v5.5c0,0.1-0.1,0.3-0.2,0.3H213c-0.1,0-0.3-0.1-0.3-0.3v-6.5c0-0.1,0.1-0.3,0.3-0.3h0.7
		c0.2,0,0.3,0.1,0.3,0.3v0.2c0.1-0.2,0.3-0.3,0.5-0.4s0.5-0.2,0.7-0.2c0.4,0,0.8,0.2,1,0.5c0.2,0.4,0.3,0.8,0.3,1.2v5.2
		C216.5,444.7,216.5,444.9,216.4,444.9z"
      />
      <path
        style="fill: #1d1d1b"
        d="M220.2,441.6l-0.8,0.2c0,0.6,0,1.1,0.1,1.7c0.1,0.4,0.2,0.5,0.5,0.5s0.6-0.2,0.9-0.4
		c0.1-0.1,0.2-0.1,0.2-0.1c0.1,0,0.1,0,0.1,0.1c0.1,0.2,0.1,0.4,0.2,0.5c0,0.1,0.1,0.1,0.1,0.2s-0.1,0.1-0.2,0.2
		c-0.4,0.3-0.9,0.4-1.3,0.4l0,0c-0.5,0.1-1.1-0.2-1.4-0.6c-0.3-0.4-0.4-0.8-0.4-1.3v-3.6c0-0.5,0.1-0.9,0.4-1.2
		c0.3-0.4,0.9-0.7,1.4-0.6h0.1c0.5,0,1,0.2,1.3,0.7c0.2,0.4,0.3,0.8,0.3,1.2v0.1C221.7,440.6,221,441.4,220.2,441.6z M220,438.7
		c-0.2,0-0.4,0.1-0.5,0.5c-0.1,0.5-0.1,1-0.1,1.5l0.2-0.1c0.5-0.1,0.9-0.6,0.9-1.1v-0.1C220.5,439.1,220.4,438.7,220,438.7
		L220,438.7z"
      />
      <path
        style="fill: #1d1d1b"
        d="M226.7,444.9h-0.8c-0.2,0-0.3-0.1-0.3-0.3v-3.2c0.1-0.8,0-1.6-0.2-2.3c-0.1-0.2-0.2-0.3-0.4-0.3
		c-0.3,0-0.6,0.2-0.7,0.4v5.5c0,0.1-0.1,0.3-0.2,0.3h-0.8c-0.1,0-0.3-0.1-0.3-0.3v-6.5c0-0.1,0.1-0.3,0.3-0.3h0.7
		c0.2,0,0.3,0.1,0.3,0.3l0,0v0.2c0.1-0.2,0.3-0.3,0.5-0.4s0.4-0.2,0.7-0.2c0.4,0,0.8,0.2,1,0.5c0.2,0.4,0.3,0.8,0.3,1.2v5.2
		C227,444.8,226.9,444.9,226.7,444.9z"
      />
      <path
        style="fill: #1d1d1b"
        d="M229.7,440.7h-0.9c-0.1,0-0.3-0.1-0.3-0.3l0,0v-1c0-0.2,0.1-0.3,0.3-0.3h0.9c0.2,0,0.3,0.1,0.3,0.3
		v1C230,440.6,229.9,440.7,229.7,440.7z M229.7,444.9h-0.9c-0.1,0-0.3-0.1-0.3-0.3l0,0v-1c0-0.1,0.1-0.3,0.3-0.3l0,0h0.9
		c0.2,0,0.3,0.1,0.3,0.3v1C230,444.8,229.9,444.9,229.7,444.9L229.7,444.9z"
      />
      <path
        style="fill: #1d1d1b"
        d="M169.3,462.4h-2c-0.2,0-0.3-0.1-0.3-0.3v-8.8c0-0.1,0.1-0.3,0.3-0.3h2c1.1,0,1.8,1.1,1.8,2.4
		c0.1,0.9-0.3,1.7-1,2.2c0.7,0.6,1.1,1.5,1,2.4C171.1,461.3,170.4,462.4,169.3,462.4z M169.1,454.1c-0.7,0-0.7,0-0.7,0.7v1.8
		c0,0.3,0,0.4,0.5,0.4c0.7,0,0.9-0.4,0.9-1.5C169.8,454.6,169.5,454.1,169.1,454.1L169.1,454.1z M168.9,458.2
		c-0.5,0-0.5,0.1-0.5,0.4v1.2c0,1.3,0,1.5,0.4,1.5h0.2c0.6,0,0.8-0.3,0.8-1.4C169.8,459.1,169.6,458.2,168.9,458.2L168.9,458.2z"
      />
      <path
        style="fill: #1d1d1b"
        d="M176,462.4h-0.9c-0.1,0-0.2-0.1-0.2-0.3V462c-0.3,0.4-0.7,0.6-1.2,0.5c-0.7,0-1.4-0.5-1.4-1.9v-0.3
		c0-1.3,0.6-2.1,1.7-2.1c0.3,0,0.5,0,0.8,0.2V457c0-0.3-0.2-0.6-0.5-0.7c-0.3,0-0.6,0.1-0.8,0.4c-0.1,0.1-0.2,0.2-0.3,0.2
		s-0.2-0.1-0.2-0.1c-0.1-0.2-0.2-0.3-0.3-0.4s-0.1-0.1-0.1-0.2s0.1-0.2,0.2-0.3c0.3-0.3,0.8-0.5,1.2-0.6h0.4c0.5,0,1,0.3,1.3,0.7
		c0.3,0.4,0.4,0.9,0.3,1.4v4.8C176.3,462.2,176.2,462.4,176,462.4z M173.8,454.7h-0.7c-0.2,0-0.3-0.1-0.3-0.3v-0.7
		c0-0.2,0.1-0.3,0.3-0.3h0.7c0.1,0,0.3,0.1,0.3,0.3v0.7C174,454.5,173.9,454.6,173.8,454.7L173.8,454.7z M175,459.5
		c0-0.3-0.2-0.4-0.6-0.4s-0.7,0.3-0.7,1.2s0.2,1.2,0.6,1.2c0.4,0,0.6-0.3,0.6-0.6L175,459.5L175,459.5z M175.9,454.7h-0.7
		c-0.2,0-0.3-0.1-0.3-0.3v-0.7c0-0.2,0.1-0.3,0.3-0.3h0.7c0.2,0,0.3,0.1,0.3,0.3v0.7C176.2,454.5,176,454.6,175.9,454.7L175.9,454.7
		z"
      />
      <path
        style="fill: #1d1d1b"
        d="M180.2,462c-0.3,0.3-0.7,0.5-1.1,0.5c-0.3,0-0.6,0-0.9-0.1c-0.1,0-0.3-0.1-0.4-0.2
		c-0.1,0-0.1-0.1-0.1-0.2s0-0.1,0-0.2c0.1-0.2,0.2-0.4,0.3-0.7c0-0.1,0.1-0.1,0.1-0.1h0.1c0.2,0.2,0.5,0.4,0.8,0.4s0.5-0.1,0.5-0.8
		c0-0.2,0-0.3-0.1-0.5c-0.1-0.4-0.3-0.7-0.6-1c-0.5-0.5-0.8-1.1-1.1-1.8c0-0.2-0.1-0.4-0.1-0.6c0-0.5,0.2-0.9,0.5-1.2
		c0.3-0.3,0.7-0.5,1.2-0.5c0.3,0,0.5,0,0.7,0.1s0.4,0.2,0.5,0.3l0.1,0.1v0.1c-0.1,0.1-0.3,0.5-0.3,0.6l-0.1,0.1h-0.1
		c-0.2-0.2-0.4-0.3-0.6-0.3c-0.3,0-0.5,0.1-0.5,0.7c0,0.2,0,0.3,0.1,0.5c0.1,0.4,0.3,0.7,0.6,1c0.5,0.5,0.9,1.1,1.1,1.8
		c0,0.2,0.1,0.4,0.1,0.5C180.7,461.1,180.5,461.6,180.2,462z"
      />
      <path
        style="fill: #1d1d1b"
        d="M185,462.2c-0.3,0.2-0.6,0.3-1,0.3l0,0c-0.5,0-0.9-0.3-1.2-0.7c-0.3-0.4-0.4-0.9-0.4-1.4v-3.9h-0.3
		c-0.2,0-0.3-0.1-0.3-0.3v-0.5c0-0.2,0.1-0.3,0.3-0.3h0.3v-1.6c0-0.1,0.1-0.3,0.3-0.3h0.8c0.2,0,0.3,0.1,0.3,0.3v1.6h0.7
		c0.2,0,0.3,0.1,0.3,0.3v0.5c0,0.2-0.1,0.3-0.3,0.3h-0.7v3.3c0,0.4,0,0.8,0,1.2c0,0.2,0.2,0.4,0.4,0.4c0,0,0,0,0.1,0
		c0.2,0,0.4-0.1,0.5-0.2s0.1-0.2,0.2-0.2s0.1,0.1,0.1,0.2l0.1,0.5c0,0.1,0.1,0.2,0.1,0.2C185.2,462,185.2,462.1,185,462.2z"
      />
      <path
        style="fill: #1d1d1b"
        d="M191.3,453.6c0,0-0.3,0.4-0.3,0.5s-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.1
		c-0.2-0.2-0.4-0.3-0.6-0.3c-0.2,0-0.3,0.1-0.3,0.3c-0.1,0.5-0.1,0.9-0.1,1.4h0.7c0.2,0,0.3,0.1,0.3,0.3v0.5c0,0.2-0.1,0.3-0.3,0.3
		h-0.7v5.6c0,0.1-0.1,0.3-0.3,0.3h-0.8c-0.2,0-0.3-0.1-0.3-0.3v-5.6h-0.3c-0.2,0-0.3-0.1-0.3-0.3v-0.5c0-0.2,0.1-0.3,0.3-0.3h0.3
		v-0.7c0-0.5,0.1-1,0.3-1.4c0.3-0.4,0.7-0.7,1.2-0.7h0.2c0.4,0,0.8,0.1,1.2,0.3c0.1,0.1,0.2,0.2,0.2,0.3
		C191.3,453.4,191.3,453.5,191.3,453.6z"
      />
      <path
        style="fill: #1d1d1b"
        d="M195.4,461.9c-0.3,0.4-0.9,0.7-1.4,0.6h-0.1c-0.5,0.1-1.1-0.2-1.4-0.6c-0.3-0.4-0.4-0.8-0.4-1.3V457
		c0-0.5,0.1-0.9,0.4-1.2c0.3-0.4,0.9-0.7,1.4-0.6h0.1c0.5-0.1,1.1,0.2,1.4,0.6c0.3,0.4,0.4,0.8,0.4,1.2v3.6
		C195.9,461,195.7,461.5,195.4,461.9z M193.2,454.6h-0.7c-0.2,0-0.3-0.1-0.3-0.3v-0.7c0-0.2,0.1-0.3,0.3-0.3h0.7
		c0.2,0,0.3,0.1,0.3,0.3v0.7C193.5,454.5,193.4,454.6,193.2,454.6L193.2,454.6z M194.5,456.7c-0.1-0.4-0.2-0.5-0.5-0.5
		s-0.5,0.2-0.5,0.5c-0.1,1.4-0.1,2.8,0,4.2c0.1,0.4,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5C194.6,459.5,194.6,458.1,194.5,456.7z
		 M195.4,454.6h-0.7c-0.1,0-0.2-0.1-0.2-0.2v-0.7c0-0.1,0.1-0.3,0.3-0.3h0.7c0.2,0,0.3,0.1,0.3,0.3v0.7
		C195.6,454.5,195.5,454.6,195.4,454.6L195.4,454.6z"
      />
      <path
        style="fill: #1d1d1b"
        d="M200.5,455.9c-0.1,0.2-0.3,0.4-0.4,0.6c0,0.1-0.1,0.1-0.1,0.1l0,0c-0.1,0-0.1,0-0.2-0.1
		c-0.1-0.1-0.3-0.2-0.5-0.2c-0.4,0-0.7,0.4-0.7,0.9v4.8c0,0.2-0.1,0.3-0.2,0.3l0,0h-0.8c-0.1,0-0.3-0.1-0.3-0.3v-6.5
		c0-0.1,0.1-0.3,0.3-0.3h0.8c0.2,0,0.3,0.1,0.3,0.3l0,0v0.4c0.1-0.2,0.3-0.4,0.4-0.5c0.2-0.2,0.5-0.3,0.7-0.3s0.4,0.1,0.6,0.2
		c0.1,0.1,0.1,0.1,0.1,0.2C200.6,455.8,200.6,455.8,200.5,455.9z"
      />
      <path
        style="fill: #1d1d1b"
        d="M203.7,459l-0.7,0.2c0,0.6,0,1.1,0.1,1.7c0.1,0.4,0.2,0.5,0.5,0.5s0.6-0.2,0.9-0.4
		c0.1-0.1,0.2-0.1,0.2-0.1s0.1,0,0.1,0.1c0.1,0.2,0.2,0.3,0.2,0.5c0,0.1,0.1,0.1,0.1,0.2s-0.1,0.1-0.2,0.2c-0.4,0.3-0.9,0.4-1.3,0.4
		h-0.1c-0.5,0.1-1.1-0.2-1.4-0.6c-0.3-0.4-0.4-0.8-0.4-1.3V457c0-0.5,0.1-0.9,0.4-1.2c0.3-0.4,0.9-0.7,1.4-0.6h0.1
		c0.5,0,1.1,0.2,1.3,0.7c0.2,0.4,0.3,0.8,0.3,1.2v0.1C205.2,458.1,204.6,458.8,203.7,459z M203.5,456.1c-0.2,0-0.4,0.1-0.5,0.5
		c-0.1,0.5-0.1,1-0.1,1.5l0.2-0.1c0.5-0.1,0.9-0.6,0.9-1.1v-0.1C204,456.5,203.9,456.2,203.5,456.1L203.5,456.1z"
      />
      <path
        style="fill: #1d1d1b"
        d="M207.9,458.2H207c-0.1,0-0.3-0.1-0.3-0.2l0,0v-1c0-0.1,0.1-0.3,0.2-0.3l0,0h0.9
		c0.2,0,0.3,0.1,0.3,0.3v1C208.1,458,208,458.2,207.9,458.2z M207.9,462.4H207c-0.1,0-0.3-0.1-0.3-0.3l0,0v-1c0-0.1,0.1-0.3,0.3-0.3
		l0,0h0.9c0.2,0,0.3,0.1,0.3,0.3v1C208.2,462.3,208,462.4,207.9,462.4L207.9,462.4L207.9,462.4L207.9,462.4z"
      />
    </g>
    <g>
      <path
        style="fill: #1d1d1b"
        d="M214.5,140.8c-2.9-3.2-4.6-4.2-7.4-4.2c-6.1,0.2-10.9,5.3-10.8,11.4c0,5.5,3.9,10.2,8.4,10.1
		c3.6,0,11-4.9,11.5-11.2v-1.1c-0.1-1.7-0.5-3.4-1.1-5H214.5z M206,153.3c-2.2-2-3-3.2-3-4.5c0-1.5,0.4-2.9,1.1-4.2
		c0.9-1.5,1.6-3.2,3.3-3.2h0.2c2.2,0.1,2.5,2.8,2.5,5.1C210.2,149.5,209.2,151.1,206,153.3z"
      />
      <path
        style="fill: #1d1d1b"
        d="M194.5,145.6c0-2.5-1.7-5.1-3.9-5.1c-0.2,0-0.5,0-0.7,0.1c-1,0.5-1.8,1.1-2.4,2
		c0.1,8.2-1.7,12.3-5.4,12.3c-3,0-4.9-9.2-4.9-9.2c-0.9-0.6-2-1-3.1-1h-0.2c-0.9,0-1.7,0.3-2.4,0.7c-0.1,1.4-0.1,2.7-0.1,3.9
		c0.1,6.2,4.4,10.9,9.9,10.9c1.8,0,3.5-0.5,5-1.4C190.9,156,194.5,150.2,194.5,145.6z"
      />
      <path
        style="fill: #1d1d1b"
        d="M280.4,147h-0.7c-2.9-3.2-4.6-4.2-7.4-4.2c-6.1,0.2-10.9,5.3-10.8,11.4c0,5.5,3.9,10.1,8.4,10.1
		c3.6,0,11-4.9,11.5-11.2V152C281.3,150.3,280.9,148.6,280.4,147z M271.2,159.5c-2.2-2-3-3.2-3-4.5c0-1.5,0.4-2.9,1.1-4.2
		c0.9-1.5,1.6-3.2,3.3-3.2h0.2c2.2,0.1,2.5,2.8,2.5,5.1C275.4,155.7,274.4,157.3,271.2,159.5L271.2,159.5z"
      />
      <path
        style="fill: #1d1d1b"
        d="M250.9,159l0.8-9.7c0,0,3.7,0.3,5,0.3c1.7,0.1,3.1-1.1,3.3-2.8c0-0.1,0-0.1,0-0.2
		c0-2.1-2.6-3.4-7.2-3.4l-0.8-0.8c0.3-1.4,0.5-2.8,0.5-4.2c0-2.3-1-4.4-2.9-4.3h-0.4c-0.6,0.2-1.2,0.6-1.8,1c-0.1,0-1.2,7.9-1.2,7.9
		h-5.7c0,0-1.8,1-2.1,2.1c0,0.2-0.1,0.3-0.1,0.5c0,1.7,2,2.7,4.1,2.7c0.9,0,3.3,0.4,3.3,0.4c0.1,2.7-0.1,5.4-0.5,8
		c-0.4,2-0.8,4-1,6.1v0.5c0,0.4,0,0.8,0.2,1.1c0.5,1.1,2,1.2,3.1,1.2h0.6c1.3-0.3,2.7-0.5,4.1-0.7h0.6c2.2,0.1,4.4,0.4,6.6,0.8
		c1.1,0,2-0.9,2-2v-0.1C261.4,161.1,250.9,159,250.9,159z"
      />
      <path
        style="fill: #1d1d1b"
        d="M229.5,134.2c-0.8,0-5.9,2.1-5.9,2.1c-0.8-0.7-1.7-1.2-2.7-1.3c-0.7,0.1-1.4,0.3-2,0.6l-0.7,11.1
		l0.4,11.8l-0.2,5.5c0.4,0.6,1,1.2,1.6,1.6c0.2,0.1,0.5,0.2,0.8,0.1c1.1-0.1,2.1-0.5,3-1c0.3-2.4,0.5-4.3,0.6-5.4
		c0.5-8.2,0.9-12.9,1.2-13.9c0.3-1.2,0.2-4.8,2.4-5.8c0.4-0.2,0.9-0.4,1.4-0.4h0.3c0.6,0,1.1,0.4,1.1,1c0,0.1,0,0.1,0,0.2
		c0,1.3-0.9,3.3-0.9,4.9c-0.1,5.4-2.1,13.1-2.1,13.6c0.1,1.3,1.2,2.4,2.5,2.4c1.3-0.2,2.6-0.7,3.8-1.3l1.2-9.2c0,0,1-10.1,1-12.1
		c0-0.1,0-0.2,0-0.4C234.9,135.8,232.3,134.3,229.5,134.2z"
      />
      <path
        style="fill: #1d1d1b"
        d="M176.8,167.2c-0.9,0.1-9.8,1.8-9.8,1.8l-0.6-9.1c0,0-1.8-18.3-1.9-19.3c-0.2-3.1-1.7-5.2-3.7-5
		c-0.3,0-0.6,0.1-0.9,0.2c-1.3,0.5-2.2,2.8-2.2,2.8l3.2,32.9l-1.4,3.4c1.8,2.3,2.9,3,5,2.8c0.5,0,3.1-1.8,5.2-2.7
		c3.8-1.4,7.8-2.5,11.8-3.1c0.3-0.1,0.5-0.7,0.4-1.5C181.7,168,180,167,176.8,167.2z"
      />
      <path
        style="fill: #1d1d1b"
        d="M203.5,165.3c-1.2-0.1-2.4,0-3.6,0.2c0-0.4-0.4-6.8-2.4-6.9c-0.9-0.2-1.9,0.1-2.4,0.9l-0.4,6
		l-7.5,0.9c0,3.7,0.3,4,4.3,4.3c1.1,0.1,2.2,0.1,3.3,0c-0.2,0.8-0.3,1.7-0.3,2.5c-0.2,3,0.7,4.6,2.9,4.8c1.3,0.1,2.3-3.2,2.4-5.4
		c0.1-0.5,0-1-0.1-1.5h0.1c0.8,0.1,7-0.6,7.2-2.8C207.1,166.5,205.8,165.4,203.5,165.3z"
      />
    </g>
    <g>
	<path class="st2" d="M60.5,343.7c0.6-0.2,1.4-0.2,2.3-0.2c1.3,0,2.4,0.2,3.1,1c0.7,0.6,1,1.6,1,2.8c0,1.3-0.4,2.2-0.9,2.9
		c-0.7,0.9-1.9,1.3-3,1.3c-0.2,0-0.4,0-0.6,0v5h-2V343.7z M62.5,349.7c0.1,0,0.3,0,0.5,0c1.3,0,2-0.9,2-2.4c0-1.2-0.5-2.2-1.8-2.2
		c-0.3,0-0.5,0-0.7,0.1V349.7z"/>
	<path class="st2" d="M70.1,344.6c0,0.7-0.4,1.2-1.1,1.2c-0.6,0-1-0.5-1-1.2c0-0.7,0.5-1.2,1.1-1.2
		C69.7,343.5,70.1,343.9,70.1,344.6z M68.1,356.4v-9.3h2v9.3H68.1z"/>
	<path class="st2" d="M73.7,344.6c0,0.7-0.4,1.2-1.1,1.2c-0.6,0-1-0.5-1-1.2c0-0.7,0.5-1.2,1.1-1.2
		C73.3,343.5,73.7,343.9,73.7,344.6z M71.7,356.4v-9.3h2v9.3H71.7z"/>
	<path class="st2" d="M75.3,349.7c0-0.9-0.1-1.8-0.1-2.6H77l0.1,1.4h0.1c0.4-1,1.1-1.6,1.8-1.6c0.2,0,0.3,0,0.4,0v2
		c-0.1,0-0.2,0-0.4,0c-0.7,0-1.4,0.6-1.6,1.6c-0.1,0.2-0.1,0.5-0.1,0.9v4.9h-2V349.7z"/>
	<path class="st2" d="M80.2,349.7c0-0.9-0.1-1.8-0.1-2.6h1.7l0.1,1.4H82c0.4-1,1.1-1.6,1.8-1.6c0.2,0,0.3,0,0.4,0v2
		c-0.1,0-0.2,0-0.4,0c-0.7,0-1.4,0.6-1.6,1.6c-0.1,0.2-0.1,0.5-0.1,0.9v4.9h-2V349.7z"/>
	<path class="st2" d="M88.8,356.4l-0.1-1h0c-0.5,0.7-1.1,1.1-1.9,1.1c-1.3,0-2.3-1.1-2.3-2.8c0-2.4,2-3.4,4-3.4v-0.2
		c0-1-0.4-1.6-1.4-1.6c-0.6,0-1.2,0.2-1.7,0.5l-0.4-1.4c0.4-0.3,1.4-0.7,2.5-0.7c2.3,0,2.9,1.6,2.9,3.6v3.7c0,0.7,0,1.5,0.1,2.1
		H88.8z M85.2,344.6c0-0.6,0.4-1.1,1-1.1c0.6,0,1,0.4,1,1.1c0,0.6-0.4,1.1-1,1.1C85.7,345.6,85.2,345.2,85.2,344.6z M88.6,351.7
		c-0.8,0-2.2,0.2-2.2,1.9c0,1.1,0.5,1.4,1,1.4c0.5,0,1-0.3,1.1-1.1c0-0.1,0.1-0.3,0.1-0.5V351.7z M88.3,344.6c0-0.6,0.4-1.1,1-1.1
		c0.5,0,1,0.4,1,1.1c0,0.6-0.4,1.1-1,1.1S88.3,345.2,88.3,344.6z"/>
	<path class="st2" d="M101,351.7c0,3.8-1.9,4.8-3.2,4.8c-1.9,0-3.2-1.6-3.2-4.8c0-3.4,1.6-4.8,3.3-4.8
		C99.7,346.9,101,348.6,101,351.7z M96.5,351.7c0,1.6,0.3,3.3,1.3,3.3c1,0,1.2-1.9,1.2-3.3c0-1.4-0.2-3.2-1.2-3.2
		C96.7,348.5,96.5,350.4,96.5,351.7z"/>
	<path class="st2" d="M102.3,349.7c0-1.1,0-1.8-0.1-2.6h1.7l0.1,1.1h0c0.4-0.7,1.1-1.3,2.1-1.3c0.9,0,1.6,0.5,1.9,1.3h0
		c0.2-0.4,0.6-0.8,0.9-1c0.4-0.2,0.8-0.3,1.3-0.3c1.4,0,2.3,1.1,2.3,3.4v6h-2v-5.6c0-1.1-0.1-2.1-1-2.1c-0.5,0-0.9,0.4-1.1,1.1
		c0,0.2-0.1,0.5-0.1,0.7v5.9h-2v-5.9c0-0.8-0.1-1.8-1-1.8c-0.5,0-0.9,0.4-1.1,1.1c-0.1,0.2-0.1,0.5-0.1,0.7v5.9h-2V349.7z"/>
	<path class="st2" d="M118.1,356.4l-0.1-1h0c-0.5,0.7-1.1,1.1-1.9,1.1c-1.3,0-2.3-1.1-2.3-2.8c0-2.4,2-3.4,4-3.4v-0.2
		c0-1-0.4-1.6-1.4-1.6c-0.6,0-1.2,0.2-1.7,0.5l-0.4-1.4c0.4-0.3,1.4-0.7,2.5-0.7c2.3,0,2.9,1.6,2.9,3.6v3.7c0,0.7,0,1.5,0.1,2.1
		H118.1z M117.8,351.7c-0.8,0-2.2,0.2-2.2,1.9c0,1.1,0.5,1.4,1,1.4c0.5,0,1-0.3,1.1-1.1c0-0.1,0.1-0.3,0.1-0.5V351.7z"/>
	<path class="st2" d="M124.2,343.2h2v13.2h-2V343.2z"/>
	<path class="st2" d="M129.9,344.6c0,0.7-0.4,1.2-1.1,1.2c-0.6,0-1-0.5-1-1.2c0-0.7,0.5-1.2,1.1-1.2
		C129.5,343.5,129.9,343.9,129.9,344.6z M127.9,356.4v-9.3h2v9.3H127.9z"/>
	<path class="st2" d="M131.5,349.7c0-1.1,0-1.8-0.1-2.6h1.7l0.1,1.1h0c0.4-0.7,1.2-1.2,2.1-1.2c1.5,0,2.3,1.2,2.3,3.4v6.1h-2v-5.9
		c0-0.9-0.2-1.8-1.1-1.8c-0.5,0-0.9,0.4-1.1,1.1c-0.1,0.2-0.1,0.5-0.1,0.8v5.8h-2V349.7z"/>
	<path class="st2" d="M141.6,344.6v2.5h1.7v1.5h-1.7v4.8c0,1.1,0.4,1.4,0.9,1.4c0.2,0,0.4,0,0.6-0.1l0,1.6c-0.3,0.1-0.7,0.2-1.3,0.2
		c-0.6,0-1.2-0.2-1.6-0.6c-0.4-0.5-0.7-1.1-0.7-2.5v-4.8h-1.1v-1.5h1.1v-2L141.6,344.6z"/>
	<path class="st2" d="M150.5,353.7c0,1,0,1.9,0.1,2.7h-1.7l-0.1-1h0c-0.4,0.6-1.1,1.1-2,1.1c-1.7,0-2.3-1.3-2.3-3.7v-5.8h2v5.5
		c0,1.3,0.2,2.3,1.1,2.3c0.6,0,0.9-0.6,1-0.9c0.1-0.2,0.1-0.5,0.1-0.8v-6h2V353.7z"/>
	<path class="st2" d="M154.1,351.3L154.1,351.3c0.2-0.5,0.4-0.8,0.5-1.2l1.4-3h2.2l-2.3,3.9l2.4,5.4h-2.2l-1.6-4.1l-0.5,0.8v3.3h-2
		v-13.2h2V351.3z"/>
	<path class="st2" d="M163.1,356.4l-0.1-1h0c-0.5,0.7-1.1,1.1-1.9,1.1c-1.3,0-2.3-1.1-2.3-2.8c0-2.4,2-3.4,4-3.4v-0.2
		c0-1-0.4-1.6-1.4-1.6c-0.6,0-1.2,0.2-1.7,0.5l-0.4-1.4c0.4-0.3,1.4-0.7,2.5-0.7c2.3,0,2.9,1.6,2.9,3.6v3.7c0,0.7,0,1.5,0.1,2.1
		H163.1z M162.8,351.7c-0.8,0-2.2,0.2-2.2,1.9c0,1.1,0.5,1.4,1,1.4c0.5,0,1-0.3,1.1-1.1c0-0.1,0.1-0.3,0.1-0.5V351.7z"/>
	<path class="st2" d="M167.5,347.1l0.9,4.6c0.1,0.7,0.3,1.6,0.4,2.4h0c0.1-0.9,0.2-1.6,0.4-2.4l0.9-4.6h2l-2.4,9.3h-1.8l-2.4-9.3
		H167.5z"/>
	<path class="st2" d="M174.3,352.3c0,2.1,1,2.7,2,2.7c0.6,0,1.2-0.2,1.6-0.3l0.3,1.5c-0.6,0.3-1.4,0.4-2.3,0.4
		c-2.3,0-3.6-1.7-3.6-4.6c0-3.1,1.4-5,3.4-5c1.9,0,2.9,1.8,2.9,4.3c0,0.5,0,0.8-0.1,1.1H174.3z M176.7,350.8c0-1.5-0.5-2.4-1.2-2.4
		c-0.9,0-1.2,1.3-1.3,2.4H176.7z"/>
	<path class="st2" d="M179.8,349.7c0-0.9-0.1-1.8-0.1-2.6h1.7l0.1,1.4h0.1c0.4-1,1.1-1.6,1.8-1.6c0.2,0,0.3,0,0.4,0v2
		c-0.1,0-0.2,0-0.4,0c-0.7,0-1.4,0.6-1.6,1.6c-0.1,0.2-0.1,0.5-0.1,0.9v4.9h-2V349.7z"/>
	<path class="st2" d="M186.7,344.6c0,0.7-0.4,1.2-1.1,1.2c-0.6,0-1-0.5-1-1.2c0-0.7,0.5-1.2,1.1-1.2
		C186.3,343.5,186.7,343.9,186.7,344.6z M184.7,356.4v-9.3h2v9.3H184.7z"/>
	<path class="st2" d="M188.2,354.5c0.3,0.2,1,0.5,1.6,0.5c0.7,0,1.1-0.4,1.1-1.1c0-0.6-0.3-1-1.1-1.5c-1.3-0.8-1.8-1.7-1.8-2.7
		c0-1.6,1.1-2.8,2.8-2.8c0.7,0,1.3,0.2,1.7,0.5l-0.4,1.5c-0.4-0.2-0.8-0.4-1.2-0.4c-0.7,0-1,0.5-1,1c0,0.5,0.2,0.8,1.1,1.4
		c1.2,0.7,1.8,1.7,1.8,2.8c0,1.9-1.3,2.9-3,2.9c-0.7,0-1.5-0.2-1.9-0.5L188.2,354.5z"/>
	<path class="st2" d="M195.9,344.6c0,0.7-0.4,1.2-1.1,1.2c-0.6,0-1-0.5-1-1.2c0-0.7,0.5-1.2,1.1-1.2
		C195.4,343.5,195.9,343.9,195.9,344.6z M193.8,356.4v-9.3h2v9.3H193.8z"/>
	<path class="st2" d="M198.7,358.6c0.4-0.1,0.9-0.2,1.2-0.6c0.3-0.4,0.4-1.1,0.4-2.7v-8.2h2v8.7c0,2-0.4,3-1.1,3.6
		c-0.6,0.5-1.6,0.8-2.3,0.8L198.7,358.6z M202.3,344.6c0,0.7-0.5,1.2-1.1,1.2c-0.6,0-1.1-0.5-1-1.2c0-0.7,0.5-1.2,1.1-1.2
		S202.3,343.9,202.3,344.6z"/>
	<path class="st2" d="M207.7,356.4l-0.1-1h0c-0.5,0.7-1.1,1.1-1.9,1.1c-1.3,0-2.3-1.1-2.3-2.8c0-2.4,2-3.4,4-3.4v-0.2
		c0-1-0.4-1.6-1.4-1.6c-0.6,0-1.2,0.2-1.7,0.5l-0.4-1.4c0.4-0.3,1.4-0.7,2.5-0.7c2.3,0,2.9,1.6,2.9,3.6v3.7c0,0.7,0,1.5,0.1,2.1
		H207.7z M207.4,351.7c-0.8,0-2.2,0.2-2.2,1.9c0,1.1,0.5,1.4,1,1.4c0.5,0,1-0.3,1.1-1.1c0-0.1,0.1-0.3,0.1-0.5V351.7z"/>
	<path class="st2" d="M216.1,344.6v2.5h1.7v1.5h-1.7v4.8c0,1.1,0.4,1.4,0.9,1.4c0.2,0,0.4,0,0.6-0.1l0,1.6c-0.3,0.1-0.7,0.2-1.3,0.2
		c-0.6,0-1.2-0.2-1.6-0.6c-0.4-0.5-0.7-1.1-0.7-2.5v-4.8h-1.1v-1.5h1.1v-2L216.1,344.6z"/>
	<path class="st2" d="M220.3,352.3c0,2.1,1,2.7,2,2.7c0.6,0,1.2-0.2,1.6-0.3l0.3,1.5c-0.6,0.3-1.4,0.4-2.3,0.4
		c-2.3,0-3.6-1.7-3.6-4.6c0-3.1,1.4-5,3.4-5c1.9,0,2.9,1.8,2.9,4.3c0,0.5,0,0.8-0.1,1.1H220.3z M222.8,350.8c0-1.5-0.5-2.4-1.2-2.4
		c-0.9,0-1.2,1.3-1.3,2.4H222.8z"/>
	<path class="st2" d="M227.5,352.3c0,2.1,1,2.7,2,2.7c0.6,0,1.2-0.2,1.6-0.3l0.3,1.5c-0.6,0.3-1.4,0.4-2.3,0.4
		c-2.3,0-3.6-1.7-3.6-4.6c0-3.1,1.4-5,3.4-5c1.9,0,2.9,1.8,2.9,4.3c0,0.5,0,0.8-0.1,1.1H227.5z M229.9,350.8c0-1.5-0.5-2.4-1.2-2.4
		c-0.9,0-1.2,1.3-1.3,2.4H229.9z"/>
	<path class="st2" d="M237.1,347.1l0.9,4.6c0.1,0.7,0.3,1.6,0.4,2.4h0c0.1-0.9,0.2-1.6,0.4-2.4l0.9-4.6h2l-2.4,9.3h-1.8l-2.4-9.3
		H237.1z"/>
	<path class="st2" d="M244.5,344.6c0,0.7-0.4,1.2-1.1,1.2c-0.6,0-1-0.5-1-1.2c0-0.7,0.5-1.2,1.1-1.2
		C244.1,343.5,244.5,343.9,244.5,344.6z M242.5,356.4v-9.3h2v9.3H242.5z"/>
	<path class="st2" d="M252.2,343.2v10.7c0,0.8,0,1.8,0.1,2.5h-1.7l-0.1-1.1h-0.1c-0.3,0.7-1,1.3-1.9,1.3c-1.8,0-2.7-2-2.7-4.8
		c0-3.3,1.4-4.8,2.8-4.8c0.7,0,1.3,0.4,1.6,1h0v-4.8H252.2z M250.2,350.6c0-0.2,0-0.4,0-0.6c-0.1-0.7-0.5-1.4-1.1-1.4
		c-1,0-1.4,1.5-1.4,3.1c0,1.9,0.5,3.1,1.3,3.1c0.4,0,0.8-0.2,1.1-1.1c0.1-0.2,0.1-0.4,0.1-0.6V350.6z"/>
	<path class="st2" d="M255.4,352.3c0,2.1,1,2.7,2,2.7c0.6,0,1.2-0.2,1.6-0.3l0.3,1.5c-0.6,0.3-1.4,0.4-2.3,0.4
		c-2.3,0-3.6-1.7-3.6-4.6c0-3.1,1.4-5,3.4-5c1.9,0,2.9,1.8,2.9,4.3c0,0.5,0,0.8-0.1,1.1H255.4z M257.9,350.8c0-1.5-0.5-2.4-1.2-2.4
		c-0.9,0-1.2,1.3-1.3,2.4H257.9z"/>
	<path class="st2" d="M267.1,351.7c0,3.8-1.9,4.8-3.2,4.8c-1.9,0-3.2-1.6-3.2-4.8c0-3.4,1.6-4.8,3.3-4.8
		C265.8,346.9,267.1,348.6,267.1,351.7z M262.6,351.7c0,1.6,0.3,3.3,1.3,3.3c1,0,1.2-1.9,1.2-3.3c0-1.4-0.2-3.2-1.2-3.2
		C262.8,348.5,262.6,350.4,262.6,351.7z"/>
	<path class="st2" d="M268.4,348.6c0-0.8,0.5-1.4,1.2-1.4c0.7,0,1.2,0.5,1.2,1.4c0,0.8-0.4,1.4-1.2,1.4
		C268.9,349.9,268.4,349.4,268.4,348.6z M268.4,355.2c0-0.8,0.5-1.4,1.2-1.4c0.7,0,1.2,0.5,1.2,1.4c0,0.8-0.4,1.4-1.2,1.4
		C268.9,356.5,268.4,356,268.4,355.2z"/>
	<path class="st2" d="M78.6,375.5l-0.1-0.9h0c-0.5,0.7-1.2,1.1-2.1,1.1c-1.4,0-2.5-1.2-2.5-2.9c0-2.4,2.2-3.5,4.3-3.5v-0.2
		c0-0.7-0.4-1.3-1.3-1.3c-0.7,0-1.4,0.2-1.9,0.5l-0.5-1.7c0.5-0.3,1.6-0.7,2.9-0.7c2.9,0,3.3,2,3.3,3.9v3.5c0,0.7,0,1.5,0.2,2.1
		H78.6z M78.3,371c-0.8,0-2,0.3-2,1.6c0,0.9,0.5,1.3,0.9,1.3c0.4,0,0.9-0.2,1-0.8c0-0.1,0.1-0.3,0.1-0.4V371z"/>
	<path class="st2" d="M82.5,369c0-0.9,0-1.9-0.1-2.8h2.2l0.1,1.4h0.1c0.4-1,1.2-1.5,1.9-1.5c0.2,0,0.4,0,0.5,0v2.5
		c-0.2,0-0.3,0-0.5,0c-0.5,0-1.2,0.4-1.5,1.3c-0.1,0.2-0.1,0.5-0.1,0.9v4.7h-2.5V369z"/>
	<path class="st2" d="M88.1,362.3h2.5v13.2h-2.5V362.3z"/>
	<path class="st2" d="M96.6,375.5l-0.1-0.9h0c-0.5,0.7-1.2,1.1-2.1,1.1c-1.4,0-2.5-1.2-2.5-2.9c0-2.4,2.2-3.5,4.3-3.5v-0.2
		c0-0.7-0.4-1.3-1.3-1.3c-0.7,0-1.4,0.2-1.9,0.5l-0.5-1.7c0.5-0.3,1.6-0.7,2.9-0.7c2.9,0,3.3,2,3.3,3.9v3.5c0,0.7,0,1.5,0.2,2.1
		H96.6z M96.2,371c-0.8,0-2,0.3-2,1.6c0,0.9,0.5,1.3,0.9,1.3c0.4,0,0.9-0.2,1-0.8c0-0.1,0.1-0.3,0.1-0.4V371z"/>
	<path class="st2" d="M100.5,374c0-1,0.7-1.7,1.5-1.7c0.8,0,1.5,0.6,1.5,1.7c0,1-0.6,1.7-1.5,1.7C101.2,375.7,100.5,375,100.5,374z"
		/>
	<path class="st2" d="M105.4,375.5v-7.6h-1.3v-1.8h1.3v-0.5c0-1.2,0.7-3.5,4.1-3.5c0.9,0,1.7,0.2,2.2,0.4l-0.4,2
		c-0.4-0.2-1-0.3-1.8-0.3c-1.4,0-1.7,0.8-1.7,1.6v0.3h4.7v9.4H110v-7.6h-2.1v7.6H105.4z"/>
	<path class="st2" d="M113,376.3l4.5-13.8h1.6l-4.5,13.8H113z"/>
	<path class="st2" d="M119.6,362.3h2.5v13.2h-2.5V362.3z"/>
	<path class="st2" d="M130.8,372.7c0,1,0,2,0.1,2.9h-2.2l-0.1-1h0c-0.4,0.6-1.2,1.1-2.2,1.1c-1.8,0-2.5-1.3-2.5-3.8v-5.7h2.5v5.4
		c0,1.2,0.2,2,0.9,2c0.4,0,0.7-0.3,0.8-0.6c0.1-0.2,0.2-0.5,0.2-0.8v-6h2.5V372.7z"/>
	<path class="st2" d="M139.4,370.8c0,3.9-2.2,4.9-3.6,4.9c-2.2,0-3.6-1.8-3.6-4.8c0-3.4,1.8-4.9,3.6-4.9
		C138.1,366,139.4,367.9,139.4,370.8z M134.7,370.8c0,1.6,0.2,2.9,1.1,2.9c0.8,0,1-1.6,1-2.9c0-1.3-0.2-2.9-1.1-2.9
		C134.9,367.9,134.7,369.5,134.7,370.8z"/>
	<path class="st2" d="M140.8,369c0-1-0.1-1.9-0.1-2.8h2.2l0.1,1.1h0c0.5-0.7,1.3-1.2,2.3-1.2c1.8,0,2.5,1.4,2.5,3.5v6h-2.5v-5.9
		c0-0.8-0.2-1.5-0.9-1.5c-0.4,0-0.8,0.3-0.9,0.8c-0.1,0.2-0.1,0.5-0.1,0.9v5.7h-2.5V369z"/>
	<path class="st2" d="M152.5,363.6v2.5h1.8v1.8h-1.8v4.3c0,1.1,0.5,1.4,1,1.4c0.2,0,0.4,0,0.7-0.1l0,1.9c-0.3,0.1-0.9,0.2-1.5,0.2
		c-0.7,0-1.4-0.2-1.8-0.6c-0.5-0.5-0.8-1.2-0.8-2.7v-4.4h-1.1v-1.8h1.1v-1.9L152.5,363.6z"/>
	<path class="st2" d="M162.2,370.8c0,3.9-2.2,4.9-3.6,4.9c-2.2,0-3.6-1.8-3.6-4.8c0-3.4,1.8-4.9,3.6-4.9
		C160.9,366,162.2,367.9,162.2,370.8z M157.6,370.8c0,1.6,0.2,2.9,1.1,2.9c0.8,0,1-1.6,1-2.9c0-1.3-0.2-2.9-1.1-2.9
		C157.7,367.9,157.6,369.5,157.6,370.8z"/>
	<path class="st2" d="M163.6,369.1c0-1.1,0-2.1-0.1-3h2.2l0.1,1.1h0c0.4-0.8,1.1-1.2,1.9-1.2c2.1,0,2.9,2.2,2.9,4.8
		c0,3.3-1.5,4.8-3,4.8c-0.8,0-1.3-0.5-1.5-0.9h0v4.4h-2.5V369.1z M166.1,372.1c0,0.3,0,0.6,0.1,0.8c0.2,0.6,0.5,0.7,0.8,0.7
		c0.9,0,1.2-1.3,1.2-2.8c0-1.8-0.4-2.7-1.2-2.7c-0.4,0-0.7,0.4-0.8,0.8c-0.1,0.2-0.1,0.4-0.1,0.7V372.1z"/>
	<path class="st2" d="M172.2,362.3h2.5v13.2h-2.5V362.3z"/>
	<path class="st2" d="M183.3,372.7c0,1,0,2,0.1,2.9h-2.2l-0.1-1h0c-0.4,0.6-1.2,1.1-2.2,1.1c-1.8,0-2.5-1.3-2.5-3.8v-5.7h2.5v5.4
		c0,1.2,0.2,2,0.9,2c0.4,0,0.7-0.3,0.8-0.6c0.1-0.2,0.2-0.5,0.2-0.8v-6h2.5V372.7z"/>
	<path class="st2" d="M185,373.2c0.4,0.2,1.1,0.5,1.7,0.5c0.7,0,1-0.3,1-0.8c0-0.5-0.3-0.8-1.1-1.3c-1.5-0.9-1.9-1.8-1.9-2.7
		c0-1.7,1.2-2.9,3.1-2.9c0.8,0,1.4,0.2,1.9,0.4l-0.4,1.8c-0.4-0.2-0.8-0.4-1.3-0.4c-0.6,0-0.9,0.4-0.9,0.8s0.2,0.6,1.1,1.2
		c1.4,0.8,1.9,1.8,1.9,2.8c0,1.9-1.4,2.9-3.3,2.9c-0.8,0-1.7-0.2-2.1-0.5L185,373.2z"/>
	<path class="st2" d="M193.8,370.2L193.8,370.2c0.2-0.5,0.3-0.9,0.5-1.3l1.2-2.8h2.7l-2.3,3.9l2.4,5.4h-2.8l-1.3-3.8l-0.4,0.8v3
		h-2.5v-13.2h2.5V370.2z"/>
	<path class="st2" d="M203.6,375.5l-0.1-0.9h0c-0.5,0.7-1.2,1.1-2.1,1.1c-1.4,0-2.5-1.2-2.5-2.9c0-2.4,2.2-3.5,4.3-3.5v-0.2
		c0-0.7-0.4-1.3-1.3-1.3c-0.7,0-1.4,0.2-1.9,0.5l-0.5-1.7c0.5-0.3,1.6-0.7,2.9-0.7c2.9,0,3.3,2,3.3,3.9v3.5c0,0.7,0,1.5,0.2,2.1
		H203.6z M203.3,371c-0.8,0-2,0.3-2,1.6c0,0.9,0.5,1.3,0.9,1.3c0.4,0,0.9-0.2,1-0.8c0-0.1,0.1-0.3,0.1-0.4V371z"/>
	<path class="st2" d="M207.5,369c0-1-0.1-1.9-0.1-2.8h2.2l0.1,1.1h0c0.5-0.7,1.3-1.2,2.2-1.2c1.1,0,1.7,0.5,2,1.3h0
		c0.2-0.4,0.6-0.7,0.9-0.9c0.4-0.2,0.8-0.3,1.3-0.3c1.7,0,2.4,1.3,2.4,3.6v5.9h-2.5V370c0-1.1-0.1-1.9-0.9-1.9
		c-0.4,0-0.8,0.3-0.9,0.9c0,0.1-0.1,0.4-0.1,0.6v5.9h-2.5v-5.9c0-0.7-0.1-1.6-0.9-1.6c-0.4,0-0.8,0.3-0.9,0.8
		c-0.1,0.2-0.1,0.4-0.1,0.7v5.9h-2.5V369z"/>
	<path class="st2" d="M220.5,369.1c0-1.1,0-2.1-0.1-3h2.2l0.1,1.1h0c0.4-0.8,1.1-1.2,1.9-1.2c2.1,0,2.9,2.2,2.9,4.8
		c0,3.3-1.5,4.8-3,4.8c-0.8,0-1.3-0.5-1.5-0.9h0v4.4h-2.5V369.1z M223,372.1c0,0.3,0,0.6,0.1,0.8c0.2,0.6,0.5,0.7,0.8,0.7
		c0.9,0,1.2-1.3,1.2-2.8c0-1.8-0.4-2.7-1.2-2.7c-0.4,0-0.7,0.4-0.8,0.8c-0.1,0.2-0.1,0.4-0.1,0.7V372.1z"/>
	<path class="st2" d="M233.2,375.5l-0.1-0.9h0c-0.5,0.7-1.2,1.1-2.1,1.1c-1.4,0-2.5-1.2-2.5-2.9c0-2.4,2.2-3.5,4.3-3.5v-0.2
		c0-0.7-0.4-1.3-1.3-1.3c-0.7,0-1.4,0.2-1.9,0.5l-0.5-1.7c0.5-0.3,1.6-0.7,2.9-0.7c2.9,0,3.3,2,3.3,3.9v3.5c0,0.7,0,1.5,0.2,2.1
		H233.2z M232.9,371c-0.8,0-2,0.3-2,1.6c0,0.9,0.5,1.3,0.9,1.3c0.4,0,0.9-0.2,1-0.8c0-0.1,0.1-0.3,0.1-0.4V371z"/>
	<path class="st2" d="M237.1,369c0-1-0.1-1.9-0.1-2.8h2.2l0.1,1.1h0c0.5-0.7,1.3-1.2,2.3-1.2c1.8,0,2.5,1.4,2.5,3.5v6h-2.5v-5.9
		c0-0.8-0.2-1.5-0.9-1.5c-0.4,0-0.8,0.3-0.9,0.8c-0.1,0.2-0.1,0.5-0.1,0.9v5.7h-2.5V369z"/>
	<path class="st2" d="M244.3,377.3c0.4-0.1,0.9-0.2,1.2-0.5c0.4-0.4,0.4-1.1,0.4-2.5v-8.2h2.5v8.7c0,2.1-0.4,3.1-1.2,3.7
		c-0.7,0.6-1.8,0.8-2.7,0.8L244.3,377.3z M248.4,363.7c0,0.8-0.6,1.4-1.3,1.4c-0.7,0-1.3-0.6-1.2-1.4c0-0.8,0.6-1.4,1.3-1.4
		C247.9,362.3,248.4,362.8,248.4,363.7z"/>
	<path class="st2" d="M254.3,375.5l-0.1-0.9h0c-0.5,0.7-1.2,1.1-2.1,1.1c-1.4,0-2.5-1.2-2.5-2.9c0-2.4,2.2-3.5,4.3-3.5v-0.2
		c0-0.7-0.4-1.3-1.3-1.3c-0.7,0-1.4,0.2-1.9,0.5l-0.5-1.7c0.5-0.3,1.6-0.7,2.9-0.7c2.9,0,3.3,2,3.3,3.9v3.5c0,0.7,0,1.5,0.2,2.1
		H254.3z M254,371c-0.8,0-2,0.3-2,1.6c0,0.9,0.5,1.3,0.9,1.3c0.4,0,0.9-0.2,1-0.8c0-0.1,0.1-0.3,0.1-0.4V371z"/>
</g> 
    <path
      style="fill: #ffe171"
      d="M198.3,189.3c-5.1,0.2-94.8,1-99.8,1.5c-12.2,1.3-24,13.3-24.1,30.1c0,8.8-0.4,17.6-0.4,26.4
	c0,4.4-0.2,9,0.8,13.3c2.3,9.4,5.7,18,13.5,22.7c3.1,1.9,9.7,1.3,12.9,1.5c7.8,0.6,96.6,0.9,104.4,1c8.2,0.1,16.5,0.1,24.7-0.6
	c7.2-0.6,13.8-4.7,18.3-11.9c3.7-6.9,3.8-12.9,3.8-19.4c0-2.3,15.9,1.4,15.8-11.4c0,0-15.7,3.8-15.9-1.4c-0.1-3.2,0-6.4-0.1-9.7
	c-0.1-4.2,0-8.6,0-12.7c-2.2-23.2-16.6-27.2-33-29C213.9,189.1,207.9,189,198.3,189.3z"
    />
    <g>
      <path
        style="fill: #28348a"
        d="M332.4,158.9v0.3c0,0,0,0,0,0.1h-3c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.3v-5.8
		c0,0,0,0,0-0.1h0.8c0,0,0,0,0,0.1v5.4h2.3c0,0,0,0,0,0.1v0.1v0.2H332.4z"
      />
      <path
        style="fill-rule: evenodd; clip-rule: evenodd; fill: #28348a"
        d="M339.2,159c0,0.1,0,0.1,0,0.2v0.1h-0.8v-0.1l-0.5-1.5h-2.6
		l-0.5,1.5v0.1c0,0,0,0-0.1,0.1H334v-0.1c0-0.1,0-0.1,0-0.2l2.1-5.8v-0.1l0.1-0.1h0.8l0.1,0.1v0.1L339.2,159L339.2,159z
		 M336.5,153.9L336.5,153.9l-1.1,3.1h2.2L336.5,153.9L336.5,153.9z"
      />
      <path
        style="fill: #28348a"
        d="M344.8,157.5c0,0.3-0.1,0.5-0.2,0.8c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.2-0.4,0.3-0.7,0.4
		c-0.3,0.1-0.6,0.1-0.8,0.1c-0.4,0-0.7-0.1-1.1-0.2c-0.1,0-0.2-0.1-0.4-0.2l-0.2-0.1l-0.1-0.1c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2
		v-0.1c0,0,0,0,0-0.1l0,0c0.1,0,0.1,0,0.2,0.1l0.3,0.2c0.2,0.1,0.3,0.1,0.5,0.2c0.2,0.1,0.4,0.1,0.6,0.1s0.3,0,0.5-0.1
		c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.2-0.3-0.3
		c-0.1-0.1-0.3-0.2-0.5-0.3l-0.5-0.2l-0.5-0.3c-0.2-0.1-0.3-0.2-0.5-0.4c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.4-0.1-0.7
		c0-0.2,0-0.5,0.1-0.7s0.2-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.3s0.5-0.1,0.8-0.1c0.1,0,0.3,0,0.4,0s0.3,0,0.4,0.1
		c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0.1,0.2,0.1l0.1,0.1c0,0,0,0,0,0.1v0.2v0.1v0.1c0,0,0,0,0,0.1h-0.1c-0.1,0-0.1,0-0.2-0.1
		l-0.3-0.1c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2,0-0.3-0.1-0.5-0.1s-0.3,0-0.5,0.1s-0.4,0.2-0.5,0.5c0,0.1-0.1,0.2-0.1,0.3
		c0,0.2,0,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.2,0.5,0.3l0.5,0.2c0.2,0.1,0.4,0.2,0.5,0.3c0.2,0.1,0.3,0.2,0.5,0.4
		c0.1,0.1,0.2,0.3,0.3,0.5C344.7,157,344.7,157.2,344.8,157.5L344.8,157.5z"
      />
      <path
        style="fill: #28348a"
        d="M351.1,153.4c0,0.1,0,0.1,0,0.2v0.1v0.1h-1.8v5.4c0,0,0,0,0,0.1h-0.8v-5.5h-1.8v-0.1v-0.1
		c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2v-0.1V153h4.5c0,0,0,0,0,0.1v0.1C351.2,153.3,351.2,153.3,351.1,153.4L351.1,153.4z"
      />
      <path
        style="fill: #28348a"
        d="M356.7,158.9v0.1v0.1c0,0,0,0-0.1,0.1h-3.1c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.3v-5.6
		c0-0.1,0-0.2,0.1-0.3s0.1-0.1,0.2-0.1h3.1c0,0,0,0,0,0.1v0.1c0,0.1,0,0.2,0,0.3v0.2h-2.5v2h2.2v0.1v0.1c0,0.1,0,0.1,0,0.2v0.1v0.1
		c0,0,0,0,0,0.1h-2.2v2.2h2.5c0,0,0,0,0.1,0.1v0.1C356.8,158.7,356.8,158.8,356.7,158.9L356.7,158.9z"
      />
      <path
        style="fill-rule: evenodd; clip-rule: evenodd; fill: #28348a"
        d="M364,158.9c0,0.1,0,0.1,0,0.2c0,0,0,0.1-0.1,0.1l-0.1,0.1h-0.6
		l-0.2-0.1l-0.2-0.2c-0.1-0.1-0.1-0.2-0.2-0.3l-2-3.6l-0.3-0.6l-0.3-0.6l0,0v5.2c0,0,0,0,0,0.1c0,0,0,0-0.1,0h-0.8v-5.8
		c0-0.1,0-0.2,0.1-0.3c0.1,0,0.1-0.1,0.2-0.1h0.6c0.1,0,0.1,0.1,0.2,0.1s0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.2,0.1,0.2l1.5,2.8l0.3,0.5
		l0.2,0.5l0.2,0.5c0.1,0.1,0.2,0.3,0.2,0.4l0,0V153c0,0,0,0,0.1,0h0.6c0,0,0,0,0.1,0c0,0,0,0,0,0.1L364,158.9L364,158.9z"
      />
      <path
        style="fill: #28348a"
        d="M370.2,157.5c0,0.3,0,0.5-0.2,0.8c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.2-0.4,0.3-0.7,0.4
		c-0.3,0.1-0.6,0.1-0.8,0.1c-0.4,0-0.7-0.1-1.1-0.2c-0.1,0-0.2-0.1-0.4-0.2l-0.2-0.1l-0.1-0.1c0-0.1,0-0.2,0-0.2v-0.3v-0.1h0.1
		c0.1,0,0.1,0,0.2,0.1l0.3,0.2c0.1,0.1,0.3,0.1,0.5,0.2c0.2,0.1,0.4,0.1,0.6,0.1s0.3,0,0.5-0.1c0.1,0,0.3-0.1,0.4-0.2
		c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
		l-0.5-0.2c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2-0.1-0.3-0.2-0.5-0.4c-0.1-0.1-0.3-0.3-0.3-0.5c-0.1-0.2-0.1-0.4-0.1-0.7
		c0-0.2,0-0.5,0.1-0.7s0.2-0.4,0.4-0.5c0.2-0.1,0.4-0.2,0.6-0.3s0.5-0.1,0.8-0.1c0.1,0,0.3,0,0.4,0s0.3,0,0.4,0.1
		c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0.1v0.3v0.1v0.1c0,0,0,0,0,0.1h-0.1c-0.1,0-0.1,0-0.2-0.1l-0.3-0.1
		c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2,0-0.3-0.1-0.5-0.1s-0.3,0-0.5,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.2-0.2,0.3
		c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.2,0.5,0.3l0.5,0.2c0.2,0.1,0.4,0.2,0.5,0.3
		c0.2,0.1,0.3,0.2,0.5,0.4c0.1,0.1,0.3,0.3,0.3,0.5C370.2,157,370.3,157.3,370.2,157.5L370.2,157.5z"
      />
      <path
        style="fill: #28348a"
        d="M377.3,157c0,0.3-0.1,0.7-0.2,1s-0.3,0.5-0.5,0.8c-0.2,0.2-0.5,0.4-0.8,0.5s-0.7,0.2-1,0.2
		s-0.6-0.1-0.9-0.1c-0.3-0.1-0.5-0.2-0.7-0.4s-0.4-0.5-0.5-0.7c-0.1-0.3-0.2-0.7-0.2-1v-3.9c0,0,0,0,0-0.1h0.8c0,0,0,0,0,0.1v3.8
		c0,0.3,0,0.5,0.1,0.8c0.1,0.4,0.4,0.7,0.8,0.9c0.4,0.1,0.9,0.1,1.3,0c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.2,0.2-0.3,0.3-0.5
		c0.1-0.2,0.1-0.5,0.1-0.7v-3.8c0,0,0,0,0-0.1h0.8c0,0,0,0,0,0.1L377.3,157L377.3,157z"
      />
      <path
        style="fill-rule: evenodd; clip-rule: evenodd; fill: #28348a"
        d="M385.4,156.1c0,0.5-0.1,0.9-0.2,1.4c-0.1,0.4-0.3,0.7-0.5,1
		s-0.5,0.5-0.9,0.7c-0.8,0.3-1.7,0.3-2.5,0c-0.3-0.1-0.6-0.3-0.9-0.6c-0.2-0.3-0.4-0.6-0.5-1s-0.2-0.9-0.2-1.4s0.1-0.9,0.2-1.4
		c0.1-0.4,0.3-0.7,0.5-1s0.5-0.5,0.9-0.6c0.4-0.2,0.8-0.2,1.3-0.2c0.4,0,0.8,0.1,1.2,0.2c0.3,0.1,0.6,0.3,0.9,0.6
		c0.2,0.3,0.4,0.6,0.5,1C385.4,155.1,385.4,155.6,385.4,156.1L385.4,156.1z M384.5,156.1c0-0.3,0-0.7-0.1-1c0-0.3-0.2-0.6-0.3-0.8
		c-0.1-0.2-0.3-0.4-0.6-0.5c-0.6-0.3-1.2-0.3-1.8,0c-0.2,0.1-0.4,0.3-0.6,0.6c-0.2,0.2-0.3,0.5-0.3,0.8c-0.1,0.3-0.1,0.7-0.1,1
		s0,0.7,0.1,1s0.2,0.6,0.3,0.8c0.1,0.2,0.3,0.4,0.6,0.5c0.3,0.1,0.6,0.2,0.9,0.2s0.6-0.1,0.9-0.2c0.2-0.1,0.4-0.3,0.6-0.6
		c0.2-0.2,0.3-0.5,0.3-0.8C384.5,156.8,384.5,156.4,384.5,156.1L384.5,156.1z"
      />
      <path
        style="fill: #28348a"
        d="M389.4,157.7c0,0.2,0,0.5-0.1,0.7c0,0.2-0.1,0.4-0.2,0.5s-0.3,0.3-0.4,0.3c-0.2,0.1-0.4,0.1-0.7,0.1
		h-0.3l-0.2-0.1l-0.2-0.1l-0.1-0.1l0,0v-0.6v-0.1h0.2l0.1,0.1l0.2,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.2-0.2
		c0.1-0.1,0.1-0.2,0.1-0.3c0-0.2,0-0.3,0-0.5V153 M388.7,153.1h0.7v4.6L388.7,153.1z"
      />
      <path
        style="fill: #28348a"
        d="M395.8,158.9v0.1v0.1c0,0,0,0,0,0.1h-3.1c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.3v-5.6
		c0-0.1,0-0.2,0.1-0.3s0.1-0.1,0.2-0.1h3.1c0,0,0,0,0,0.1v0.1c0,0.1,0,0.2,0,0.3v0.1l0,0h-2.5v2h2.2l0.1,0.1v0.1c0,0.1,0,0.1,0,0.2
		v0.1v0.1l-0.1,0.1h-2.2v2.2h2.6c0,0,0,0,0,0.1v0.1v0.2L395.8,158.9z"
      />
      <path
        style="fill: #28348a"
        d="M401.6,158.9c0,0.1,0,0.1,0,0.2v0.1c0,0,0,0.1-0.1,0.1h-2.8c-0.1,0-0.2,0-0.2-0.1
		c-0.1-0.1-0.1-0.2-0.1-0.3v-5.8c0,0,0,0,0-0.1h0.8c0,0,0,0,0,0.1v5.4h2.3l0.1,0.1v0.1C401.6,158.8,401.6,158.8,401.6,158.9
		L401.6,158.9z"
      />
      <path
        style="fill: #28348a"
        d="M408.4,157c0,0.3-0.1,0.7-0.2,1c-0.2,0.6-0.6,1-1.2,1.2c-0.3,0.1-0.7,0.2-1,0.2s-0.7,0-1-0.1
		s-0.5-0.3-0.7-0.4c-0.2-0.2-0.4-0.5-0.5-0.7c-0.1-0.3-0.2-0.7-0.2-1v-3.9c0,0,0,0,0-0.1h0.8c0,0,0,0,0,0.1v3.8c0,0.3,0,0.5,0.1,0.8
		c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.1,0.3,0.3,0.5,0.3c0.2,0.1,0.4,0.1,0.6,0.1s0.4,0,0.6-0.1s0.4-0.2,0.5-0.3c0.1-0.2,0.2-0.3,0.3-0.5
		c0.1-0.2,0.1-0.5,0.1-0.7v-3.8c0,0,0,0,0-0.1h0.8c0,0,0,0,0,0.1v3.8h0.2V157z"
      />
      <path
        style="fill: #28348a"
        d="M414.5,158.9c0,0.1,0,0.1,0,0.2v0.1c0,0,0,0.1-0.1,0.1h-2.8c-0.1,0-0.2,0-0.2-0.1
		c-0.1-0.1-0.1-0.2-0.1-0.3v-5.8c0,0,0,0,0-0.1h0.8c0,0,0,0,0,0.1v5.4h2.3l0.1,0.1v0.1C414.5,158.8,414.5,158.8,414.5,158.9
		L414.5,158.9z"
      />
      <path
        style="fill: #28348a"
        d="M417.5,159.1L417.5,159.1l-0.8,0.1v-6.1c0,0,0,0,0-0.1h0.1h0.7c0,0,0,0,0,0.1L417.5,159.1
		L417.5,159.1z"
      />
      <path
        style="fill: #28348a"
        d="M421.2,159.1L421.2,159.1l-0.8,0.1c0,0,0,0,0-0.1v-6 M420.5,153.1h0.6 M421.2,153.1v6V153.1z"
      />
      <path
        style="fill: #28348a"
        d="M428,153.4c0,0.1,0,0.1,0,0.2v0.1v0.1h-1.8v5.4c0,0,0,0,0,0.1h-0.9v-5.5h-1.9v-0.1v-0.1
		c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2v-0.1V153h4.5c0,0,0,0,0,0.1v0.1C428,153.3,428,153.3,428,153.4L428,153.4z"
      />
      <path
        style="fill: #28348a"
        d="M434,153.4c0,0.1,0,0.1,0,0.2v0.1v0.1h-1.8v5.4c0,0,0,0,0,0.1h-0.9v-5.5h-1.9v-0.1v-0.1
		c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2v-0.1V153h4.5c0,0,0,0,0,0.1v0.1C433.9,153.3,433.9,153.3,434,153.4L434,153.4z"
      />
      <path
        style="fill-rule: evenodd; clip-rule: evenodd; fill: #28348a"
        d="M441.4,156.1c0,0.5-0.1,0.9-0.2,1.4c-0.1,0.4-0.3,0.7-0.5,1
		s-0.5,0.5-0.9,0.7c-0.8,0.3-1.7,0.3-2.5,0c-0.3-0.1-0.6-0.3-0.9-0.6c-0.2-0.3-0.4-0.6-0.5-1s-0.2-0.9-0.2-1.4s0.1-0.9,0.2-1.4
		c0.1-0.4,0.3-0.7,0.5-1s0.5-0.5,0.9-0.6c0.4-0.2,0.8-0.2,1.3-0.2c0.4,0,0.8,0.1,1.2,0.2c0.3,0.1,0.6,0.3,0.9,0.6
		c0.2,0.3,0.4,0.6,0.5,1C441.3,155.1,441.4,155.6,441.4,156.1L441.4,156.1z M440.5,156.1c0-0.3,0-0.7-0.1-1s-0.2-0.5-0.3-0.8
		c-0.1-0.2-0.3-0.4-0.6-0.5c-0.6-0.3-1.2-0.3-1.8,0c-0.2,0.1-0.4,0.3-0.6,0.6c-0.2,0.2-0.3,0.5-0.3,0.8c-0.1,0.3-0.1,0.7-0.1,1
		s0,0.7,0.1,1s0.2,0.6,0.3,0.8c0.1,0.2,0.3,0.4,0.6,0.5c0.3,0.1,0.6,0.2,0.9,0.2s0.6-0.1,0.9-0.2c0.2-0.1,0.4-0.3,0.6-0.6
		c0.2-0.2,0.3-0.5,0.3-0.8S440.5,156.4,440.5,156.1L440.5,156.1z"
      />
      <path
        style="fill: #28348a"
        d="M336,148.7C336,148.7,336,148.8,336,148.7l-0.1,0.1h-0.6l-0.1-0.1c0,0,0,0,0-0.1v-5.5l0,0l-2.2,5.5
		c0,0,0,0,0,0.1h-0.7v-0.1l-2.1-5.5l0,0v5.5c0,0,0,0,0,0.1l-0.1,0.1h-0.6c0,0-0.1,0-0.1-0.1c0,0,0,0,0-0.1V143
		c0-0.2,0.1-0.4,0.3-0.4l0,0h0.8c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0.1,0.1,0.2c0,0.1,0.1,0.2,0.1,0.2l1.8,4.5l0,0l1.7-4.5
		c0-0.1,0.1-0.2,0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2h0.9c0,0,0.1,0,0.1,0.1l0.1,0.1c0,0.1,0,0.1,0,0.2L336,148.7L336,148.7z"
      />
      <path
        style="fill-rule: evenodd; clip-rule: evenodd; fill: #28348a"
        d="M343.5,148.6c0,0.1,0,0.1,0,0.2v0.1c0,0-0.1,0-0.1,0.1h-0.7
		v-0.1l-0.5-1.5h-2.6l-0.5,1.5c0,0,0,0,0,0.1c0,0,0,0.1-0.1,0.1h-0.7V149c0-0.1,0-0.1,0-0.2l2.1-5.8c0-0.1,0.1-0.1,0.1-0.1h0.8
		c0,0,0.1,0,0.1,0.1c0,0,0,0,0.1,0.1L343.5,148.6L343.5,148.6z M340.8,143.4L340.8,143.4l-1.1,3.1h2.2L340.8,143.4z"
      />
      <path
        style="fill-rule: evenodd; clip-rule: evenodd; fill: #28348a"
        d="M350.5,148.5c0,0.1,0,0.1,0,0.2c0,0,0,0.1-0.1,0.1l-0.1,0.1
		h-0.6c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1-0.1-0.2-0.2-0.3l-2-3.6l-0.3-0.6l-0.3-0.6l0,0v5.2c0,0,0,0,0,0.1
		l-0.1,0.1h-0.6c0,0-0.1,0-0.1-0.1v-5.9c0-0.1,0-0.2,0.1-0.3c0.1,0,0.1-0.1,0.2-0.1h0.6c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0.1,0.1,0.1
		c0.1,0.1,0.1,0.2,0.1,0.2l1.5,2.8c0.1,0.2,0.2,0.3,0.3,0.5l0.2,0.5l0.2,0.5c0.1,0.1,0.2,0.3,0.2,0.4l0,0v-5.1c0,0,0,0,0.1-0.1h0.6
		c0,0,0,0,0.1,0.1c0,0,0,0,0,0.1L350.5,148.5L350.5,148.5z"
      />
      <path
        style="fill-rule: evenodd; clip-rule: evenodd; fill: #28348a"
        d="M358.1,148.5c0,0.1,0,0.1,0,0.2c0,0,0,0.1-0.1,0.1l-0.1,0.1
		h-0.6c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.2l-0.2-0.3l-2-3.6c-0.1-0.2-0.2-0.4-0.3-0.6s-0.2-0.4-0.3-0.6l0,0v5.2
		c0,0,0,0,0,0.1l-0.1,0.1h-0.6l-0.1-0.1v-5.9c0-0.1,0-0.2,0.1-0.3c0.1,0,0.1-0.1,0.2-0.1h0.6c0.1,0,0.1,0,0.2,0.1
		c0.1,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.2,0.1,0.2l1.5,2.8l0.3,0.5l0.2,0.5l0.2,0.5l0.2,0.4l0,0v-5.1c0,0,0,0,0.1-0.1h0.6
		c0,0,0.1,0,0.1,0.1L358.1,148.5L358.1,148.5z"
      />
      <path
        style="fill: #28348a"
        d="M364.4,148.5v0.2v0.1c0,0,0,0.1-0.1,0.1h-3.1c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.3v-5.6
		c0-0.1,0-0.2,0.1-0.3s0.1-0.1,0.2-0.1h3.1v0.1v0.1c0,0.1,0,0.1,0,0.2v0.1v0.1v0.1h-2.5v2h2.1c0,0,0,0,0.1,0c0,0,0,0,0,0.1v0.1
		c0,0.1,0,0.1,0,0.2v0.1v0.1v0.1h-2.2v2.2h2.5c0,0,0,0,0.1,0.1v0.1V148.5L364.4,148.5z"
      />
      <path
        style="fill-rule: evenodd; clip-rule: evenodd; fill: #28348a"
        d="M371.2,148.7C371.2,148.7,371.2,148.8,371.2,148.7l-0.1,0.1
		h-0.7c0,0-0.1,0-0.1-0.1v-0.1l-0.6-1.5c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1-0.2-0.2-0.4-0.2
		c-0.2-0.1-0.3-0.1-0.5-0.1h-0.6v2.7c0,0,0,0,0,0.1l-0.1,0.1h-0.7c0,0-0.1,0-0.1-0.1c0,0,0,0,0-0.1v-5.8c0-0.1,0-0.2,0.1-0.3
		s0.1-0.1,0.2-0.1h2c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.4,0.1,0.8,0,1.2
		c-0.1,0.3-0.3,0.6-0.6,0.8c-0.2,0.1-0.3,0.2-0.5,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.2,0.2c0.1,0.1,0.1,0.2,0.2,0.3
		s0.1,0.3,0.2,0.4l0.6,1.4c0,0.1,0.1,0.2,0.1,0.2L371.2,148.7L371.2,148.7z M369.9,144.3c0-0.2,0-0.4-0.2-0.6
		c-0.1-0.2-0.3-0.3-0.5-0.4h-1.4v2.1h0.8c0.2,0,0.4,0,0.6-0.1c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.2-0.3
		C369.9,144.6,369.9,144.5,369.9,144.3L369.9,144.3z"
      />
      <path
        style="fill: #28348a"
        d="M378.1,148.7C378.1,148.7,378.1,148.8,378.1,148.7l-0.1,0.1h-0.6c0,0-0.1,0-0.1-0.1c0,0,0,0,0-0.1
		v-2.8h-2.9v2.8c0,0,0,0,0,0.1l-0.1,0.1h-0.7c0,0-0.1,0-0.1-0.1c0,0,0,0,0-0.1v-6c0,0,0,0,0-0.1h0.1h0.1h0.4h0.1c0,0,0,0,0.1,0
		c0,0,0,0,0,0.1v2.5h2.9v-2.5c0,0,0,0,0-0.1h0.8c0,0,0,0,0,0.1L378.1,148.7L378.1,148.7z"
      />
      <path
        style="fill: #28348a"
        d="M384.4,148.5c0,0.1,0,0.1,0,0.2v0.1l-0.1,0.1h-3.1c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.3
		v-5.6c0-0.1,0-0.2,0.1-0.3s0.1-0.1,0.2-0.1h3.1l0.1,0.1v0.1c0,0.1,0,0.2,0,0.3v0.1l-0.1,0.1h-2.5v2h2.2l0.1,0.1v0.1
		c0,0.1,0,0.1,0,0.2v0.1v0.1l-0.1,0.1h-2.2v2.2h2.6l0.1,0.1v0.1C384.5,148.3,384.5,148.4,384.4,148.5L384.4,148.5z"
      />
      <path
        style="fill: #28348a"
        d="M387.8,148.7C387.8,148.7,387.8,148.8,387.8,148.7l-0.1,0.1H387c0,0-0.1,0-0.1-0.1c0,0,0,0,0-0.1v-6
		c0,0,0,0,0-0.1h0.1h0.1h0.4h0.1c0,0,0,0,0.1,0c0,0,0,0,0,0.1L387.8,148.7z"
      />
      <path
        style="fill: #28348a"
        d="M397.5,148.7C397.5,148.7,397.5,148.8,397.5,148.7l-0.1,0.1h-0.6l-0.1-0.1c0,0,0,0,0-0.1v-5.5l0,0
		l-2.3,5.5c0,0,0,0,0,0.1h-0.7c0,0,0,0,0-0.1l-2.1-5.5l0,0v5.5c0,0,0,0,0,0.1l-0.1,0.1h-0.6c0,0-0.1,0-0.1-0.1c0,0,0,0,0-0.1V143
		c0-0.2,0.1-0.4,0.3-0.4l0,0h0.8c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0.1,0.1,0.2c0,0.1,0.1,0.2,0.1,0.2l1.8,4.5l0,0l1.9-4.5
		c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0.1,0,0.1-0.1,0.2-0.1h0.9c0,0,0.1,0,0.1,0.1l0.1,0.1c0,0.1,0,0.1,0,0.2L397.5,148.7
		L397.5,148.7z"
      />
      <path
        style="fill: #28348a"
        d="M401.2,148.7C401.2,148.7,401.2,148.8,401.2,148.7l-0.1,0.1h-0.7l-0.1-0.1c0,0,0,0,0-0.1v-6
		c0,0,0,0,0-0.1h0.1h0.1h0.4h0.1h0.1c0,0,0,0,0,0.1L401.2,148.7L401.2,148.7z"
      />
      <path
        style="fill-rule: evenodd; clip-rule: evenodd; fill: #28348a"
        d="M408.8,148.5c0,0.1,0,0.1,0,0.2l-0.1,0.1l-0.1,0.1H408
		c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.2-0.3l-2-3.6l-0.3-0.6l-0.3-0.6l0,0v5.3l-0.1,0.1h-0.6
		c0,0-0.1,0-0.1-0.1c0,0,0,0,0-0.1v-5.8c0-0.1,0-0.2,0.1-0.3c0.1,0,0.1-0.1,0.2-0.1h0.6c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0.1,0.1,0.1
		l0.2,0.2l1.5,2.8c0.1,0.2,0.2,0.3,0.3,0.5l0.2,0.5c0.1,0.2,0.2,0.3,0.2,0.5l0.2,0.4l0,0v-5.1c0,0,0,0,0.1-0.1h0.6c0,0,0,0,0.1,0.1
		L408.8,148.5L408.8,148.5z"
      />
      <path
        style="fill: #0068b2"
        d="M331.1,106.1c0-16.2,13.1-29.3,29.3-29.3c16.2,0,29.3,13.1,29.3,29.3s-13.1,29.3-29.2,29.3
		C344.2,135.4,331.1,122.3,331.1,106.1L331.1,106.1z"
      />
      <path
        style="fill-rule: evenodd; clip-rule: evenodd; fill: #ffffff"
        d="M375.9,97.2c1.1,0,2,0.4,2,1.6v11.6h3.6c1.1,0,2,0.9,2,2
		s-0.9,2-2,2h-5.6c-1.1,0-2-0.9-2-2V98.8C373.9,97.7,374.9,97.2,375.9,97.2L375.9,97.2z M363.8,97.2c1.1,0,2,0.4,2,1.6v11.6h3.5
		c1.1,0,2,0.9,2,2s-0.9,2-2,2h-5.6c-1.1,0-2-0.9-2-2V98.8C361.8,97.7,362.8,97.2,363.8,97.2L363.8,97.2z M346.3,109.7l-3.2-7.2
		c-0.3,1.9-0.4,3.8-0.4,5.7v4.8c0,1.2-1,1.6-2,1.6s-2-0.4-2-1.6v-4.8c0.1-3,0.4-6,1-9c0.2-1.3,1.5-2,2.9-2c1.3,0,2.4,0.7,3,1.8
		l2.6,6.2l2.6-6.2c0.6-1.1,1.7-1.8,3-1.8c1.5,0,2.7,0.6,2.9,2c0.6,3,0.9,6,1,9v4.8c0,1.2-1,1.6-2,1.6s-2-0.4-2-1.6v-4.8
		c0-1.9-0.1-3.8-0.4-5.7l-3.2,7.2c-0.3,0.7-1,1.1-1.8,1.1s-1.5-0.4-1.8-1.1H346.3z"
      />
    </g>
    <g>
	<path class="st2" d="M71.4,388.2h1.1v7.2c0,2.2-0.9,2.9-2.4,2.9c-0.3,0-0.5,0-0.7-0.1l0.1-1c0.1,0,0.3,0.1,0.6,0.1
		c0.8,0,1.3-0.3,1.3-1.9V388.2z"/>
	<path class="st2" d="M78.6,394.7c0,2.8-1.3,3.7-2.4,3.7c-1.3,0-2.3-1.2-2.3-3.7c0-2.6,1.2-3.7,2.4-3.7
		C77.5,390.9,78.6,392.1,78.6,394.7z M75,394.7c0,1.2,0.2,2.8,1.2,2.8c1,0,1.2-1.7,1.2-2.8c0-1.1-0.2-2.8-1.2-2.8
		C75.2,391.9,75,393.6,75,394.7z"/>
	<path class="st2" d="M81,394.4L81,394.4c0.2-0.3,0.3-0.6,0.4-0.8l1.4-2.5h1.2l-1.9,3l2.1,4.2H83l-1.6-3.4l-0.4,0.7v2.7h-1.1V388H81
		V394.4z"/>
	<path class="st2" d="M88,398.3l-0.1-0.8h0c-0.3,0.5-0.8,0.9-1.4,0.9c-1,0-1.7-0.8-1.7-2.1c0-1.8,1.4-2.6,3.1-2.6v-0.2
		c0-1-0.2-1.6-1.1-1.6c-0.4,0-0.8,0.1-1.2,0.4l-0.3-0.8c0.3-0.2,1-0.5,1.7-0.5c1.4,0,2.1,1,2.1,2.6v3.1c0,0.5,0,1.2,0.1,1.6H88z
		 M87.9,394.5c-0.6,0-2,0.1-2,1.7c0,0.9,0.5,1.3,0.9,1.3c0.5,0,0.9-0.4,1.1-1.1c0-0.1,0-0.3,0-0.4V394.5z"/>
	<path class="st2" d="M91.8,389.2c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7
		C91.6,388.5,91.8,388.8,91.8,389.2z M90.6,398.3v-7.2h1.1v7.2H90.6z"/>
	<path class="st2" d="M93.3,392.9c0-0.8,0-1.3-0.1-1.9h1l0.1,0.9h0c0.3-0.6,0.9-1,1.7-1c1,0,1.7,0.7,1.7,2.5v4.8h-1.1v-4.6
		c0-0.8-0.2-1.7-1-1.7c-0.5,0-0.9,0.4-1.1,1.2c0,0.2,0,0.4,0,0.6v4.5h-1.1V392.9z"/>
	<path class="st2" d="M100.2,394.8c0,2.2,0.9,2.7,1.7,2.7c0.5,0,0.9-0.1,1.2-0.3l0.2,0.8c-0.4,0.2-1,0.3-1.6,0.3
		c-1.7,0-2.6-1.4-2.6-3.6c0-2.3,1-3.8,2.4-3.8c1.5,0,2.1,1.5,2.1,3.2c0,0.3,0,0.5,0,0.6H100.2z M102.4,393.9c0-1.4-0.5-2.1-1.1-2.1
		c-0.7,0-1.1,1.1-1.2,2.1H102.4z"/>
	<path class="st2" d="M104.8,392.9c0-0.8,0-1.3-0.1-1.9h1l0.1,0.9h0c0.3-0.6,0.9-1,1.7-1c1,0,1.7,0.7,1.7,2.5v4.8H108v-4.6
		c0-0.8-0.2-1.7-1-1.7c-0.5,0-0.9,0.4-1.1,1.2c0,0.2,0,0.4,0,0.6v4.5h-1.1V392.9z"/>
	<path class="st2" d="M113.1,393.3c0-1.1,0-1.7-0.1-2.2h1l0.1,0.9h0c0.4-0.8,1-1,1.5-1c1.2,0,2.1,1.3,2.1,3.7c0,2.7-1.1,3.7-2.2,3.7
		c-0.7,0-1.1-0.4-1.2-0.8h0v3.6h-1.1V393.3z M114.3,395.6c0,0.2,0,0.4,0,0.6c0.2,1,0.7,1.2,1.1,1.2c1,0,1.2-1.3,1.2-2.8
		c0-1.5-0.4-2.7-1.3-2.7c-0.5,0-1,0.7-1.1,1.3c0,0.2,0,0.4,0,0.5V395.6z"/>
	<path class="st2" d="M123.4,396.3c0,0.8,0,1.4,0.1,2h-1l-0.1-0.8h0c-0.2,0.4-0.8,0.9-1.5,0.9c-1.3,0-1.8-1-1.8-2.8v-4.6h1.1v4.4
		c0,1.1,0.2,2,1,2c0.7,0,1-0.6,1-1c0-0.2,0.1-0.4,0.1-0.7v-4.7h1.1V396.3z"/>
	<path class="st2" d="M125,392.9c0-0.6,0-1.3-0.1-1.9h1l0.1,1.1h0c0.2-0.7,0.8-1.3,1.5-1.3c0.1,0,0.2,0,0.2,0v1.2
		c-0.1,0-0.1,0-0.2,0c-0.7,0-1.3,0.7-1.4,1.5c0,0.2,0,0.4,0,0.7v4H125V392.9z"/>
	<path class="st2" d="M129.8,394.4L129.8,394.4c0.2-0.3,0.3-0.6,0.4-0.8l1.4-2.5h1.2l-1.9,3l2.1,4.2h-1.3l-1.6-3.4l-0.4,0.7v2.7
		h-1.1V388h1.1V394.4z"/>
	<path class="st2" d="M135.1,394.4L135.1,394.4c0.2-0.3,0.3-0.6,0.4-0.8l1.4-2.5h1.2l-1.9,3l2.1,4.2H137l-1.6-3.4l-0.4,0.7v2.7h-1.1
		V388h1.1V394.4z"/>
	<path class="st2" d="M140.4,389.2c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7
		C140.1,388.5,140.4,388.8,140.4,389.2z M139.1,398.3v-7.2h1.1v7.2H139.1z"/>
	<path class="st2" d="M144.2,392.9c0-0.8,0-1.3-0.1-1.9h1l0.1,0.9h0c0.3-0.6,0.9-1,1.6-1c0.7,0,1.2,0.3,1.5,1.1h0
		c0.2-0.4,0.4-0.6,0.7-0.8c0.3-0.2,0.6-0.3,1-0.3c1,0,1.7,0.7,1.7,2.5v4.8h-1.1v-4.5c0-0.9-0.1-1.8-1-1.8c-0.5,0-0.9,0.4-1.1,1.1
		c0,0.2,0,0.4,0,0.7v4.6h-1.1v-4.7c0-0.8-0.1-1.6-1-1.6c-0.5,0-0.9,0.4-1.1,1.2c0,0.2,0,0.4,0,0.6v4.6h-1.1V392.9z"/>
	<path class="st2" d="M157.7,396.3c0,0.8,0,1.4,0.1,2h-1l-0.1-0.8h0c-0.2,0.4-0.8,0.9-1.5,0.9c-1.3,0-1.8-1-1.8-2.8v-4.6h1.1v4.4
		c0,1.1,0.2,2,1,2c0.7,0,1-0.6,1-1c0-0.2,0.1-0.4,0.1-0.7v-4.7h1.1V396.3z"/>
	<path class="st2" d="M160.5,394.4L160.5,394.4c0.2-0.3,0.3-0.6,0.4-0.8l1.4-2.5h1.2l-1.9,3l2.1,4.2h-1.3l-1.6-3.4l-0.4,0.7v2.7
		h-1.1V388h1.1V394.4z"/>
	<path class="st2" d="M167.5,398.3l-0.1-0.8h0c-0.3,0.5-0.8,0.9-1.4,0.9c-1,0-1.7-0.8-1.7-2.1c0-1.8,1.4-2.6,3.1-2.6v-0.2
		c0-1-0.2-1.6-1.1-1.6c-0.4,0-0.8,0.1-1.2,0.4l-0.3-0.8c0.3-0.2,1-0.5,1.7-0.5c1.4,0,2.1,1,2.1,2.6v3.1c0,0.5,0,1.2,0.1,1.6H167.5z
		 M167.4,394.5c-0.6,0-2,0.1-2,1.7c0,0.9,0.5,1.3,0.9,1.3c0.5,0,0.9-0.4,1.1-1.1c0-0.1,0-0.3,0-0.4V394.5z"/>
	<path class="st2" d="M170.1,392.9c0-0.8,0-1.3-0.1-1.9h1l0.1,0.9h0c0.3-0.6,0.9-1,1.7-1c1,0,1.7,0.7,1.7,2.5v4.8h-1.1v-4.6
		c0-0.8-0.2-1.7-1-1.7c-0.5,0-0.9,0.4-1.1,1.2c0,0.2,0,0.4,0,0.6v4.5h-1.1V392.9z"/>
	<path class="st2" d="M178.9,398.3l-0.1-0.8h0c-0.3,0.5-0.8,0.9-1.4,0.9c-1,0-1.7-0.8-1.7-2.1c0-1.8,1.4-2.6,3.1-2.6v-0.2
		c0-1-0.2-1.6-1.1-1.6c-0.4,0-0.8,0.1-1.2,0.4l-0.3-0.8c0.3-0.2,1-0.5,1.7-0.5c1.4,0,2.1,1,2.1,2.6v3.1c0,0.5,0,1.2,0.1,1.6H178.9z
		 M178.8,394.5c-0.6,0-2,0.1-2,1.7c0,0.9,0.5,1.3,0.9,1.3c0.5,0,0.9-0.4,1.1-1.1c0-0.1,0-0.3,0-0.4V394.5z"/>
	<path class="st2" d="M185.3,389.2v1.9h1.4v0.9h-1.4v4.3c0,0.9,0.3,1.2,0.8,1.2c0.2,0,0.3,0,0.5,0l0,0.9c-0.2,0.1-0.5,0.1-0.8,0.1
		c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.3-0.4-0.8-0.4-1.8v-4.2h-0.9v-0.9h0.9v-1.6L185.3,389.2z"/>
	<path class="st2" d="M192,396.3c0,0.8,0,1.4,0.1,2h-1l-0.1-0.8h0c-0.2,0.4-0.8,0.9-1.5,0.9c-1.3,0-1.8-1-1.8-2.8v-4.6h1.1v4.4
		c0,1.1,0.2,2,1,2c0.7,0,1-0.6,1-1c0-0.2,0.1-0.4,0.1-0.7v-4.7h1.1V396.3z"/>
	<path class="st2" d="M194.7,394.4L194.7,394.4c0.2-0.3,0.3-0.6,0.4-0.8l1.4-2.5h1.2l-1.9,3l2.1,4.2h-1.3l-1.6-3.4l-0.4,0.7v2.7
		h-1.1V388h1.1V394.4z"/>
	<path class="st2" d="M199.4,394.8c0,2.2,0.9,2.7,1.7,2.7c0.5,0,0.9-0.1,1.2-0.3l0.2,0.8c-0.4,0.2-1,0.3-1.6,0.3
		c-1.7,0-2.6-1.4-2.6-3.6c0-2.3,1-3.8,2.4-3.8c1.5,0,2.1,1.5,2.1,3.2c0,0.3,0,0.5,0,0.6H199.4z M201.7,393.9c0-1.4-0.5-2.1-1.1-2.1
		c-0.7,0-1.1,1.1-1.2,2.1H201.7z"/>
	<path class="st2" d="M204,392.9c0-0.8,0-1.3-0.1-1.9h1l0.1,0.9h0c0.3-0.6,0.9-1,1.6-1c0.7,0,1.2,0.3,1.5,1.1h0
		c0.2-0.4,0.4-0.6,0.7-0.8c0.3-0.2,0.6-0.3,1-0.3c1,0,1.7,0.7,1.7,2.5v4.8h-1.1v-4.5c0-0.9-0.1-1.8-1-1.8c-0.5,0-0.9,0.4-1.1,1.1
		c0,0.2,0,0.4,0,0.7v4.6h-1.1v-4.7c0-0.8-0.1-1.6-1-1.6c-0.5,0-0.9,0.4-1.1,1.2c0,0.2,0,0.4,0,0.6v4.6H204V392.9z"/>
	<path class="st2" d="M216.1,398.3l-0.1-0.8h0c-0.3,0.5-0.8,0.9-1.4,0.9c-1,0-1.7-0.8-1.7-2.1c0-1.8,1.4-2.6,3.1-2.6v-0.2
		c0-1-0.2-1.6-1.1-1.6c-0.4,0-0.8,0.1-1.2,0.4l-0.3-0.8c0.3-0.2,1-0.5,1.7-0.5c1.4,0,2.1,1,2.1,2.6v3.1c0,0.5,0,1.2,0.1,1.6H216.1z
		 M215.9,394.5c-0.6,0-2,0.1-2,1.7c0,0.9,0.5,1.3,0.9,1.3c0.5,0,0.9-0.4,1.1-1.1c0-0.1,0-0.3,0-0.4V394.5z"/>
	<path class="st2" d="M218.4,397.1c0.3,0.2,0.7,0.4,1.2,0.4c0.7,0,1.1-0.4,1.1-1c0-0.5-0.2-0.9-0.9-1.4c-0.9-0.6-1.4-1.2-1.4-2.1
		c0-1.2,0.9-2,2-2c0.6,0,1,0.2,1.3,0.4l-0.3,0.9c-0.3-0.2-0.6-0.3-1-0.3c-0.6,0-0.9,0.4-0.9,0.9c0,0.5,0.2,0.8,0.9,1.3
		c0.8,0.5,1.5,1.2,1.5,2.2c0,1.4-1,2.1-2.2,2.1c-0.5,0-1.1-0.2-1.4-0.4L218.4,397.1z"/>
	<path class="st2" d="M222.7,397.1c0.3,0.2,0.7,0.4,1.2,0.4c0.7,0,1.1-0.4,1.1-1c0-0.5-0.2-0.9-0.9-1.4c-0.9-0.6-1.4-1.2-1.4-2.1
		c0-1.2,0.9-2,2-2c0.6,0,1,0.2,1.3,0.4l-0.3,0.9c-0.3-0.2-0.6-0.3-1-0.3c-0.6,0-0.9,0.4-0.9,0.9c0,0.5,0.2,0.8,0.9,1.3
		c0.8,0.5,1.5,1.2,1.5,2.2c0,1.4-1,2.1-2.2,2.1c-0.5,0-1.1-0.2-1.4-0.4L222.7,397.1z"/>
	<path class="st2" d="M230.1,398.3l-0.1-0.8h0c-0.3,0.5-0.8,0.9-1.4,0.9c-1,0-1.7-0.8-1.7-2.1c0-1.8,1.4-2.6,3.1-2.6v-0.2
		c0-1-0.2-1.6-1.1-1.6c-0.4,0-0.8,0.1-1.2,0.4l-0.3-0.8c0.3-0.2,1-0.5,1.7-0.5c1.4,0,2.1,1,2.1,2.6v3.1c0,0.5,0,1.2,0.1,1.6H230.1z
		 M229.9,394.5c-0.6,0-2,0.1-2,1.7c0,0.9,0.5,1.3,0.9,1.3c0.5,0,0.9-0.4,1.1-1.1c0-0.1,0-0.3,0-0.4V394.5z"/>
	<path class="st2" d="M241.1,393.9c-0.1-1.3-0.1-2.8-0.1-3.9H241c-0.2,1.1-0.5,2.3-0.8,3.5l-1.2,4.7h-0.7l-1.2-4.5
		c-0.3-1.3-0.6-2.5-0.8-3.7h0c0,1.2-0.1,2.6-0.1,4l-0.2,4.2h-1l0.6-10h1.2l1.2,4.6c0.3,1.3,0.6,2.3,0.8,3.5h0
		c0.2-1.1,0.4-2.1,0.7-3.5l1.1-4.6h1.3l0.6,10h-1.1L241.1,393.9z"/>
	<path class="st2" d="M244,388.2h1.1v9.1h2.8v1h-4V388.2z"/>
	<path class="st2" d="M249.1,388.2h1.1v9.1h2.8v1h-4V388.2z"/>
	<path class="st2" d="M254.1,392.1c0-0.5,0.3-0.8,0.7-0.8c0.5,0,0.7,0.3,0.7,0.8c0,0.5-0.3,0.8-0.7,0.8
		C254.4,392.9,254.1,392.6,254.1,392.1z M254.1,397.5c0-0.5,0.3-0.8,0.7-0.8c0.5,0,0.7,0.3,0.7,0.8c0,0.5-0.3,0.8-0.7,0.8
		C254.4,398.4,254.1,398,254.1,397.5z"/>
	<path class="st2" d="M257,392.9c0-0.8,0-1.3-0.1-1.9h1l0.1,0.9h0c0.3-0.6,0.9-1,1.7-1c1,0,1.7,0.7,1.7,2.5v4.8h-1.1v-4.6
		c0-0.8-0.2-1.7-1-1.7c-0.5,0-0.9,0.4-1.1,1.2c0,0.2,0,0.4,0,0.6v4.5H257V392.9z"/>
	<path class="st2" d="M85.9,405.8H87v10.3h-1.1V405.8z"/>
	<path class="st2" d="M91.6,416.1l-0.1-0.8h0c-0.3,0.5-0.8,0.9-1.4,0.9c-1,0-1.7-0.8-1.7-2.1c0-1.8,1.4-2.6,3.1-2.6v-0.2
		c0-1-0.2-1.6-1.1-1.6c-0.4,0-0.8,0.1-1.2,0.4l-0.3-0.8c0.3-0.2,1-0.5,1.7-0.5c1.4,0,2.1,1,2.1,2.6v3.1c0,0.5,0,1.2,0.1,1.6H91.6z
		 M91.4,412.4c-0.6,0-2,0.1-2,1.7c0,0.9,0.5,1.3,0.9,1.3c0.5,0,0.9-0.4,1.1-1.1c0-0.1,0-0.3,0-0.4V412.4z"/>
	<path class="st2" d="M93.9,414.9c0.3,0.2,0.7,0.4,1.2,0.4c0.7,0,1.1-0.4,1.1-1c0-0.5-0.2-0.9-0.9-1.4c-0.9-0.6-1.4-1.2-1.4-2.1
		c0-1.2,0.9-2,2-2c0.6,0,1,0.2,1.3,0.4l-0.3,0.9c-0.3-0.2-0.6-0.3-1-0.3c-0.6,0-0.9,0.4-0.9,0.9c0,0.5,0.2,0.8,0.9,1.3
		c0.8,0.5,1.5,1.2,1.5,2.2c0,1.4-1,2.1-2.2,2.1c-0.5,0-1.1-0.2-1.4-0.4L93.9,414.9z"/>
	<path class="st2" d="M99.8,407v1.9h1.4v0.9h-1.4v4.3c0,0.9,0.3,1.2,0.8,1.2c0.2,0,0.3,0,0.5,0l0,0.9c-0.2,0.1-0.5,0.1-0.8,0.1
		c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.3-0.4-0.8-0.4-1.8v-4.2h-0.9v-0.9h0.9v-1.6L99.8,407z"/>
	<path class="st2" d="M102.9,412.6c0,2.2,0.9,2.7,1.7,2.7c0.5,0,0.9-0.1,1.2-0.3l0.2,0.8c-0.4,0.2-1,0.3-1.6,0.3
		c-1.7,0-2.6-1.4-2.6-3.6c0-2.3,1-3.8,2.4-3.8c1.5,0,2.1,1.5,2.1,3.2c0,0.3,0,0.5,0,0.6H102.9z M105.2,411.8c0-1.4-0.5-2.1-1.1-2.1
		c-0.7,0-1.1,1.1-1.2,2.1H105.2z"/>
	<path class="st2" d="M107.5,410.8c0-0.8,0-1.3-0.1-1.9h1l0.1,0.9h0c0.3-0.6,0.9-1,1.7-1c1,0,1.7,0.7,1.7,2.5v4.8h-1.1v-4.6
		c0-0.8-0.2-1.7-1-1.7c-0.5,0-0.9,0.4-1.1,1.2c0,0.2,0,0.4,0,0.6v4.5h-1.1V410.8z"/>
	<path class="st2" d="M117,412.3L117,412.3c0.2-0.3,0.3-0.6,0.4-0.8l1.4-2.5h1.2l-1.9,3l2.1,4.2h-1.3l-1.6-3.4l-0.4,0.7v2.7h-1.1
		v-10.3h1.1V412.3z"/>
	<path class="st2" d="M124,416.1l-0.1-0.8h0c-0.3,0.5-0.8,0.9-1.4,0.9c-1,0-1.7-0.8-1.7-2.1c0-1.8,1.4-2.6,3.1-2.6v-0.2
		c0-1-0.2-1.6-1.1-1.6c-0.4,0-0.8,0.1-1.2,0.4l-0.3-0.8c0.3-0.2,1-0.5,1.7-0.5c1.4,0,2.1,1,2.1,2.6v3.1c0,0.5,0,1.2,0.1,1.6H124z
		 M123.9,412.4c-0.6,0-2,0.1-2,1.7c0,0.9,0.5,1.3,0.9,1.3c0.5,0,0.9-0.4,1.1-1.1c0-0.1,0-0.3,0-0.4V412.4z"/>
	<path class="st2" d="M127,408.9l0.9,4c0.1,0.5,0.2,1.1,0.3,1.8h0c0.1-0.6,0.2-1.2,0.3-1.8l0.9-4h1.1l-1.9,7.2h-1l-1.9-7.2H127z"/>
	<path class="st2" d="M132.2,412.6c0,2.2,0.9,2.7,1.7,2.7c0.5,0,0.9-0.1,1.2-0.3l0.2,0.8c-0.4,0.2-1,0.3-1.6,0.3
		c-1.7,0-2.6-1.4-2.6-3.6c0-2.3,1-3.8,2.4-3.8c1.5,0,2.1,1.5,2.1,3.2c0,0.3,0,0.5,0,0.6H132.2z M134.5,411.8c0-1.4-0.5-2.1-1.1-2.1
		c-0.7,0-1.1,1.1-1.2,2.1H134.5z"/>
	<path class="st2" d="M136.8,410.8c0-0.6,0-1.3-0.1-1.9h1l0.1,1.1h0c0.2-0.7,0.8-1.3,1.5-1.3c0.1,0,0.2,0,0.2,0v1.2
		c-0.1,0-0.1,0-0.2,0c-0.7,0-1.3,0.7-1.4,1.5c0,0.2,0,0.4,0,0.7v4h-1.1V410.8z"/>
	<path class="st2" d="M141.7,407c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7C141.5,406.3,141.7,406.6,141.7,407z
		 M140.5,416.1v-7.2h1.1v7.2H140.5z"/>
	<path class="st2" d="M144.6,407v1.9h1.4v0.9h-1.4v4.3c0,0.9,0.3,1.2,0.8,1.2c0.2,0,0.3,0,0.5,0l0,0.9c-0.2,0.1-0.5,0.1-0.8,0.1
		c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.3-0.4-0.8-0.4-1.8v-4.2h-0.9v-0.9h0.9v-1.6L144.6,407z"/>
	<path class="st2" d="M149.9,416.1l-0.1-0.8h0c-0.3,0.5-0.8,0.9-1.4,0.9c-1,0-1.7-0.8-1.7-2.1c0-1.8,1.4-2.6,3.1-2.6v-0.2
		c0-1-0.2-1.6-1.1-1.6c-0.4,0-0.8,0.1-1.2,0.4l-0.3-0.8c0.3-0.2,1-0.5,1.7-0.5c1.4,0,2.1,1,2.1,2.6v3.1c0,0.5,0,1.2,0.1,1.6H149.9z
		 M149.7,412.4c-0.6,0-2,0.1-2,1.7c0,0.9,0.5,1.3,0.9,1.3c0.5,0,0.9-0.4,1.1-1.1c0-0.1,0-0.3,0-0.4V412.4z"/>
	<path class="st2" d="M153.7,407c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7C153.4,406.3,153.7,406.6,153.7,407z
		 M152.4,416.1v-7.2h1.1v7.2H152.4z"/>
	<path class="st2" d="M156.6,407v1.9h1.4v0.9h-1.4v4.3c0,0.9,0.3,1.2,0.8,1.2c0.2,0,0.3,0,0.5,0l0,0.9c-0.2,0.1-0.5,0.1-0.8,0.1
		c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.3-0.4-0.8-0.4-1.8v-4.2h-0.9v-0.9h0.9v-1.6L156.6,407z"/>
	<path class="st2" d="M163.3,412.5c0,2.8-1.3,3.7-2.4,3.7c-1.3,0-2.3-1.2-2.3-3.7c0-2.6,1.2-3.7,2.4-3.7
		C162.2,408.8,163.3,410,163.3,412.5z M159.7,412.5c0,1.2,0.2,2.8,1.2,2.8c1,0,1.2-1.7,1.2-2.8c0-1.1-0.2-2.8-1.2-2.8
		C159.9,409.7,159.7,411.4,159.7,412.5z"/>
	<path class="st2" d="M163.3,418.2c0.3-0.1,0.7-0.2,1-0.6c0.3-0.4,0.3-0.9,0.3-2.3v-6.4h1.1v6.7c0,1.5-0.2,2.2-0.7,2.8
		c-0.4,0.4-1.1,0.7-1.6,0.7L163.3,418.2z M165.9,407c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7
		S165.9,406.6,165.9,407z"/>
	<path class="st2" d="M170.2,416.1l-0.1-0.8h0c-0.3,0.5-0.8,0.9-1.4,0.9c-1,0-1.7-0.8-1.7-2.1c0-1.8,1.4-2.6,3.1-2.6v-0.2
		c0-1-0.2-1.6-1.1-1.6c-0.4,0-0.8,0.1-1.2,0.4l-0.3-0.8c0.3-0.2,1-0.5,1.7-0.5c1.4,0,2.1,1,2.1,2.6v3.1c0,0.5,0,1.2,0.1,1.6H170.2z
		 M170,412.4c-0.6,0-2,0.1-2,1.7c0,0.9,0.5,1.3,0.9,1.3c0.5,0,0.9-0.4,1.1-1.1c0-0.1,0-0.3,0-0.4V412.4z"/>
	<path class="st2" d="M175.9,412.6c0,2.2,0.9,2.7,1.7,2.7c0.5,0,0.9-0.1,1.2-0.3l0.2,0.8c-0.4,0.2-1,0.3-1.6,0.3
		c-1.7,0-2.6-1.4-2.6-3.6c0-2.3,1-3.8,2.4-3.8c1.5,0,2.1,1.5,2.1,3.2c0,0.3,0,0.5,0,0.6H175.9z M178.2,411.8c0-1.4-0.5-2.1-1.1-2.1
		c-0.7,0-1.1,1.1-1.2,2.1H178.2z"/>
	<path class="st2" d="M184.8,405.8v8.6c0,0.5,0,1.3,0.1,1.7h-1l-0.1-0.9h0c-0.2,0.5-0.7,1-1.5,1c-1.2,0-2.1-1.3-2.1-3.7
		c0-2.6,1.1-3.8,2.2-3.8c0.5,0,1,0.3,1.3,0.9h0v-3.8H184.8z M183.7,411.6c0-0.1,0-0.3,0-0.5c-0.1-0.7-0.5-1.4-1.1-1.4
		c-1,0-1.3,1.3-1.3,2.8c0,1.5,0.4,2.7,1.2,2.7c0.4,0,0.8-0.2,1.1-1.2c0-0.1,0-0.3,0-0.5V411.6z"/>
	<path class="st2" d="M187.7,407c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7C187.4,406.3,187.7,406.6,187.7,407z
		 M186.4,416.1v-7.2h1.1v7.2H186.4z"/>
	<path class="st2" d="M189,414.9c0.3,0.2,0.7,0.4,1.2,0.4c0.7,0,1.1-0.4,1.1-1c0-0.5-0.2-0.9-0.9-1.4c-0.9-0.6-1.4-1.2-1.4-2.1
		c0-1.2,0.9-2,2-2c0.6,0,1,0.2,1.3,0.4l-0.3,0.9c-0.3-0.2-0.6-0.3-1-0.3c-0.6,0-0.9,0.4-0.9,0.9c0,0.5,0.2,0.8,0.9,1.3
		c0.8,0.5,1.5,1.2,1.5,2.2c0,1.4-1,2.1-2.2,2.1c-0.5,0-1.1-0.2-1.4-0.4L189,414.9z"/>
	<path class="st2" d="M194.9,407v1.9h1.4v0.9h-1.4v4.3c0,0.9,0.3,1.2,0.8,1.2c0.2,0,0.3,0,0.5,0l0,0.9c-0.2,0.1-0.5,0.1-0.8,0.1
		c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.3-0.4-0.8-0.4-1.8v-4.2h-0.9v-0.9h0.9v-1.6L194.9,407z"/>
	<path class="st2" d="M200.1,416.1l-0.1-0.8h0c-0.3,0.5-0.8,0.9-1.4,0.9c-1,0-1.7-0.8-1.7-2.1c0-1.8,1.4-2.6,3.1-2.6v-0.2
		c0-1-0.2-1.6-1.1-1.6c-0.4,0-0.8,0.1-1.2,0.4l-0.3-0.8c0.3-0.2,1-0.5,1.7-0.5c1.4,0,2.1,1,2.1,2.6v3.1c0,0.5,0,1.2,0.1,1.6H200.1z
		 M197.3,407c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.6,0.3,0.6,0.7c0,0.4-0.3,0.7-0.6,0.7C197.6,407.7,197.3,407.4,197.3,407z M199.9,412.4
		c-0.6,0-2,0.1-2,1.7c0,0.9,0.5,1.3,0.9,1.3c0.5,0,0.9-0.4,1.1-1.1c0-0.1,0-0.3,0-0.4V412.4z M199.7,407c0-0.4,0.3-0.7,0.7-0.7
		c0.4,0,0.6,0.3,0.6,0.7c0,0.4-0.3,0.7-0.6,0.7C199.9,407.7,199.7,407.4,199.7,407z"/>
	<path class="st2" d="M203.1,408.9l0.9,4c0.1,0.5,0.2,1.1,0.3,1.8h0c0.1-0.6,0.2-1.2,0.3-1.8l0.9-4h1.1l-1.9,7.2h-1l-1.9-7.2H203.1z
		"/>
	<path class="st2" d="M210.4,416.1l-0.1-0.8h0c-0.3,0.5-0.8,0.9-1.4,0.9c-1,0-1.7-0.8-1.7-2.1c0-1.8,1.4-2.6,3.1-2.6v-0.2
		c0-1-0.2-1.6-1.1-1.6c-0.4,0-0.8,0.1-1.2,0.4l-0.3-0.8c0.3-0.2,1-0.5,1.7-0.5c1.4,0,2.1,1,2.1,2.6v3.1c0,0.5,0,1.2,0.1,1.6H210.4z
		 M207.6,407c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.6,0.3,0.6,0.7c0,0.4-0.3,0.7-0.6,0.7C207.9,407.7,207.6,407.4,207.6,407z M210.2,412.4
		c-0.6,0-2,0.1-2,1.7c0,0.9,0.5,1.3,0.9,1.3c0.5,0,0.9-0.4,1.1-1.1c0-0.1,0-0.3,0-0.4V412.4z M209.9,407c0-0.4,0.3-0.7,0.7-0.7
		c0.4,0,0.6,0.3,0.6,0.7c0,0.4-0.3,0.7-0.6,0.7C210.2,407.7,209.9,407.4,209.9,407z"/>
	<path class="st2" d="M215.8,416.1l-0.1-0.8h0c-0.3,0.5-0.8,0.9-1.4,0.9c-1,0-1.7-0.8-1.7-2.1c0-1.8,1.4-2.6,3.1-2.6v-0.2
		c0-1-0.2-1.6-1.1-1.6c-0.4,0-0.8,0.1-1.2,0.4l-0.3-0.8c0.3-0.2,1-0.5,1.7-0.5c1.4,0,2.1,1,2.1,2.6v3.1c0,0.5,0,1.2,0.1,1.6H215.8z
		 M213,407c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.6,0.3,0.6,0.7c0,0.4-0.3,0.7-0.6,0.7C213.3,407.7,213,407.4,213,407z M215.6,412.4
		c-0.6,0-2,0.1-2,1.7c0,0.9,0.5,1.3,0.9,1.3c0.5,0,0.9-0.4,1.1-1.1c0-0.1,0-0.3,0-0.4V412.4z M215.4,407c0-0.4,0.3-0.7,0.7-0.7
		c0.4,0,0.6,0.3,0.6,0.7c0,0.4-0.3,0.7-0.6,0.7C215.6,407.7,215.4,407.4,215.4,407z"/>
	<path class="st2" d="M222.1,407v1.9h1.4v0.9h-1.4v4.3c0,0.9,0.3,1.2,0.8,1.2c0.2,0,0.3,0,0.5,0l0,0.9c-0.2,0.1-0.5,0.1-0.8,0.1
		c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.3-0.4-0.8-0.4-1.8v-4.2h-0.9v-0.9h0.9v-1.6L222.1,407z"/>
	<path class="st2" d="M225.2,408.9l1,4.1c0.1,0.6,0.2,1,0.3,1.5h0c0.1-0.4,0.2-1,0.3-1.5l0.8-4.2h1.1l-1.2,4.6
		c-0.4,1.8-0.9,3.6-1.8,4.6c-0.5,0.6-1,1-1.3,1.1l-0.4-1c0.3-0.2,0.7-0.5,1-0.9c0.3-0.4,0.5-0.9,0.7-1.2c0.1-0.2,0.1-0.3,0.1-0.4
		c0-0.1,0-0.2,0-0.3l-1.8-6.6H225.2z"/>
	<path class="st2" d="M234,412.5c0,2.8-1.3,3.7-2.4,3.7c-1.3,0-2.3-1.2-2.3-3.7c0-2.6,1.2-3.7,2.4-3.7C233,408.8,234,410,234,412.5z
		 M229.8,407c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.6,0.3,0.6,0.7c0,0.4-0.3,0.7-0.6,0.7C230.1,407.7,229.8,407.4,229.8,407z M230.4,412.5
		c0,1.2,0.2,2.8,1.2,2.8c1,0,1.2-1.7,1.2-2.8c0-1.1-0.2-2.8-1.2-2.8C230.6,409.7,230.4,411.4,230.4,412.5z M232.1,407
		c0-0.4,0.3-0.7,0.7-0.7s0.6,0.3,0.6,0.7c0,0.4-0.3,0.7-0.6,0.7C232.4,407.7,232.1,407.4,232.1,407z"/>
	<path class="st2" d="M236.7,407v1.9h1.4v0.9h-1.4v4.3c0,0.9,0.3,1.2,0.8,1.2c0.2,0,0.3,0,0.5,0l0,0.9c-0.2,0.1-0.5,0.1-0.8,0.1
		c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.3-0.4-0.8-0.4-1.8v-4.2h-0.9v-0.9h0.9v-1.6L236.7,407z"/>
	<path class="st2" d="M241.9,416.1l-0.1-0.8h0c-0.3,0.5-0.8,0.9-1.4,0.9c-1,0-1.7-0.8-1.7-2.1c0-1.8,1.4-2.6,3.1-2.6v-0.2
		c0-1-0.2-1.6-1.1-1.6c-0.4,0-0.8,0.1-1.2,0.4l-0.3-0.8c0.3-0.2,1-0.5,1.7-0.5c1.4,0,2.1,1,2.1,2.6v3.1c0,0.5,0,1.2,0.1,1.6H241.9z
		 M239.2,407c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.6,0.3,0.6,0.7c0,0.4-0.3,0.7-0.6,0.7C239.5,407.7,239.2,407.4,239.2,407z M241.7,412.4
		c-0.6,0-2,0.1-2,1.7c0,0.9,0.5,1.3,0.9,1.3c0.5,0,0.9-0.4,1.1-1.1c0-0.1,0-0.3,0-0.4V412.4z M241.5,407c0-0.4,0.3-0.7,0.7-0.7
		c0.4,0,0.6,0.3,0.6,0.7c0,0.4-0.3,0.7-0.6,0.7C241.8,407.7,241.5,407.4,241.5,407z"/>
	<path class="st2" d="M244.4,415.4c0-0.5,0.3-0.9,0.8-0.9c0.5,0,0.8,0.3,0.8,0.9s-0.3,0.9-0.8,0.9
		C244.7,416.2,244.4,415.9,244.4,415.4z"/>
</g> 
    <g>
      <path
        style="fill: #1d1d1b"
        d="M133.5,294.6h1.1v9h2.8v1h-4L133.5,294.6z"
      />
      <path
        style="fill: #1d1d1b"
        d="M139.8,295.6c0.1,0.4-0.2,0.7-0.6,0.8c-0.4,0.1-0.7-0.2-0.8-0.6l0,0c0-0.1,0-0.1,0-0.2
		c0-0.4,0.2-0.7,0.6-0.7h0.1c0.4,0,0.7,0.3,0.7,0.6C139.8,295.5,139.8,295.5,139.8,295.6L139.8,295.6z M138.5,304.6v-7.2h1.1v7.2
		H138.5z"
      />
      <path
        style="fill: #1d1d1b"
        d="M141.2,299.3c0-0.9,0-1.3-0.1-1.9h1l0.1,0.9l0,0c0.3-0.6,1-1,1.6-1c1,0,1.7,0.7,1.7,2.5v4.8h-1.1
		V300c0-0.9-0.2-1.7-1-1.7c-0.5,0-0.9,0.4-1.1,1.2c0,0.2,0,0.4,0,0.6v4.5h-1.1L141.2,299.3z"
      />
      <path
        style="fill: #1d1d1b"
        d="M147.3,299.3c0-0.6,0-1.2-0.1-1.9h1l0.1,0.9l0,0c0.3-0.6,1-1,1.6-1c1,0,1.7,0.7,1.7,2.5v4.8h-1.1
		V300c0-0.9-0.2-1.7-1-1.7c-0.5,0-0.9,0.4-1.1,1.2c0,0.2,0,0.4,0,0.6v4.5h-1.1L147.3,299.3z"
      />
      <path
        style="fill: #1d1d1b"
        d="M157.6,302.7c0,0.7,0,1.3,0.1,2h-1l-0.1-0.8l0,0c-0.3,0.5-0.9,0.9-1.6,0.9c-1.3,0-1.8-1-1.8-2.8
		v-4.6h1.1v4.4c0,1.1,0.2,2,1,2c0.5,0,0.9-0.4,1-1c0.1-0.2,0.1-0.4,0.1-0.6v-4.7h1.1L157.6,302.7z"
      />
      <path
        style="fill: #1d1d1b"
        d="M159.2,299.3c0-0.9,0-1.3-0.1-1.9h1l0.1,0.9l0,0c0.3-0.6,1-1,1.6-1c1,0,1.7,0.7,1.7,2.5v4.8h-1.1
		V300c0-0.9-0.2-1.7-1-1.7c-0.5,0-0.9,0.4-1.1,1.2c0,0.2,0,0.4,0,0.6v4.5h-1.1L159.2,299.3z"
      />
      <path
        style="fill: #1d1d1b"
        d="M167.5,299.7c0-0.7,0-1.5-0.1-2.2h1l0.1,0.9l0,0c0.3-0.6,0.9-1,1.5-1c1.2,0,2.1,1.3,2.1,3.7
		c0,2.7-1.1,3.7-2.2,3.7c-0.5,0-1-0.3-1.2-0.8l0,0v3.6h-1.1L167.5,299.7z M168.6,302c0,0.2,0,0.4,0,0.6c0.2,1,0.7,1.2,1.1,1.2
		c0.9,0,1.2-1.3,1.2-2.8c0-1.4-0.4-2.7-1.3-2.7c-0.5,0-1,0.6-1.1,1.3c0,0.2,0,0.4,0,0.6L168.6,302z"
      />
      <path
        style="fill: #1d1d1b"
        d="M174.7,295.6c0.1,0.4-0.2,0.7-0.6,0.8c-0.4,0.1-0.7-0.2-0.8-0.6l0,0c0-0.1,0-0.1,0-0.2
		c0-0.4,0.2-0.7,0.6-0.7l0,0c0.4,0,0.7,0.3,0.7,0.6C174.6,295.5,174.6,295.5,174.7,295.6L174.7,295.6z M173.4,304.6v-7.2h1.1v7.2
		H173.4z"
      />
      <path
        style="fill: #1d1d1b"
        d="M177.4,295.6c0.1,0.4-0.2,0.7-0.6,0.8c-0.4,0.1-0.7-0.2-0.8-0.6l0,0c0-0.1,0-0.1,0-0.2
		c0-0.4,0.2-0.7,0.6-0.7h0.1c0.4,0,0.7,0.3,0.7,0.6C177.4,295.5,177.4,295.5,177.4,295.6L177.4,295.6z M176.1,304.6v-7.2h1.2v7.2
		H176.1z"
      />
      <path
        style="fill: #1d1d1b"
        d="M178.9,299.3c0-0.6,0-1.2-0.1-1.9h1l0.1,1.1l0,0c0.2-0.7,0.8-1.2,1.5-1.2h0.2v1.2h-0.3
		c-0.8,0.1-1.4,0.7-1.4,1.5c0,0.2,0,0.4,0,0.6v4h-1.1L178.9,299.3z"
      />
      <path
        style="fill: #1d1d1b"
        d="M182.4,303.4c0.3,0.2,0.7,0.4,1.1,0.4c0.6,0,1-0.4,1.1-0.9l0,0v-0.1c0-0.6-0.2-0.9-0.9-1.4
		c-0.9-0.6-1.4-1.2-1.4-2c0-1.1,0.8-2,1.9-2l0,0h0.1c0.5,0,0.9,0.1,1.3,0.4l-0.3,0.9c-0.3-0.2-0.6-0.3-1-0.3c-0.5,0-0.9,0.3-0.9,0.8
		l0,0v0.1c0,0.5,0.2,0.8,0.9,1.2c0.8,0.4,1.4,1.2,1.5,2.2c0.1,1.1-0.7,2.1-1.8,2.1l0,0h-0.3c-0.5,0-1-0.1-1.4-0.4L182.4,303.4z"
      />
      <path
        style="fill: #1d1d1b"
        d="M188.1,295.6c0.1,0.4-0.2,0.7-0.6,0.8c-0.4,0.1-0.7-0.2-0.8-0.6l0,0c0-0.1,0-0.1,0-0.2
		c0-0.4,0.2-0.7,0.6-0.7h0.1c0.4,0,0.7,0.3,0.7,0.6C188.1,295.5,188.1,295.5,188.1,295.6L188.1,295.6z M186.9,304.6v-7.2h1.1v7.2
		H186.9z"
      />
      <path
        style="fill: #1d1d1b"
        d="M189.5,298.4c0-0.4,0.3-0.8,0.7-0.8l0,0l0,0c0.4,0,0.7,0.3,0.8,0.8v0.1c0.1,0.4-0.2,0.8-0.6,0.8l0,0
		h-0.1C189.9,299.2,189.5,298.9,189.5,298.4C189.5,298.5,189.5,298.5,189.5,298.4L189.5,298.4z M189.5,303.9c0-0.4,0.3-0.8,0.7-0.8
		l0,0l0,0c0.4,0,0.7,0.3,0.8,0.8v0.1c0.1,0.4-0.2,0.8-0.6,0.8l0,0h-0.1C189.9,304.7,189.5,304.4,189.5,303.9L189.5,303.9
		L189.5,303.9z"
      />
    </g>
    <image
      style="overflow: visible; enable-background: new"
      width="59"
      height="60"
      xlink:href="../assets/img/heart.png"
      transform="matrix(0.52 0 0 0.52 277.96 92.73)"
    ></image>
    <foreignObject
      xmlns="http://www.w3.org/2000/svg"
      x="80"
      y="200"
      width="166"
      height="76"
    >
      <div
        xmlns="http://www.w3.org/1999/xhtml"
        style="height: 100%; display: table; width: 100%"
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style="
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            width: 100%;
            font-size: 30px;
            font-family: amatic_bold-webfont, Amatic;
            font-weight: 700;
            letter-spacing: 0.02em;
            text-anchor:middle;
            line-height:32px;
          "
          :style="this.getCssInline()"
        >
          {{this.getText()}}
        </div>
      </div>
    </foreignObject>

    <text
      transform="translate(165 325)"
      style="
        fill: #e84e0d;
        font-size: 17px;
        text-anchor: middle;
        font-size: 22px;
        font-family: amatic_bold-webfont, Amatic;
        font-weight: 700;
        letter-spacing: 0.02em;
        line-height:24px;        
      "
    >
      {{this.contact.name}} {{this.contact.age}}, {{this.contact.town}}
    </text>
    <image
      style="overflow: visible; enable-background: new"
      width="290"
      height="352"
      :href="this.bird.source_image_url"
      transform="matrix(0.7631 0 0 0.7631 282.3689 188.4196)"
    ></image>
    <image
      style="overflow: visible; enable-background: new"
      width="152"
      height="100"
      xlink:href="../assets/img/arla-logo.png"
      transform="matrix(0.49 0 0 0.49 185.79 76.77)"
    ></image>
    <rect x="63.6" y="330.3" style="fill: #e84e0d" width="184.2" height="1.8" />
  </svg>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SvgContainer",
  components: {},
  data() {
    return {
      bird: {},
      contact: {},
    };
  },
  mounted() {
    this.bird = this.getBird();
    this.contact = this.getContact();
  },
  methods: {
    ...mapGetters({
      getBird: "getBird",
      getContact: "getContact",
      getMessages: 'getMessages'
    }),
    getText() {
      if(this.getMessages().find(el => el.id == this.bird.message)) return this.getMessages().find(el => el.id == this.bird.message).text
      return ''
    },
    getCssInline() {
      let css = ''
      let messageObj = this.getMessages().find(el => el.id == this.bird.message)
      if(messageObj) {
        if(messageObj.conf['font-size']) css += `font-size:${messageObj.conf['font-size']};`
        if(messageObj.conf['line-height']) css += `line-height:${messageObj.conf['line-height']};`
      }
      return css
    }
  },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
</style>
