<template>
  <ArlaNavbar />

  <section class="hero hero-wrapper">
    <SpinnerModal :spinning="spinning"></SpinnerModal>
    <div class="hero-body">
      <div class="container">
        <div class="columns is-flex-direction-column">
          <div class="column">
            <figure class="image">
              <img src="../assets/img/Group25@3x.png" />
            </figure>
          </div>
          <div class="column">
            <h1
              class="has-text-centered font-arla-strong-regular font-43 color-dg lh-49"
            >
              Piirrä oma arla Luonto+ lintusi
            </h1>
          </div>
          <div class="column">
            <p class="has-text-centered arlainterface-regular font-16">
              Etsimme jälleen uusia siivekkäitä kavereita. <b>Piirrä meille omasi! Tällä kertaa voit myös tehdä videon lintusi seikkailuista.</b>
            </p>
            <p class="has-text-centered arlainterface-regular font-16">
              Uudet lintukaverit muuttavat Arla Luonto+ jogurttikippojen kansiin, ja jokainen kippo on mukana hyväntekeväisyyskeräyksessä tukemassa MLL:n tekemää lasten kaveritaitotyötä.
            </p>
          </div>
          <div class="column has-text-centered">
            <button class="button arla-button" @click="goToDraw()">
              Tästä piirtämään
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="hero-body video-gallery-wrapper banana-bg">
      <div class="container video-gallery-wrapper banana-bg is-clipped">
        <div class="columns">
          <div class="column">
            <h1 class="has-text-centered font-arla-strong-regular gallery-title color-dg">
              Video esimerkkejä
            </h1>
          </div>
        </div>
				<!-- Start Carousel -->
				<div id="carousel-demo" class="carousel">
					<div class="video-item item-1">
            <figure class="image" @click="$refs.videoModal.showModal(videos[0].animation_url)" v-if="videos[0]">
              <img :src="videos[0].thumbnails" />
            </figure>
					</div>
					<div class="video-item item-2">
            <figure class="image" @click="$refs.videoModal.showModal(videos[1].animation_url)" v-if="videos[1]">
              <img :src="videos[1].thumbnails" />
            </figure>
					</div>
					<div class="video-item item-3">
            <figure class="image" @click="$refs.videoModal.showModal(videos[2].animation_url)" v-if="videos[2]">
              <img :src="videos[2].thumbnails" />
            </figure>
					</div>
					<div class="video-item item-4">
            <figure class="image" @click="$refs.videoModal.showModal(videos[3].animation_url)" v-if="videos[3]">
              <img :src="videos[3].thumbnails" />
            </figure>
					</div>
					<div class="video-item item-5">
            <figure class="image" @click="$refs.videoModal.showModal(videos[4].animation_url)" v-if="videos[4]">
              <img :src="videos[4].thumbnails" />
            </figure>
					</div>                    
				</div>
				<!-- End Carousel -->        
      </div>
    </div>    

    <div class="hero-body gallery-wrapper">
      <div class="container gallery-wrapper">
        <div class="columns is-flex-direction-column">
          <div class="column">
            <h1
              class="has-text-centered font-arla-strong-regular gallery-title color-dg"
            >
              LINTUGALLERIA
            </h1>
          </div>
        </div>
        <div class="columns is-mobile is-multiline">
          <div class="column is-6 is-clickable" v-if="birds[0]">
            <figure class="image gallery-image" @click="goToShare(birds[0].uuid)">
              <img :src="birds[0].thumbnails_url" />
              <div v-if="birds[0].video_url" class="video-wrapper">
                <i class="fa-solid fa-video" style="color: #ffffff;"></i>
              </div>
            </figure>
          </div>
          <div class="column is-6 is-clickable" v-if="birds[1]">
            <figure class="image gallery-image" @click="goToShare(birds[1].uuid)">
              <img :src="birds[1].thumbnails_url" />
              <div v-if="birds[1].video_url" class="video-wrapper">
                <i class="fa-solid fa-video" style="color: #ffffff;"></i>
              </div>              
            </figure>
          </div>
          <div class="column is-6 is-clickable" v-if="birds[2]">
            <figure class="image gallery-image" @click="goToShare(birds[2].uuid)">
              <img :src="birds[2].thumbnails_url" />
              <div v-if="birds[2].video_url" class="video-wrapper">
                <i class="fa-solid fa-video" style="color: #ffffff;"></i>
              </div>
            </figure>
          </div>
          <div class="column is-6 is-clickable" v-if="birds[3]">
            <figure class="image gallery-image" @click="goToShare(birds[3].uuid)">
              <img :src="birds[3].thumbnails_url" />
              <div v-if="birds[3].video_url" class="video-wrapper">
                <i class="fa-solid fa-video" style="color: #ffffff;"></i>
              </div>
            </figure>
          </div>
          <div class="column is-12 has-text-centered">
            <button class="button arla-button" @click="goToGallery()">
              Näytä kaikki
            </button>
          </div>
        </div>
      </div>
    </div>

  </section>
  <VideoModal ref="videoModal"/>
  <BananaComponent />

  <ArlaFooter />
</template>

<script>
import ArlaNavbar from "../components/ArlaNavbar";
import ArlaFooter from "../components/ArlaFooter";
import BananaComponent from "../components/BananaComponent";
import SpinnerModal from "../components/SpinningModal";
import VideoModal from "../components/VideoModal";
import bulmaCarousel from 'bulma-carousel/dist/js/bulma-carousel.min.js';
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "LandingPage",
  components: {
    ArlaNavbar,
    ArlaFooter,
    BananaComponent,
    SpinnerModal,
    VideoModal,
  },
  data() {
    return {
      spinning: false,
      birds: [],
      videos: [],
    };
  },
  methods: {
    ...mapActions({
      getAuthenticate: "getAuthenticate",
      loadFirstItemsGallery: "loadFirstItemsGallery",
      loadFirstVideosGallery: "loadFirstVideosGallery",
    }),
    ...mapGetters({
      getGalleryBirds: "getGalleryBirds",
      getGalleryVideos: "getGalleryVideos"
    }),
    ...mapMutations({
      partialReset: "partialReset",
    }),
    goToDraw() {
      this.getAuthenticate().then(() => {
        this.$router.push({ path: `/drawing` });
      });
    },
    goToShare(uuid) {
      this.$router.push({ path: `/share/${uuid}/` });
    },
    goToGallery() {
      this.$router.push({ path: `/gallery` });
    },
  },
  mounted() {
    let self = this;
    this.partialReset();
    this.loadFirstItemsGallery().then(() => {
      self.birds = self.getGalleryBirds();
    });
    this.loadFirstVideosGallery().then(() => {
      self.videos = self.getGalleryVideos()
    })
    // Initialize all elements with carousel class.
    const carousels = bulmaCarousel.attach('#carousel-demo', {
      initialSlide: 0,
      slidesToScroll: 1,
      slidesToShow: 3,
      navigation: true,
      navigationKeys: true,
      navigationSwipe: true,
      pagination: false,
      loop: false,
      infinite: false,
      effect: "translate", // translate|fade
      duration: 300,
      timing: "ease",
      autoplay: false,
      autoplaySpeed: 3000,
      pauseOnHover: true,
      breakpoints: [{ changePoint: 480, slidesToShow: 1, slidesToScroll: 1 }, { changePoint: 640, slidesToShow: 2, slidesToScroll: 2 }, { changePoint: 768, slidesToShow: 3, slidesToScroll: 3 } ]
    });    
  },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
@import "video.js/dist/video-js.css";
.hero-wrapper {
  flex: 1 1 auto;
}
.gallery-wrapper {
  background-color: $light-green;
}

.gallery-title {
  font-size: 43px;
}

.video-item {
  padding: 0 10px;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
  transition: all 1s;
}

.gallery-image {
  position: relative;
}

.video-wrapper {
  position: absolute;
  width: 47px;
  height: 25px;
  border-radius: 20px;
  background-color: $dark-red;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 8%;
  left: 12%;
}

@media screen and (max-width: 768px) {
  .gallery-title {
    font-size: 30px;
  }
}
</style>
