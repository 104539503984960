<template>
  <footer class="footer arla-footer">
    <div class="content has-text-centered">
      <p class="has-text-left">
        <span>Arla Oy</span>
        <span class="mx-2">|</span>
        <span
          ><a
            href="https://www.arla.fi/lakitiedot/henkilotietoasiaa/"
            target="_blank"
            >Tietosuojaseloste</a
          ></span
        >
        <br>
        <span>kuluttajapalvelu@arlafoods.com</span>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "ArlaFooter",
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.arla-footer {
  max-width: 100%;
  flex: 0 1 40px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  background-color: #000000 !important;
  p {
    overflow-wrap: anywhere;
	font-size: 14px;
    span {
      color: #ffffff;
      a {
        color: white;
      }
      a:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
