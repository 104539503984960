<template>
  <ArlaNavbar/>

  <section class="hero">
    <SpinnerModal :spinning="spinning"></SpinnerModal>
    <div class="hero-body">
      <div class="container">

        <div class="columns">
          <div class="column">
            <p class="has-text-centered arlainterface-regular font-18">Hyvä valinta! Seuraavaksi valitse 4 eri vaihtoehdosta animaatio, mitä haluat lintusi tekevän.</p>
          </div>
        </div>
        <div class="columns is-multiline">
          <div class="column is-6">
            <p class="has-text-centered font-18 mb-3"><b>Riemu</b></p>
            <figure class="image animation-image" :class="{ 'animation-selected': animationSelected == 1 }" @click="selectAnimation(1)">
              <img src="../assets/img/dab.gif" />
            </figure>
          </div>
          <div class="column is-6">
            <p class="has-text-centered font-18 mb-3"><b>Tanssi</b></p>
            <figure class="image animation-image" :class="{ 'animation-selected': animationSelected == 2 }" @click="selectAnimation(2)">
              <img src="../assets/img/dance.gif" />
            </figure>
          </div>
          <div class="column is-6">
            <p class="has-text-centered font-18 mb-3"><b>Lento</b></p>
            <figure class="image animation-image" :class="{ 'animation-selected': animationSelected == 3 }" @click="selectAnimation(3)">
              <img src="../assets/img/fly.gif" />
            </figure>
          </div>
          <div class="column is-6">
            <p class="has-text-centered font-18 mb-3"><b>Hyppely</b></p>
            <figure class="image animation-image" :class="{ 'animation-selected': animationSelected == 4 }" @click="selectAnimation(4)">
              <img src="../assets/img/jump.gif" />
            </figure>
          </div>
          <div class="column is-6">
            <p class="has-text-centered font-18 mb-3"><b>Kävely</b></p>
            <figure class="image animation-image" :class="{ 'animation-selected': animationSelected == 5 }" @click="selectAnimation(5)">
              <img src="../assets/img/walk.gif" />
            </figure>
          </div>                                        
        </div>
        <div class="column has-text-centered">
            <button class="button arla-button dark-red-btn" @click="goToThanksPage">
              Eteenpäin!
            </button>
        </div>
        <div class="column">
          <figure class="image">
              <img src="../assets/img/Group25@3x.png" />
            </figure>          
        </div>     
      </div>
    </div>
  </section>



  <ArlaFooter/>
</template>

<script>
import ArlaNavbar from "../components/ArlaNavbar";
import ArlaFooter from "../components/ArlaFooter";
import SpinnerModal from '../components/SpinningModal'
import {mapMutations, mapGetters, mapActions } from "vuex"

export default {
  name: "VideoAnimationSelector",
  components: {
    ArlaNavbar,
    ArlaFooter,
    SpinnerModal
  },
  data() {
    return {
      animationSelected: null,
      videoData: {},
      spinning: false
    }
  },
  methods: {
    ...mapMutations({
      setVideoData: 'setVideoData'
    }),
    ...mapGetters({
      getVideoData: 'getVideoData',
    }),
		...mapActions({
      patchVideo: 'patchVideo',
    }),    
    selectAnimation(animationId) {
      this.animationSelected = animationId;
    },
    goToThanksPage() {
      this.spinning = true
      let self = this;
      if(this.animationSelected) {
        this.setVideoData({...this.getVideoData(), animation:this.animationSelected})
        this.patchVideo(this.getVideoData()).then(data => {
          this.spinning = false
          self.$router.push({ path: `/${self.$route.params.birdUuid}/thanks` })
        })
        
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
.animation-image {
  cursor: pointer;
  transition: transform .3s
}
.animation-image:hover {
  transform: scale(1.05);
}
.animation-selected {
  border: 3px solid $dark-red;
}
</style>
